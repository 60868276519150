function removeScriptTags(str: string) {
  return str.replace(/(<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>)|(<\/script>)/gi, '');
}

export function deepObjectSanitizeHtml<T extends object>(obj: T): T {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      // @ts-ignore
      obj[key] = removeScriptTags(value);
    }

    if (value !== null && typeof value === 'object') {
      // @ts-ignore
      obj[key] = deepObjectSanitizeHtml(value);
    }
  }

  return obj;
}
