import { Box } from '@webstore-monorepo/ui/box';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import { NotificationBannerEnum, notificationBlockVariantStyles } from './notification-banner.enum';
import type { NotificationBannerButtonProps } from './NotificationBannerButton';
import { NotificationBannerButton } from './NotificationBannerButton';
import type { NotificationBannerIconProps } from './NotificationBannerIcon';
import { NotificationBannerIcon } from './NotificationBannerIcon';
import { NotificationBannerProvider } from './NotificationBannerProvider';
import type { NotificationBannerTextProps } from './NotificationBannerText';
import { NotificationBannerText } from './NotificationBannerText';

type NotificationBannerCompositionProps = {
  Icon: React.FC<NotificationBannerIconProps>;
  Content: React.FC<PropsWithChildren>;
  Text: React.FC<NotificationBannerTextProps>;
  Button: React.FC<NotificationBannerButtonProps>;
};

export type NotificationBannerProps = {
  variant?: NotificationBannerEnum;
  children?: React.ReactNode;
};
export const NotificationBanner: NotificationBannerCompositionProps & FC<NotificationBannerProps> = ({ variant = NotificationBannerEnum.info, children }) => {
  const styles = notificationBlockVariantStyles[variant];

  return (
    <NotificationBannerProvider initial={{ variant }}>
      <Box
        testID={`notification-block-${variant}`}
        borderColor={styles.borderColor}
        backgroundColor={styles.backgroundColor}
        borderRadius="md"
        borderWidth={1}
        px={4}
        py={4}
        marginBottom={6}
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {children}
      </Box>
    </NotificationBannerProvider>
  );
};

const NotificationBannerContent: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Box alignItems="flex-start" flexGrow={1} flexBasis={0}>
    {children}
  </Box>
);

NotificationBanner.Icon = NotificationBannerIcon;
NotificationBanner.Content = NotificationBannerContent;
NotificationBanner.Text = NotificationBannerText;
NotificationBanner.Button = NotificationBannerButton;
