import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const StoreIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M22 10L20.82 4H3.18L2.02 9.8L2 10C2 11.47 2.81 12.75 4 13.44V20H14V13.44C14.38 13.22 14.71 12.94 15 12.61C15.73 13.45 16.8 13.99 18 13.99V19.99H20V13.43C21.19 12.75 22 11.47 22 10ZM19.18 6L19.58 8H4.42L4.82 6H19.18ZM4 10.09L4.02 10H8C8 11.1 7.1 12 6 12C4.93 12 4.05 11.15 4 10.09ZM12 18H6V14C7.2 14 8.27 13.46 9 12.62C9.73 13.46 10.8 14 12 14V18ZM12 12C10.9 12 10 11.1 10 10H14C14 11.1 13.1 12 12 12ZM16 10H19.98L20 10.09C19.95 11.15 19.07 12 18 12C16.9 12 16 11.1 16 10Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
});
