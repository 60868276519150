import type { AuthResponseDto } from '@goparrot/webstore-gateway-public-sdk';
import { WebstoreStorage } from '@webstore-monorepo/shared/utils/webstore-storage';

import type { UserSessionsByMerchant } from './type';

export class WebstoreUserAuthStorage {
  private key: string = 'sessions';
  private mobileKey = 'USER_AUTH';
  private merchantId = '';
  private webstoreStorage = new WebstoreStorage();

  constructor(merchantId: string) {
    this.merchantId = merchantId;
  }

  async getAccessToken(): Promise<string | null | undefined> {
    return (await this.get())?.accessToken;
  }

  async isAuthenticated(): Promise<boolean | null | undefined> {
    return (await this.get())?.isAuthenticated;
  }

  async has(): Promise<boolean> {
    const sessions: UserSessionsByMerchant = await this.getAll();

    return this.merchantId in sessions;
  }

  async get(): Promise<AuthResponseDto | null> {
    const sessions: UserSessionsByMerchant = await this.getAll();
    if (sessions === null) {
      const mobileAuth: AuthResponseDto | null = await this.webstoreStorage.getItem(this.mobileKey);
      if (mobileAuth) {
        return mobileAuth;
      }
      return null;
    }
    return this.merchantId in sessions ? sessions[this.merchantId] : null;
  }

  async set(data: AuthResponseDto) {
    const sessions: UserSessionsByMerchant = await this.getAll();
    sessions[this.merchantId] = data;
    await this.webstoreStorage.setItem(this.key, sessions);
  }

  async remove() {
    const sessions: UserSessionsByMerchant = await this.getAll();
    if (this.merchantId in sessions) {
      delete sessions[this.merchantId];
      await this.webstoreStorage.setItem(this.key, sessions);
    }
  }

  private getAll(): Promise<UserSessionsByMerchant> {
    return this.webstoreStorage.getItem<UserSessionsByMerchant>(this.key) || {};
  }
}
