import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const PrepareIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M11.9972 5C8.1312 5 4.99719 8.13401 4.99719 12V12.5887L6.29297 11.293L7.70718 12.7072L4.70718 15.7072C4.31666 16.0977 3.68349 16.0977 3.29297 15.7072L0.292969 12.7072L1.70718 11.293L2.99719 12.583V12C2.99719 7.02944 7.02663 3 11.9972 3C16.9678 3 20.9972 7.02944 20.9972 12C20.9972 16.9706 16.9678 21 11.9972 21C9.31079 21 6.89833 19.8217 5.25075 17.9571L6.7495 16.6328C8.03355 18.086 9.90801 19 11.9972 19C15.8632 19 18.9972 15.866 18.9972 12C18.9972 8.13401 15.8632 5 11.9972 5Z',
      'M11.0001 12V7H13.0001V11.5858L15.7043 14.29L14.2901 15.7042L11.293 12.7071C11.1055 12.5196 11.0001 12.2652 11.0001 12Z',
    ],
    viewBox: '0 0 24 25',
  },
  ...BaseConfig,
});
