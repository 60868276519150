import type { BoxProps } from '@shopify/restyle';
import { useTheme } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React, { forwardRef, memo } from 'react';

import { Box } from '../box';
import { getSpacedChildren } from './getSpacedChildren';

type Props = React.PropsWithChildren<BoxProps<Theme>> & {
  divider?: JSX.Element;
  size?: keyof Theme['spacing'];
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  reversed?: boolean;
  testID?: string;
};

export const Stack = memo(
  forwardRef(({ children, direction = 'row', reversed, divider, size = 'm', ...props }: Props, ref?: any) => {
    const theme = useTheme<Theme>();
    const spacing = theme.spacing[size];
    return (
      <Box flexDirection={direction} ref={ref} {...props}>
        {getSpacedChildren(children, spacing, ['row', 'row-reverse'].includes(direction) ? 'X' : 'Y', reversed ? 'reverse' : 'normal', divider)}
      </Box>
    );
  }),
);
