import type { IOrderElement } from '@goparrot/order-sdk';
import { OrderDelayedInfoUtil } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import type { SelectablePackingInstructions } from '@webstore-monorepo/shared/interfaces';
import { httpParamSerializerJQLike } from '@webstore-monorepo/shared/utils/url';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export interface SelectableOrderElement extends IOrderElement {
  selected?: boolean;
}

type PackingInstructionsType = {
  merchantId?: string;
  storeId?: string;
  options?: UseQueryOptions<SelectablePackingInstructions[], AxiosError, SelectablePackingInstructions[], any>;
};

export const usePackingInstructions = (
  { merchantId, storeId, options }: PackingInstructionsType = {} as PackingInstructionsType,
): UseQueryResult<SelectablePackingInstructions[]> => {
  const cart = useCartState();
  const merchant = useMerchantState();
  const store = useStoreState();
  const fulfilledAt = !OrderDelayedInfoUtil.isASAP(cart) && cart?.delayedInfo?.date ? cart?.delayedInfo?.date : '';

  const getPackingInstructions = async (): Promise<SelectablePackingInstructions[]> => {
    return axios.get(`/api/v2/merchants/${merchantId ?? merchant.merchantId}/stores/${storeId ?? store.storeId}/store-items/packing`, {
      params: {
        fulfillmentDate: fulfilledAt,
      },
      paramsSerializer: httpParamSerializerJQLike,
    });
  };

  return useQuery([QueryKeysEnum.packingInstructions], getPackingInstructions, {
    staleTime: 5000,
    ...options,
  });
};
