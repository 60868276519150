import type { UserContextDto, UserContextWithAuthDto } from '@goparrot/webstore-gateway-public-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { UserAuthStorageService } from '@webstore-monorepo/shared/utils/user-auth-storage';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

declare global {
  interface Window {
    merchantId: string;
  }
}

export const useUserContextLogoutMutation = (
  merchantId: string,
  storeId?: string,
  options?: UseMutationOptions<UserContextWithAuthDto, AxiosError, void>,
): UseMutationResult<UserContextWithAuthDto, AxiosError, void> => {
  const config = storeId ? { params: { storeId } } : undefined;
  return useMutation(async () => {
    return axios.delete(`/api/v2/merchants/${merchantId}/user-contexts`, config);
  }, options);
};

export const createTempContext = async (merchantId: string, storeId: string): Promise<UserContextDto> => {
  const storeIdQuery = storeId ? `?storeId=${storeId}` : '';
  const { auth, ...context }: UserContextWithAuthDto = await axios.post(`/api/v2/merchants/${merchantId}/user-contexts${storeIdQuery}`, {});
  const userAuthStorageService = new UserAuthStorageService(merchantId);

  userAuthStorageService.set(auth);
  return context;
};

export const createNewTempContext = async (merchantId: string, storeId: string): Promise<UserContextWithAuthDto> => {
  const storeIdQuery = storeId ? `?storeId=${storeId}` : '';
  return await axios.post(`/api/v2/merchants/${merchantId}/user-contexts${storeIdQuery}`, {});
};

export const getCurrentContext = async (merchantId: string, storeId: string): Promise<UserContextWithAuthDto> => {
  return await axios.get(`/api/v2/merchants/${merchantId}/user-contexts`, {
    params: {
      storeId,
    },
  });
};
