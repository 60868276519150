import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const GiftCardIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M19 7H16.82C16.93 6.69 17 6.35 17 6C17 4.35 15.65 3 14 3C13.23 3 12.53 3.3 12 3.78C11.47 3.3 10.77 3 10 3C8.35 3 7 4.35 7 6C7 6.35 7.07 6.69 7.18 7H5C3.34 7 2 8.34 2 10V18C2 19.66 3.34 21 5 21H11H13H19C20.66 21 22 19.66 22 18V10C22 8.34 20.66 7 19 7ZM20 10V13H13V10.41L14.29 11.7L15.7 10.29L14.41 9H19C19.55 9 20 9.45 20 10ZM14 5C14.55 5 15 5.45 15 6C15 6.55 14.55 7 14 7H13V6C13 5.45 13.45 5 14 5ZM9 6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V7H10C9.45 7 9 6.55 9 6ZM5 9H9.59L8.3 10.29L9.71 11.7L11 10.41V13H4V10C4 9.45 4.45 9 5 9ZM5 19C4.45 19 4 18.55 4 18V15H11V19H5ZM19 19H13V15H20V18C20 18.55 19.55 19 19 19Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
