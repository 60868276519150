import type { DiningOptionEnum } from '@goparrot/common';
import type { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil } from '@goparrot/store-v2-sdk';

export const getOrderAheadAvailable = (store: ReadStoreDto, diningOption: DiningOptionEnum) => {
  const orderAheadAvailabilityForMethods = DelayedOrdersUtils.isOrderAheadAvailable(store);
  const receiveMethod = diningOption && DiningOptionsUtil.getReceiveMethodMatchingDiningOption(diningOption);

  return orderAheadAvailabilityForMethods[receiveMethod];
};
