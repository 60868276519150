import { datadogRum } from '@datadog/browser-rum';
import type { ReadTemporaryUserDto, ReadUserDto } from '@goparrot/customer-sdk';
import type { IOrder } from '@goparrot/order-sdk';
import { CartActionTypeEnum } from '@goparrot/order-sdk';
import type { AuthResponseDto, UserContextDto, UserLoggedInContextDto } from '@goparrot/webstore-gateway-public-sdk';
import { useCartHandleActions } from '@webstore-monorepo/shared/api/cart-api';
import { createTempContext, getCurrentContext, useUserContextLogoutMutation } from '@webstore-monorepo/shared/api/users-api';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useAuthStorage } from '@webstore-monorepo/shared/hooks/use-auth-storage';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useReducer } from 'react';

type Action = { type: 'update'; payload: Partial<State> } | { type: 'logout'; payload: State };
type UpdateUser = (action: Action) => void;
type LoginType = (userLoggedInContext: Partial<UserLoggedInContextDto>) => void;
type LogoutType = () => Promise<any>;
export type UserState = (ReadUserDto | ReadTemporaryUserDto) & {
  isFirstLogin?: boolean;
};
type State = {
  user: UserState;
  cart?: IOrder;
  auth?: AuthResponseDto;
};

const UserStateContext = React.createContext<State>({ user: {} as UserState });
const UserDispatchContext = React.createContext<{ updateUserContext: UpdateUser; onLogin: LoginType; onLogout: LogoutType }>({
  updateUserContext: () => void 0,
  onLogin: () => void 0,
  onLogout: async () => new Promise(() => void 0),
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'logout':
      return action.payload;

    default: {
      return state;
    }
  }
};

export const UserContextProvider: React.FC<PropsWithChildren<{ merchantId: string; storeId: string; DD_RUM_ENABLED: boolean }>> = ({
  storeId,
  merchantId,
  DD_RUM_ENABLED,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, { user: {} as UserState });
  const authStorage = useAuthStorage();
  const { mutateAsync: handleActions } = useCartHandleActions();
  const { mutateAsync: onLogout } = useUserContextLogoutMutation(merchantId, storeId);
  const { notification } = usePlatformStoreState();
  // TODO: think of a better solution

  const handleDispatch = useCallback((action: Action) => dispatch(action), []);

  useEffect(() => {
    const getCurrentContextOrCreate = async () => {
      const auth: AuthResponseDto | null = authStorage.get(merchantId);
      let userContext: UserContextDto;
      if (!auth) {
        userContext = await createTempContext(merchantId, storeId);
      } else {
        const { user, cart } = await getCurrentContext(merchantId, storeId);
        userContext = { user, cart };
      }
      // @ts-ignore TODO: remove after gateway sdk is updated
      handleDispatch({ type: 'update', payload: { ...userContext } });
      updateCartAndContext();
      if (DD_RUM_ENABLED && userContext?.user?.userId) {
        datadogRum.setUser({
          id: userContext.user?.userId,
        });
      }
    };

    getCurrentContextOrCreate();
  }, []);

  const updateCartAndContext = async () => {
    if (state.user && state.cart) {
      if (state.cart.delayedInfo) {
        try {
          const cart = await handleActions([
            {
              type: CartActionTypeEnum.SET_DELAYED_INFO,
              payload: {
                delayedInfo: state.cart.delayedInfo ?? { isASAP: true },
              },
            },
          ]);
          if (cart) {
            handleDispatch({ type: 'update', payload: { cart } });
          }
        } catch (error: any) {
          notification.error(error?.response?.data?.message);
        }
      }
    }
  };

  const handleLogin = useCallback(
    (userLoggedInContext: Partial<UserLoggedInContextDto>) => {
      if (userLoggedInContext.auth) {
        authStorage.set(merchantId, userLoggedInContext.auth);
      }
      // @ts-ignore TODO: remove after gateway sdk is updated
      handleDispatch({ type: 'update', payload: { ...userLoggedInContext } });
    },
    [authStorage],
  );

  const handleLogout = useCallback(async (): Promise<UserContextDto> => {
    const { auth, ...context } = await onLogout();
    authStorage.set(merchantId, auth);
    // @ts-ignore TODO: remove after gateway sdk is updated
    handleDispatch({ type: 'logout', payload: { ...context } });
    return context;
  }, [authStorage, handleDispatch, onLogout]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={{ updateUserContext: handleDispatch, onLogin: handleLogin, onLogout: handleLogout }}>
        {state.user.userId && children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export const useUserContextState = () => {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserContextProvider');
  }
  return context;
};

export const useUserContextStateDispatch = () => {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserContextProvider');
  }
  return context;
};
