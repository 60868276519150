import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import type { IOrderElement, IOrderProperty, OrderSelectionType } from '@goparrot/order-sdk';
import { OrderElementTypeEnum } from '@goparrot/order-sdk';

export const hasItemPaidModifiers = (item: IStoreItemWithOptions | IOrderElement) =>
  item.properties.some(
    (property: IStoreItemOptionWithSelections | IOrderProperty) => property.selected && !property.isHidden && property.selections.some(isSelectedAndPaid),
  );

const isSelectedAndPaid = (selection: OrderSelectionType | IStoreItemOptionSelection) => {
  if (OrderElementTypeEnum.SELECTION_GROUP === selection.type) {
    // @ts-ignore core-sdk 3.200.0 changes broke
    return selection.properties?.some(isSelectedAndPaid);
  }

  // @ts-ignore core-sdk 3.200.0 changes broke
  return selection.selected && !!selection.price;
};
