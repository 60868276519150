import type { IFontStylesApp } from '@goparrot/webstore-sdk';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

export interface NotificationBannerTextProps {
  message: string;
  style?: IFontStylesApp;
}

export const NotificationBannerText: React.FC<NotificationBannerTextProps> = ({ message, style }) => {
  return (
    <Text fontSize="m" lineHeight={24} {...style}>
      {message}
    </Text>
  );
};
