import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const DoubleCheckMark = createSvgIcon({
  iconConfig: {
    paths: ['M33 8L16.8462 26L9.92308 19.25M9.92308 26L3 19.25M26.0769 8L16.2692 18.9688'],
    viewBox: '0 0 36 36',
  },
  ...BaseConfig,
  fill: 'transparent',
});
