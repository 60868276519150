import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ChevronThin = createSvgIcon({
  iconConfig: {
    paths: ['M5.5 1L2 5L5.5 9'],
    viewBox: '0 0 12 12',
  },
  ...BaseConfig,
});
