import type { ReadUserDto } from '@goparrot/customer-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useMergeUserVerificationMutation = (
  options?: UseMutationOptions<ReadUserDto, AxiosError, { userId: string; code: string }>,
): UseMutationResult<ReadUserDto, AxiosError, { userId: string; code: string }> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ userId, code }) => {
    return await axios.get(`/api/v2/users/${userId}/email-confirmations/${code}`, { params: { merchantId, storeId } });
  }, options);
};

export const useMergeUserVerificationResendMutation = (options?: UseMutationOptions<void, AxiosError, string>): UseMutationResult<void, AxiosError, string> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (userId) => {
    await axios.get(`/api/v2/users/${userId}/email-confirmations`, { params: { merchantId, storeId } });
  }, options);
};
