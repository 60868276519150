import { useTheme } from '@shopify/restyle';
import type { ConcreteSvgIcon } from '@webstore-monorepo/shared/interfaces';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const PromoIcon: React.FC<ConcreteSvgIcon> = ({ fill }) => {
  const theme = useTheme<Theme>();

  // @ts-ignore
  const themeFill = fill ? theme.colors[fill] : '#262626';
  return (
    <Svg viewBox="0 0 24 24" width={24} height={24} fill="transparent">
      <Path
        d="M9 10.5977C10.1046 10.5977 11 9.70223 11 8.59766C11 7.49309 10.1046 6.59766 9 6.59766C7.89543 6.59766 7 7.49309 7 8.59766C7 9.70223 7.89543 10.5977 9 10.5977Z"
        fill={themeFill}
      />
      <Path
        d="M17 15.3998C17 16.5044 16.1046 17.3998 15 17.3998C13.8954 17.3998 13 16.5044 13 15.3998C13 14.2952 13.8954 13.3998 15 13.3998C16.1046 13.3998 17 14.2952 17 15.3998Z"
        fill={themeFill}
      />
      <Path d="M7 17.3998L14.56 6.59766H17L9.44 17.3998H7Z" fill={themeFill} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H5C5 0.552285 5.44772 1 6 1C6.55228 1 7 0.552285 7 0H9C9 0.552285 9.44772 1 10 1C10.5523 1 11 0.552285 11 0H13C13 0.552285 13.4477 1 14 1C14.5523 1 15 0.552285 15 0H17C17 0.552285 17.4477 1 18 1C18.5523 1 19 0.552285 19 0H21V24H19C19 23.4477 18.5523 23 18 23C17.4477 23 17 23.4477 17 24H15C15 23.4477 14.5523 23 14 23C13.4477 23 13 23.4477 13 24H11C11 23.4477 10.5523 23 10 23C9.44771 23 9 23.4477 9 24H7C7 23.4477 6.55229 23 6 23C5.44771 23 5 23.4477 5 24H3V0ZM19 2.82929V21.1707C18.6872 21.0602 18.3506 21 18 21C17.2316 21 16.5308 21.2889 16 21.7639C15.4692 21.2889 14.7684 21 14 21C13.2316 21 12.5308 21.2889 12 21.7639C11.4692 21.2889 10.7684 21 10 21C9.23164 21 8.53076 21.2889 8 21.7639C7.46924 21.2889 6.76836 21 6 21C5.64936 21 5.31278 21.0602 5 21.1707V2.82929C5.31278 2.93985 5.64936 3 6 3C6.76835 3 7.46924 2.71115 8 2.23611C8.53076 2.71115 9.23165 3 10 3C10.7684 3 11.4692 2.71115 12 2.2361C12.5308 2.71115 13.2316 3 14 3C14.7684 3 15.4692 2.71115 16 2.2361C16.5308 2.71115 17.2316 3 18 3C18.3506 3 18.6872 2.93985 19 2.82929Z"
        fill={themeFill}
      />
    </Svg>
  );
};
