export class LocalStorage {
  private storageId: string = 'LS_';

  get<T>(key: string, notJSON?: boolean): T | null | string {
    let data: string | null;

    try {
      data = localStorage.getItem(`${this.storageId}${key}`);
    } catch (e) {
      return null;
    }

    if (data === null) {
      return null;
    }

    if (notJSON) {
      return data;
    }
    return JSON.parse(data);
  }

  has(key: string): boolean {
    return this.get(key) !== null;
  }

  set(key: string, data: any): void {
    if (typeof data == 'object') {
      data = JSON.stringify(data);
    }

    try {
      localStorage.setItem(`${this.storageId}${key}`, data);
    } catch (e) {
      console.error(e);
    }
  }

  remove(key: string): void {
    try {
      localStorage.removeItem(`${this.storageId}${key}`);
    } catch (e) {
      console.error(e);
    }
  }
}
