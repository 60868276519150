import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ChevronLeft = createSvgIcon({
  iconConfig: {
    paths: ['M9 1L2 9L9 17'],
    viewBox: '0 0 18 18',
  },
  ...BaseConfig,
});
