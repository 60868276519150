import type { WeeklyWorkingSchedule } from '@goparrot/store-v2-sdk';
import cloneDeep from 'lodash/cloneDeep';

export const makeSundayLast = (schedule: WeeklyWorkingSchedule[]) => {
  const formattedSchedule = cloneDeep(schedule);
  if (formattedSchedule[0].dayFrom === 'sun' && !formattedSchedule[0].dayTo) {
    const sunday = formattedSchedule.shift();
    if (sunday) {
      formattedSchedule.push(sunday);
    }
  }
  return formattedSchedule;
};

export const getFormattedHours = ({ from, to, intervals }: WeeklyWorkingSchedule): string => {
  if (!to) {
    return from;
  }
  return intervals?.length ? intervals.join(', ') : `${from} - ${to}`;
};
