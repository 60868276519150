import axiosOriginal from 'axios';

import { handleResponseFail, requestInterceptor, responseInterceptor } from './interceptors';

export const axiosConfig = {
  axiosConfig: {
    timeout: 65_000,
  },
  axiosRetryConfig: {
    retries: 0,
  },
};

export const axios = axiosOriginal.create({
  timeout: 65_000,
});

axios.interceptors.response.use(responseInterceptor, handleResponseFail);
axios.interceptors.request.use(requestInterceptor);
