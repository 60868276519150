import { useTheme } from '@shopify/restyle';
import type { ConcreteSvgIcon } from '@webstore-monorepo/shared/interfaces';
import type { Theme } from '@webstore-monorepo/shared/theme';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Animated, Easing } from 'react-native';
import Svg, { Path } from 'react-native-svg';

export const Spinner: FC<ConcreteSvgIcon> = (props) => {
  const theme = useTheme<Theme>();
  // @ts-ignore
  const stroke = props.stroke ? theme.colors[props.stroke] : 'white';
  const rotateStart = useRef(new Animated.Value(0)).current;
  const spin = rotateStart.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  Animated.loop(
    Animated.timing(rotateStart, {
      toValue: 1,
      duration: 72000,
      easing: Easing.linear,
      useNativeDriver: true,
    }),
  ).start();

  return (
    // @ts-ignore
    <Animated.View testID="spinner" style={{ transform: [{ rotate: spin }] }}>
      <Svg width={props.width || '20'} height={props.height || '20'} viewBox="0 0 17 16" fill="none">
        <Path d="M8.5 1.33325V3.99992" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M8.5 12V14.6667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M3.78711 3.28662L5.67378 5.17329" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M11.3262 10.8267L13.2128 12.7133" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M1.83398 8H4.50065" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M12.5 8H15.1667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M3.78711 12.7133L5.67378 10.8267" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M11.3262 5.17329L13.2128 3.28662" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </Svg>
    </Animated.View>
  );
};
