import type { ICartAction, IOrder } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useCartHandleActions = (options?: UseMutationOptions<IOrder, AxiosError, ICartAction[]>): UseMutationResult<IOrder, AxiosError, ICartAction[]> => {
  const { merchantId, storeId } = useWebStore();
  return useMutation({
    mutationFn: async (actions) => {
      return axios.patch(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/actions`, actions);
    },
    mutationKey: 'cart-actions',
    ...options,
  });
};
