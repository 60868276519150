import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const DeliveryIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7ZM7 4C7.55228 4 8 3.55228 8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4Z',
      'M8 4V13',
      'M7.75 12.0027C7.83258 12.0009 7.91593 12 8 12C8.08407 12 8.16742 12.0009 8.25 12.0027V11.0025C8.16718 11.0008 8.08383 11 8 11C7.91617 11 7.83282 11.0008 7.75 11.0025V12.0027ZM6.25 11.1259C4.35167 11.4097 3 12.1417 3 13C3 14.1046 5.23858 15 8 15C10.7614 15 13 14.1046 13 13C13 12.1417 11.6483 11.4097 9.75 11.1259V12.1382C10.2975 12.229 10.7773 12.3595 11.1641 12.5143C11.5468 12.6673 11.7894 12.8246 11.92 12.9481C11.9413 12.9682 11.9577 12.9856 11.9704 13C11.9577 13.0145 11.9413 13.0318 11.92 13.0519C11.7894 13.1754 11.5468 13.3327 11.1641 13.4857C10.4043 13.7897 9.28606 14 8 14C6.71394 14 5.59568 13.7897 4.83586 13.4857C4.45317 13.3327 4.21059 13.1754 4.08003 13.0519C4.0587 13.0318 4.04225 13.0145 4.02965 13C4.04225 12.9855 4.0587 12.9682 4.08003 12.9481C4.21059 12.8246 4.45317 12.6673 4.83586 12.5143C5.22271 12.3595 5.70248 12.229 6.25 12.1382V11.1259ZM12.0089 12.9454C12.0091 12.9454 12.0085 12.9472 12.0067 12.9506C12.0078 12.9472 12.0088 12.9454 12.0089 12.9454ZM3.99108 12.9454C3.99124 12.9454 3.99216 12.9472 3.99334 12.9506C3.99151 12.9472 3.99092 12.9454 3.99108 12.9454ZM3.99108 13.0546C3.99092 13.0546 3.99151 13.0528 3.99334 13.0494C3.99216 13.0528 3.99124 13.0546 3.99108 13.0546ZM12.0067 13.0493C12.0085 13.0528 12.0091 13.0546 12.0089 13.0546C12.0088 13.0546 12.0078 13.0528 12.0067 13.0493Z',
    ],
    viewBox: '0 0 16 16',
  },
  ...BaseConfig,
  width: 16,
  height: 16,
});
