import type { ReadWebMerchantDto } from '@goparrot/webstore-sdk';
import { WebMerchant } from '@goparrot/webstore-sdk';
import { createTheme } from '@shopify/restyle';

import { buttons } from './buttons';
import { text } from './text';

const webMerchant: ReadWebMerchantDto = new WebMerchant();

const { theme } = webMerchant.app;

export const restyleTheme = createTheme({
  colors: {
    errorBorderColor: '#FFCCD5',
    errorIconFill: '#CC0023',
    errorBg: '#FFE5EA',
    ...theme.colors,
    'rgb(0,0,0)': 'rgb(0,0,0)',
    modalBackdrop: 'rgba(0,0,0, 0.8)',
  },
  fontSizes: {
    ...theme.fontSizes,
    '16': 16,
  },
  fontFamily: {
    ...theme.fonts,
    ...theme.customFonts,
  },
  spacing: {
    ...theme.spacing,
  },
  borderRadii: {
    ...theme.borderRadii,
  },
  breakpoints: {
    ...theme.breakpoints,
  },
  textVariants: {
    ...theme.textVariants,
    ...text.textVariants,
    defaults: {},
  },
  buttonVariants: {
    ...buttons.buttonVariants,
    defaults: {},
  },
});

export type Theme = typeof restyleTheme;
