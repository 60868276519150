import { ChevronLeft } from '@webstore-monorepo/shared/icons';
import { ChevronRight } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { useTheme } from '@webstore-monorepo/shared/theme';
import type { FC, PropsWithChildren } from 'react';
import React, { useEffect, useRef } from 'react';

import type { ScrollableBoxProps } from './interface';
import { useDraggableScroll } from './use-draggable-scroll';
import { hexToRgb } from './utils/colors';

export const ScrollableBox: FC<PropsWithChildren<ScrollableBoxProps>> = ({
  testID,
  showArrows,
  iconsStyles,
  parentWidth,
  scrollToElement,
  textColor,
  leftIcon,
  rightIcon,
  children,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isLeftEdge, isRightEdge, onMouseDown, onTouchStart, onScrollToNextGroup, onScrollToPrevGroup, onScrollTo } = useDraggableScroll(ref);
  const theme = useTheme<Theme>();
  const leftArrowRef = useRef<HTMLDivElement>(null);
  const rightArrowRef = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const themeBackground = rest.backgroundColor ? theme.colors[rest.backgroundColor] : '';
  // @ts-ignore
  const arrowBackground = rest.arrowBackground ? theme.colors[rest.arrowBackground] : '';
  // @ts-ignore
  const arrowColor = rest.arrowColor ? theme.colors[rest.arrowColor] : null;
  // @ts-ignore
  const themeTextColor = textColor ? theme.colors[textColor] : 'black';
  // @ts-expect-error wrong key for the theme, only for legacy
  const marginLeft = rest.marginLeft ? theme.spacing[rest.marginLeft] : rest.marginLeft ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const marginRight = rest.marginRight ? theme.spacing[rest.marginRight] : rest.marginRight ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const marginTop = rest.marginTop ? theme.spacing[rest.marginTop] : rest.marginTop ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const marginBottom = rest.marginBottom ? theme.spacing[rest.marginBottom] : rest.marginBottom ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const paddingLeft = rest.paddingLeft ? theme.spacing[rest.paddingLeft] : rest.paddingLeft ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const paddingRight = rest.paddingRight ? theme.spacing[rest.paddingRight] : rest.paddingRight ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const paddingTop = rest.paddingTop ? theme.spacing[rest.paddingTop] : rest.paddingTop ?? 0;
  // @ts-expect-error wrong key for the theme, only for legacy
  const paddingBottom = rest.paddingBottom ? theme.spacing[rest.paddingBottom] : rest.paddingBottom ?? 0;

  useEffect(() => {
    if (scrollToElement) {
      onScrollTo(scrollToElement);
    }
  }, [scrollToElement, onScrollTo]);

  useEffect(() => {
    document.styleSheets[0].insertRule('.scrollable-box::-webkit-scrollbar { -webkit-appearance: none; display: none; }');
    document.styleSheets[0].insertRule('.scrollable-box { -ms-overflow-style: none; scrollbar-width: none; }');
  }, []);

  const mainColor = arrowBackground ? hexToRgb(arrowBackground) : 'rgba(255, 255, 255, 0.2)';
  const secondaryColor = arrowBackground ? `${hexToRgb(arrowBackground, 1)}` : 'rgba(255, 255, 255, 0.521234)';
  const tertiaryColor = arrowBackground ? `${hexToRgb(arrowBackground, 0)}` : 'rgba(255, 255, 255, 0)';

  return (
    <div style={{ position: 'relative', width: parentWidth ?? '100%' }}>
      {!isLeftEdge ? (
        <div
          ref={leftArrowRef}
          onClick={onScrollToPrevGroup}
          style={{
            // @ts-ignore
            position: 'absolute',
            display: 'flex',
            // @ts-ignore
            justifyContent: 'center',
            // @ts-ignore
            alignItems: 'center',
            // @ts-ignore
            top: 0,
            // @ts-ignore
            bottom: 0,
            // @ts-ignore
            left: 0,
            // @ts-ignore
            width: 40,
            // @ts-ignore
            zIndex: 100,
            cursor: 'pointer',
            ...(arrowBackground ? { background: `linear-gradient(270deg, ${`${tertiaryColor} 0%, ${secondaryColor} 40%, ${mainColor} 100%`})` } : {}),
            ...iconsStyles,
          }}
        >
          {showArrows ? (
            leftIcon ? (
              leftIcon
            ) : (
              <ChevronLeft width={16} height={16} strokeWidth={2} stroke={arrowColor ?? themeTextColor} fill="transparent" />
            )
          ) : null}
        </div>
      ) : null}
      <div
        className="scrollable-box"
        ref={ref}
        onTouchStart={onTouchStart}
        onMouseDown={onMouseDown}
        data-testid={'scrollable-box' ?? testID}
        style={{
          boxSizing: 'border-box',
          border: '0 solid black',
          display: 'flex',
          // @ts-ignore
          position: 'relative',
          // @ts-ignore
          flexBasis: 'auto',
          // @ts-ignore
          flexShrink: 0,
          // @ts-ignore
          flexDirection: 'row',
          // @ts-ignore
          minWidth: 0,
          // @ts-ignore
          minHeight: 0,
          // @ts-ignore
          zIndex: 0,
          // @ts-ignore
          overflowX: 'scroll',
          scrollbarWidth: 'none',
          ...rest,
          // @ts-ignore
          backgroundColor: themeBackground,
          margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`,
          padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
        }}
      >
        {children}
      </div>
      {!isRightEdge ? (
        <div
          ref={rightArrowRef}
          onClick={onScrollToNextGroup}
          style={{
            // @ts-ignore
            position: 'absolute',
            display: 'flex',
            // @ts-ignore
            justifyContent: 'center',
            // @ts-ignore
            alignItems: 'center',
            // @ts-ignore
            top: 0,
            // @ts-ignore
            bottom: 0,
            // @ts-ignore
            right: 0,
            // @ts-ignore
            width: 40,
            // @ts-ignore
            zIndex: 100,
            cursor: 'pointer',
            ...(arrowBackground ? { background: `linear-gradient(90deg, ${`${tertiaryColor} 0%, ${secondaryColor} 40%, ${mainColor} 100%`})` } : {}),
            ...iconsStyles,
          }}
        >
          {showArrows ? (
            rightIcon ? (
              rightIcon
            ) : (
              <ChevronRight width={16} height={16} strokeWidth={2} stroke={arrowColor ?? themeTextColor} fill="transparent" />
            )
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
