import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const Rotate = createSvgIcon({
  iconConfig: {
    paths: [
      'M11.5 2.5V5.5H8.5',
      'M10.2449 8.00001C9.91993 8.91997 9.30472 9.70937 8.49202 10.2493C7.67932 10.7892 6.71317 11.0503 5.73915 10.9933C4.76513 10.9363 3.83602 10.5643 3.09182 9.93334C2.34762 9.30237 1.82865 8.44662 1.61312 7.49503C1.39759 6.54345 1.49718 5.5476 1.89687 4.65754C2.29656 3.76748 2.97471 3.03144 3.82912 2.56033C4.68352 2.08921 5.6679 1.90856 6.63392 2.04558C7.59993 2.1826 8.49525 2.62988 9.18494 3.32001L11.4999 5.50001',
    ],
    viewBox: '0 0 12 13',
  },
  ...BaseConfig,
  fill: 'transparent',
});
