import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const LoyaltyPoint = createSvgIcon({
  iconConfig: {
    paths: [
      'M16.3651 2.34172C15.9395 1.91589 15.4341 1.5781 14.8779 1.34763C14.3217 1.11716 13.7255 0.998535 13.1235 0.998535C12.5214 0.998535 11.9252 1.11716 11.369 1.34763C10.8128 1.5781 10.3074 1.91589 9.88181 2.34172L8.99847 3.22506L8.11514 2.34172C7.25539 1.48198 6.08933 0.998981 4.87347 0.998981C3.65761 0.998981 2.49155 1.48198 1.6318 2.34172C0.772061 3.20147 0.289063 4.36753 0.289062 5.58339C0.289062 6.79925 0.772061 7.96531 1.6318 8.82506L2.51514 9.70839L8.99847 16.1917L15.4818 9.70839L16.3651 8.82506C16.791 8.39943 17.1288 7.89407 17.3592 7.33785C17.5897 6.78164 17.7083 6.18546 17.7083 5.58339C17.7083 4.98132 17.5897 4.38514 17.3592 3.82893C17.1288 3.27271 16.791 2.76735 16.3651 2.34172Z',
    ],
    viewBox: '0 0 18 17',
  },
  ...BaseConfig,
  fill: 'white',
});
