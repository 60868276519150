import type { IOrder } from '@goparrot/order-sdk';
import { OrderElementTypeEnum, OrderStoreItemElementSubTypeEnum } from '@goparrot/order-sdk';
import { DiningOptionsUtil, HumanReadableIntervalsUtils, type ReadStoreDto, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';

export function getConvertedPrepTimeByCartItems(cart: IOrder) {
  const maxPrepTime = cart?.elements.length ? Math.max(...cart.elements.map((item) => item.metadata.prepTime ?? 0)) : 0;

  return HumanReadableIntervalsUtils.convertMinutesToHumanReadable(maxPrepTime);
}

export function getConvertedOrderPrepTime(cart: IOrder, store: ReadStoreDto) {
  const isDeliveryRequired = DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo.type);
  let time = store[isDeliveryRequired ? ReceiveMethodEnum.DELIVERY : ReceiveMethodEnum.PICKUP]?.time ?? 0;

  if (cart.schedulingInfo.prepTime) {
    time += cart.schedulingInfo.prepTime;
  }

  if (cart.schedulingInfo.delayTime) {
    time += cart.schedulingInfo.delayTime;
  }

  return HumanReadableIntervalsUtils.convertMinutesToHumanReadable(time);
}

export const getCartItems = (cart: IOrder) =>
  cart.elements.filter(
    (item) =>
      item.subtype !== OrderStoreItemElementSubTypeEnum.PACKING_INSTRUCTIONS &&
      !item.parentUniqueName &&
      OrderElementTypeEnum.BUNDLE_ITEM !== item.type &&
      OrderElementTypeEnum.COMBO !== item.type,
  );
