import type { AuthResponseDto } from '@goparrot/webstore-gateway-public-sdk';
import { useLocalStorage } from '@webstore-monorepo/shared/utils/local-storage';
import type { UserSessionsByMerchant } from '@webstore-monorepo/shared/utils/user-auth-storage';
import { useCallback } from 'react';

export const useAuthStorage = () => {
  const localStorage = useLocalStorage();
  const key: string = 'sessions';
  const getAll = useCallback((): UserSessionsByMerchant => {
    return localStorage.get(key) || {};
  }, [localStorage]);

  const get = useCallback(
    (merchantId: string): AuthResponseDto | null => {
      const sessions: UserSessionsByMerchant = getAll();

      return merchantId in sessions ? sessions[merchantId] : null;
    },
    [getAll],
  );

  const getAccessToken = useCallback(
    (merchantId: string): string | undefined => {
      return get(merchantId)?.accessToken;
    },
    [get],
  );

  const isAuthenticated = useCallback(
    (merchantId: string): boolean | undefined => {
      return get(merchantId)?.isAuthenticated;
    },
    [get],
  );

  const has = useCallback(
    (merchantId: string): boolean => {
      const sessions: UserSessionsByMerchant = getAll();

      return merchantId in sessions;
    },
    [getAll],
  );

  const set = useCallback(
    (merchantId: string, data: AuthResponseDto): void => {
      const sessions: UserSessionsByMerchant = getAll();
      sessions[merchantId] = data;
      localStorage.set(key, sessions);
    },
    [getAll, localStorage],
  );

  const remove = useCallback(
    (merchantId: string): void => {
      const sessions: UserSessionsByMerchant = getAll();
      if (merchantId in sessions) {
        delete sessions[merchantId];
        localStorage.set(key, sessions);
      }
    },
    [getAll, localStorage],
  );

  return { getAccessToken, isAuthenticated, has, get, remove, set };
};
