import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const MealIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M31.8334 39.6665C31.8334 38.5325 32.7769 36.9276 34.7584 35.4415C36.6785 34.0014 38.9408 33.1665 40.5002 33.1665V28.8332C37.7261 28.8332 34.5717 30.1649 32.1584 31.9748C29.8065 33.7387 27.5 36.4671 27.5 39.6665H31.8334Z',
      'M47 13.6665H34V17.9998H38.3334V22.4382C27.2147 23.5262 18.8334 32.9685 18.8334 46.1665V48.3332H34V52.6665H18.8334V56.9998H62.1667V52.6665H47V48.3332H62.1667V46.1665C62.1667 32.9685 53.7854 23.5262 42.6667 22.4382V17.9998H47V13.6665ZM40.5 26.6665C49.4017 26.6665 56.8569 33.3052 57.7449 43.9998H23.2552C24.1431 33.3052 31.5984 26.6665 40.5 26.6665ZM38.3334 52.6665V48.3332H42.6667V52.6665H38.3334Z',
    ],
    viewBox: '0 0 80 80',
  },
  ...BaseConfig,
  width: 80,
  height: 80,
});
