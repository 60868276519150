import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const LockIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M7.875 4H7.3125V2.85714C7.3125 1.28 6.0525 0 4.5 0C2.9475 0 1.6875 1.28 1.6875 2.85714V4H1.125C0.50625 4 0 4.51429 0 5.14286V10.8571C0 11.4857 0.50625 12 1.125 12H7.875C8.49375 12 9 11.4857 9 10.8571V5.14286C9 4.51429 8.49375 4 7.875 4ZM6.24375 4H2.75625V2.85714C2.75625 1.88 3.53813 1.08571 4.5 1.08571C5.46187 1.08571 6.24375 1.88 6.24375 2.85714V4Z',
    ],
    viewBox: '0 0 9 12',
  },
  ...BaseConfig,
});
