import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ArrowDown = createSvgIcon({
  iconConfig: {
    paths: ['M0.75 1.33331L5.75 6.74998L10.75 1.33331'],
    viewBox: '0 0 12 8',
  },
  ...BaseConfig,
  fillOpacity: 0,
  strokeWidth: 1.5,
});
