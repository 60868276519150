import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ArrowRight = createSvgIcon({
  iconConfig: {
    paths: ['M12.0923 20.6004L21.6923 11.0004M21.6923 11.0004L12.0923 1.40039M21.6923 11.0004H1.09229'],
    viewBox: '0 0 23 22',
  },
  ...BaseConfig,
});
