import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CircledExclamation = createSvgIcon({
  iconConfig: {
    paths: [
      'M10 17.5C14.1333 17.5 17.5 14.1333 17.5 10C17.5 5.86667 14.1333 2.5 10 2.5C5.86667 2.5 2.5 5.86667 2.5 10C2.5 14.1333 5.86667 17.5 10 17.5ZM10 4.16667C13.2167 4.16667 15.8333 6.78333 15.8333 10C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667ZM10 14.1667C10.5753 14.1667 11.0417 13.7003 11.0417 13.125C11.0417 12.5497 10.5753 12.0833 10 12.0833C9.4247 12.0833 8.95833 12.5497 8.95833 13.125C8.95833 13.7003 9.4247 14.1667 10 14.1667ZM9.16667 5.83333H10.8333V11.25H9.16667V5.83333Z',
    ],
    viewBox: '0 0 20 20',
  },
  ...BaseConfig,
  width: 20,
  height: 20,
  stroke: 'transparent',
  fill: 'white',
});
