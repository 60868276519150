import { useTheme } from '@shopify/restyle';
import type { ConcreteSvgIcon } from '@webstore-monorepo/shared/interfaces';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

export const ClockBack: React.FC<ConcreteSvgIcon> = ({ stroke }) => {
  const theme = useTheme<Theme>();
  // @ts-ignore
  const themeStroke = stroke ? theme.colors[stroke] : '#31AC95';
  return (
    <Svg viewBox="0 0 16 16" width={16} height={16} fill="transparent">
      <Circle cx="8" cy="8" r="6.224" transform="rotate(7.675 8 8)" stroke={themeStroke} strokeDasharray="1.2 1.2" />
      <Path d="M8.23 4.542v4.15H5.464" stroke={themeStroke} strokeWidth="1.1" strokeLinecap="round" />
      <Path d="M8 14.224a6.224 6.224 0 115.176-9.682" stroke={themeStroke} />
      <Path d="M13.303 1.775v2.767h-2.767" stroke={themeStroke} strokeLinecap="round" />
    </Svg>
  );
};
