import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ChevronDown = createSvgIcon({
  iconConfig: {
    paths: [
      'M7.70703 7.70711C7.31651 8.09763 6.68334 8.09763 6.29282 7.70711L0.292818 1.70711L1.70703 0.292892L6.99992 5.58579L12.2928 0.292893L13.707 1.70711L7.70703 7.70711Z',
    ],
    viewBox: '0 0 14 8',
  },
  ...BaseConfig,
  fill: 'gray900',
  stroke: 'transparent',
});
