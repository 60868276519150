import { useTheme } from '@shopify/restyle';
import type { ConcreteSvgIcon, CreateSvgIcon, SvgSettings } from '@webstore-monorepo/shared/interfaces';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React, { memo } from 'react';
import Svg, { G, Path } from 'react-native-svg';

/**
 * This is SVG icons config.
 * TODO:
 * - Split each config for icon in separate file
 */
export type IconsSettingsType =
  | 'CLEAR_BUTTON_ANDROID'
  | 'BACK_ARROW'
  | 'RIGHT_ARROW'
  | 'SUN'
  | 'STORE_MODIFIER_EXPAND_ARROW'
  | 'CLEAR_BIN'
  | 'SETTINGS'
  | 'CART'
  | 'CART_NEW'
  | 'PIN'
  | 'LOCATION_CARD_EXPAND_ARROW'
  | 'ACCOUNT_CIRCLE'
  | 'DECREASE_QUANTITY'
  | 'INCREASE_QUANTITY'
  | 'MARKER'
  | 'MENU'
  | 'MENU_NEW'
  | 'CURRENT_LOCATION'
  | 'LOCATION_MAP_ON'
  | 'LOCATION_ACC_MENU_ICON'
  | 'ORDER_FAV_ACC_MENU_ICON'
  | 'LOYALTY_ACC_MENU_ICON'
  | 'ADDRESS_ACC_MENU_ICON'
  | 'NOTIFICATION_ACC_MENU_ICON'
  | 'HELP_ACC_MENU_ICON'
  | 'LOCATION_MAP_OFF'
  | 'SCAN_MY_CODE'
  | 'FAVORITES_LOCATION'
  | 'STAR_STORE'
  | 'GET_LOCATION'
  | 'ROUTE_TO'
  | 'ROUTE_TO_NOT_FILLED'
  | 'INFO_ICON'
  | 'CLOCK_ICON'
  | 'CALENDAR_ICON'
  | 'CLOSE_ICON'
  | 'ALERT_ICON'
  | 'REFRESH_OUTLINE'
  | 'CHECK_MARK';

export const IconsSettings: Record<IconsSettingsType, SvgSettings> = {
  BACK_ARROW: {
    paths: ['M9 1L2 8.5L9 16'],
    viewBox: '0 0 10 17',
    fillOpacity: 0.001,
    strokeOpacity: 1,
  },
  RIGHT_ARROW: {
    paths: [
      'M48.3 1.4C34.5 4.6 19.8 15.4 11 29.1c-5.2 8-7.6 14.9-9.2 25.4C.2 65.8 1.5 71.9 7.6 82c12.1 19.9 26.1 34.5 221.7 230.9L405 489.5l-133.8 134C43.6 851.4 21.1 874.7 7.6 897c-6.5 10.7-7.5 16.3-5.3 29.2C5 942.3 12.7 955 26.1 965.9c8.1 6.5 15.1 9.8 26.2 12.2 24.2 5.4 33.2-.6 105.7-70.6 40.2-38.8 301.7-300.7 338.5-339 36.7-38.2 49.6-53.1 53-61.1 2-4.8 3.8-17.7 3.3-22.8-1.2-10.3-9.7-23.3-31.5-47.7-47.2-52.9-356.7-362-421.2-420.7-11-10-13.1-11.4-19-13.3C72.5.2 56.8-.5 48.3 1.4z',
    ],
    viewBox: '0 0 554 980',
  },
  INFO_ICON: {
    paths: [
      'M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z',
      'M9 12.2V9',
      'M9 6.2V6.5 6  H9.4 8.6',
    ],
    viewBox: '0 0 20 20',
  },
  SUN: {
    paths: [
      'M9 3a6 6 0 100 12A6 6 0 009 3zm0 9.6a3.6 3.6 0 11-.001-7.199A3.6 3.6 0 019 12.6zM9 2a1 1 0 100-2 1 1 0 000 2zM9 18a1 1 0 100-2 1 1 0 000 2zM4.135 2.366a1.25 1.25 0 10-1.769 1.769 1.25 1.25 0 001.77-1.769zm11.499 11.499a1.25 1.25 0 11-1.77 1.769 1.25 1.25 0 011.77-1.769zM1 10a1 1 0 100-2 1 1 0 000 2zM17 10a1 1 0 100-2 1 1 0 000 2zM15.633 4.135a1.251 1.251 0 10-1.77-1.77 1.251 1.251 0 001.77 1.77zM4.136 15.634a1.25 1.25 0 11-1.769-1.77 1.25 1.25 0 011.77 1.77z',
    ],
    viewBox: '0 0 18 18',
  },
  STORE_MODIFIER_EXPAND_ARROW: {
    paths: [
      'M1 1.5C-.4 3.2 3.7 8.6 13.8 18.25c7.5 7.1 6.95 7.15 15.4-1C40.25 6.6 42.65 3.25 40.65 1.2 39.1-.35 38.35.2 30 8.5l-9 8.95-8.75-8.7C2.7-.75 2.8-.7 1 1.5z',
    ],
    viewBox: '0 0 42 24',
  },
  CLEAR_BIN: {
    paths: [
      'M48 1.2c0 .7-1.4 2.1-3.1 3-1.9 1.1-3.9 3.7-5.5 7.2-2.5 5.4-2.5 5.5-6.2 4.9-2-.2-5.7-1.1-8.1-1.9-5.8-1.8-9.5-1.8-13.2.2C4.8 18.2 2.7 22.9.4 41.2c-.6 4.4-.5 4.7 2.4 6.2 1.7.9 4.1 1.6 5.3 1.6 1.3 0 4.8.6 7.8 1.4 3.1.8 9.2 2.2 13.6 3.1 4.4.9 10.5 2.3 13.5 3 5.4 1.3 11.7 2.6 27.5 6 4.4.9 10.3 2.3 13.1 3.1 2.8.8 6.1 1.4 7.4 1.4 1.3 0 4.6.6 7.4 1.4 2.8.8 8.2 2.1 12.1 3l6.9 1.7 1.8-2.3c1-1.3 2-3.8 2.3-5.6.3-1.7.7-4.8 1-6.7.4-2.1 1.2-3.5 2-3.5 1.2 0 1.5-1.5 1.5-6.5 0-3.7-.4-6.5-1-6.5-.5 0-2.7-1.7-4.9-3.9-2.5-2.5-5-4-7.2-4.3-2.9-.4-8.8-1.8-14.8-3.4-1-.3-1.2-1.5-.7-4.9.8-4.7-1.2-10.7-4.4-13.3-2.5-2-8.2-4.3-13-5.2-2.5-.4-6.5-1.2-9-1.9-2.5-.6-6.2-1.3-8.2-1.6-2.4-.4-3.8-1.1-3.8-2C59 .4 57.6 0 53.5 0 49.8 0 48 .4 48 1.2zm10.8 7.4c3.7.9 9.3 2.2 12.4 3 3.2.8 6.8 1.4 8 1.4 1.3 0 4.1.9 6.4 1.9 4.9 2.2 7 6.3 5.5 10.4-1.5 4 .1 4.2-38.6-5-9.7-2.3-10.8-4.2-5.2-9.8C49.2 8.6 51.1 7 51.5 7c.4 0 3.7.7 7.3 1.6zM23.9 20.4c3.1.8 9.2 2.2 13.6 3.1 4.4.9 10.5 2.3 13.5 3 3 .7 9.1 2.1 13.5 3 15.1 3.2 22 4.7 27.5 6 3 .8 7.1 1.6 9 1.9 15.2 2.4 20.2 6.9 16.9 15.3-.6 1.5-1.4 4.8-1.8 7.3l-.7 4.5-5.4-.2c-3-.1-8-.9-11-1.7-5.2-1.3-8.6-2.1-27.5-6.1-4.4-.9-10.5-2.3-13.5-3-3-.7-9.1-2.1-13.5-3-15.2-3.2-22-4.7-27.5-6-3-.8-6.8-1.7-8.4-2-2.3-.5-2.7-1-2.2-2.8.4-1.2 1.1-4.5 1.6-7.4C9.4 24 12.7 19.1 16.9 19c.8 0 4 .6 7 1.4zM2.9 75.7c-.6 1.7-.9 20-.7 50.7l.3 48.1 2.5 5c1.4 2.8 4 6 5.9 7.2 3.4 2.3 3.6 2.3 43.6 2.3h40.2l4.5-3.9c3.5-3.1 4.7-5 5.6-9.1 1.6-6.7 1.7-98.2.2-101.1-1-1.8-2.7-1.9-51.1-1.9H4l-1.1 2.7zM99.8 124c.4 51 .1 53.6-5.6 57.5l-3.7 2.5H54.2c-32.9 0-36.7-.2-39.4-1.8-5.7-3.3-5.8-4.1-5.8-54.6V80.2c0-1 9.4-1.2 45.3-1l45.2.3.3 44.5z',
      'M30.6 91.6c-1.4 1.3-1.6 6.1-1.6 36.7 0 19.4.4 35.7.8 36.3.4.6 1.5 1.3 2.5 1.7 1.3.5 2 0 2.7-1.9 1.4-3.5 1.3-69.9 0-72.5-1.3-2.3-2.4-2.4-4.4-.3zM52.2 91.2c-.9.9-1.2 10-1.2 36.9 0 31.5.2 35.9 1.6 37.4 1.3 1.2 1.9 1.3 3 .4 1.2-1 1.4-7.4 1.2-38.3-.3-32.2-.5-37.1-1.8-37.4-.9-.1-2.1.3-2.8 1zM73.7 90.7c-.4.3-.7 17.1-.7 37.3 0 38.2.2 40 4.2 37.9 1.7-.9 1.8-3 1.8-37.2 0-36.6-.2-38.7-3.5-38.7-.7 0-1.5.3-1.8.7z',
    ],
    viewBox: '0 0 126 189',
  },
  SETTINGS: {
    paths: [
      'M5 1.2c0 .7-1.2 2.4-2.5 3.7-4.5 4.2-2.8 11.9 3.2 15 1.5.8 22 1.1 69.8 1.1 71.3 0 72.2 0 73.8-4.5.4-.8 1.3-1.5 2.2-1.5 1.1 0 1.5-1.1 1.5-4 0-2.5-.5-4-1.2-4-1.6 0-5.8-4.4-5.8-5.9C146 .3 126.5 0 75.5 0 19.8 0 5 .3 5 1.2zM2.7 54.8c-5.3 5.6-2.8 14.3 4.5 15.7 1.8.3 16.7.5 33 .3 29.1-.3 29.8-.3 31.9-2.5 3.4-3.4 3.1-10.8-.5-14L68.9 52H5.4l-2.7 2.8zM5.5 108.2c-6 3.2-7.4 10.7-2.9 15.2l2.7 2.6h140.5l2.3-2.5c1.3-1.3 3-2.5 3.7-2.5.7 0 1.2-1.5 1.2-4 0-2.9-.4-4-1.5-4-.9 0-1.8-.7-2.2-1.5-1.6-4.4-4-4.5-74.7-4.5-46.9.1-67.7.4-69.1 1.2z',
    ],
    viewBox: '0 0 153 126',
  },
  CLEAR_BUTTON_ANDROID: {
    paths: [
      'M456 1.1C350.7 8.8 250.6 49.8 171 118c-13.6 11.7-40.5 38.5-52.3 52.4C55.8 243.7 16.1 332.7 4.1 427.2c-26.7 209.2 82 410.7 271.4 503.3 145.1 70.9 317.5 65.2 457.6-15.1 95.7-54.8 170.9-141.1 211.7-242.9 46.8-117 46.8-248.3 0-365-16.2-40.1-37.1-77.1-63.3-112-14.9-19.7-24.5-30.9-42.3-48.9C757.7 64 656 15 540 2.5 522.6.6 473.6-.2 456 1.1zm134.8 346.6l90.7-90.7 27.8 27.7 27.7 27.8-90.7 90.7-90.8 90.8 91.3 91.3 91.2 91.2-27.7 27.8-27.8 27.7-91.2-91.2-91.3-91.3-91.3 91.3-91.2 91.2-27.8-27.7-27.7-27.8 91.2-91.2 91.3-91.3-90.8-90.8-90.7-90.7 27.7-27.8 27.8-27.7 90.7 90.7 90.8 90.8 90.8-90.8z',
    ],
    viewBox: '0 0 980 980',
  },
  PIN: {
    paths: [
      'M10.5 2.35c-8.65 4-12.6 14.5-8.65 23.15C3.5 29.1 16 42 17.8 42c1.5 0 14.3-12.35 15.8-15.2 7.45-14.4-8.45-31.2-23.1-24.45zm10.3 12.2c2.6 1.85 2.85 5.05.55 7.6-3 3.35-8.35.95-8.35-3.75 0-1.95 3.2-5.4 5-5.4.3 0 1.55.7 2.8 1.55z',
    ],
    viewBox: '0 0 36 42',
  },
  LOCATION_CARD_EXPAND_ARROW: {
    paths: [
      'M3 1.9c0 1-.7 2.1-1.5 2.5-1.9.7-2 7.2-.3 9.1.7.7 7.4 8.7 14.8 17.8 13.7 16.8 16.6 19.2 21.3 17.9 1.5-.4 7.6-6.9 15.7-16.7 7.3-8.8 14.1-17 15.1-18.3 1-1.2 2.3-2.2 2.9-2.2.5 0 1-1.5 1-3.4 0-2.8-.4-3.5-2.5-4C67.9 4.2 67 3.3 67 2c0-2-.8-2-32-2C4.3 0 3 .1 3 1.9z',
    ],
    viewBox: '0 0 72 54',
  },
  ACCOUNT_CIRCLE: {
    paths: [
      'M38 .5C17.29.5.5 17.29.5 38S17.29 75.5 38 75.5 75.5 58.71 75.5 38A37.5 37.5 0 0038 .5zm0 11.25c6.213 0 11.25 5.037 11.25 11.25S44.213 34.25 38 34.25 26.75 29.213 26.75 23 31.787 11.75 38 11.75zM59.075 53.6a26.25 26.25 0 01-42.15 0 3.75 3.75 0 01-.262-3.75l.787-1.65a11.25 11.25 0 0110.163-6.45h20.775a11.25 11.25 0 0110.087 6.3l.862 1.763a3.75 3.75 0 01-.262 3.787z',
    ],
    viewBox: '0 0 76 76',
  },
  DECREASE_QUANTITY: {
    paths: [
      'M21.6 6.3c-5.1.8-6.5 1.6-11 6.2C2.9 20.1 3 19.3 3 100s-.1 79.9 7.5 87.5c4.8 4.8 5.7 5.2 12.3 6.3 10 1.7 158.4 1.7 168.4 0 6.6-1.1 7.5-1.5 12.3-6.3 7.6-7.6 7.5-6.8 7.5-87.5s.1-79.9-7.5-87.5c-4.8-4.8-5.7-5.2-12.3-6.3-9-1.5-160.8-1.4-169.6.1zm168.7 9.1c4.2.5 8.4 3.7 9.8 7.4 1.1 2.7 1.3 150.9.3 153.8-1 2.8-4.6 6.5-7.2 7.5-3.1 1.2-169.3 1.2-172.4 0-2.6-1-6.2-4.7-7.2-7.5-1-2.9-.8-151.1.3-153.8 1.4-3.7 5.5-6.8 10-7.6 4.6-.8 160-.6 166.4.2z',
      'M71.4 92.3c-.3.8-.4 4.7-.2 8.8l.3 7.4h71v-17l-35.3-.3c-28.9-.2-35.3 0-35.8 1.1z',
    ],
    viewBox: '0 0 216 198',
  },
  FAVORITES_LOCATION: {
    paths: [
      'M15.7663 2.14929C15.3753 1.78493 14.9111 1.4959 14.4002 1.2987C13.8893 1.1015 13.3417 1 12.7887 1C12.2357 1 11.6881 1.1015 11.1772 1.2987C10.6663 1.4959 10.2021 1.78493 9.81116 2.14929L8.9998 2.90512L8.18843 2.14929C7.39874 1.41366 6.32768 1.00038 5.21089 1.00038C4.09409 1.00038 3.02303 1.41366 2.23334 2.14929C1.44365 2.88493 1 3.88267 1 4.92302C1 5.96336 1.44365 6.9611 2.23334 7.69674L3.0447 8.45256L8.9998 14L14.9549 8.45256L15.7663 7.69674C16.1574 7.33255 16.4677 6.90014 16.6794 6.42422C16.891 5.94829 17 5.43818 17 4.92302C17 4.40785 16.891 3.89774 16.6794 3.42182C16.4677 2.94589 16.1574 2.51348 15.7663 2.14929V2.14929Z',
    ],
    viewBox: '0 0 18 15',
  },
  STAR_STORE: {
    paths: [
      'M9.57143 1L12.22 6.26604L18.1429 7.11567L13.8571 11.2124L14.8686 17L9.57143 14.266L4.27429 17L5.28571 11.2124L1 7.11567L6.92286 6.26604L9.57143 1Z',
    ],
    viewBox: '0 0 19 18',
  },
  GET_LOCATION: {
    paths: [
      'M19.7755 1.48972C19.8528 1.32114 19.6789 1.14728 19.5103 1.2246L0.832331 9.79217C0.637172 9.88169 0.701005 10.174 0.915715 10.174H10.4167C10.5272 10.174 10.6332 10.2179 10.7113 10.296C10.7894 10.3741 10.8333 10.4801 10.8333 10.5906V20.0836C10.8333 20.2984 11.1257 20.3622 11.2151 20.167L19.7755 1.48972Z',
    ],
    viewBox: '0 0 21 21',
  },
  ROUTE_TO: {
    paths: [
      'M17.3649 9.54812C17.4989 9.68203 17.6969 9.72879 17.8766 9.6689C18.0563 9.60902 18.1867 9.45276 18.2135 9.26527L18.567 6.7904C18.5893 6.6346 18.5369 6.47742 18.4256 6.36614C18.3143 6.25485 18.1571 6.20246 18.0013 6.22471L15.5265 6.57827C15.339 6.60505 15.1827 6.73546 15.1228 6.91513C15.0629 7.0948 15.1097 7.29288 15.2436 7.4268L17.3649 9.54812Z',
      'M8.83317 15.0175L14.5919 9.25867L15.299 9.96577L9.54028 15.7246C8.43872 16.8261 8.43872 18.6121 9.54029 19.7137C10.6419 20.8153 12.4279 20.8153 13.5294 19.7137L19.2367 14.0065C20.8368 12.4063 23.4312 12.4063 25.0314 14.0065C26.6315 15.6066 26.6315 18.201 25.0314 19.8012L20.1457 24.6868L19.4386 23.9797L24.3243 19.0941C25.5339 17.8844 25.5339 15.9232 24.3243 14.7136C23.1146 13.5039 21.1534 13.5039 19.9438 14.7136L14.2365 20.4208C12.7444 21.9129 10.3253 21.9129 8.83318 20.4208C7.34109 18.9287 7.34109 16.5095 8.83317 15.0175Z',
    ],
    viewBox: '0 0 34 34',
  },
  ROUTE_TO_NOT_FILLED: {
    paths: [
      'M29.2002 14.2923L29.2024 14.2945C29.5954 14.6826 29.599 15.3103 29.2064 15.709C29.2061 15.7093 29.2058 15.7097 29.2054 15.71L15.7077 29.2077C15.3427 29.5727 14.7618 29.5959 14.3694 29.2772L14.2922 29.2001L0.792303 15.7077C0.79227 15.7077 0.792237 15.7076 0.792204 15.7076C0.402566 15.3179 0.402599 14.682 0.792303 14.2923L14.2923 0.792303L14.2923 0.79231L14.2946 0.790007C14.675 0.404605 15.3092 0.401292 15.7002 0.792303L29.2002 14.2923Z',
      'M18.0908 18.6818V14.9318H12.0908V19.4318H9.09082V13.4318C9.09082 12.5993 9.7583 11.9319 10.5908 11.9319H18.0908V8.18182L23.3408 13.4318L18.0908 18.6818Z',
    ],
    viewBox: '0 0 30 30',
  },
  INCREASE_QUANTITY: {
    paths: [
      'M21.6 6.3c-5.1.8-6.5 1.6-11 6.2C2.9 20.1 3 19.3 3 100s-.1 79.9 7.5 87.5c4.8 4.8 5.7 5.2 12.3 6.3 10 1.7 158.4 1.7 168.4 0 6.6-1.1 7.5-1.5 12.3-6.3 7.6-7.6 7.5-6.8 7.5-87.5s.1-79.9-7.5-87.5c-4.8-4.8-5.7-5.2-12.3-6.3-9-1.5-160.8-1.4-169.6.1zm168.7 9.1c4.2.5 8.4 3.7 9.8 7.4 1.1 2.7 1.3 150.9.3 153.8-1 2.8-4.6 6.5-7.2 7.5-3.1 1.2-169.3 1.2-172.4 0-2.6-1-6.2-4.7-7.2-7.5-1-2.9-.8-151.1.3-153.8 1.4-3.7 5.5-6.8 10-7.6 4.6-.8 160-.6 166.4.2z',
      'M102.7 64.6c-.4.4-.7 6.9-.7 14.4 0 10.2-.3 14-1.3 14.8-.8.7-7.1 1.3-15.3 1.4l-13.9.3v9l14.1.3c10.1.2 14.5.6 15.3 1.5.6.8 1.2 7.3 1.3 15.3l.3 13.9h9l.3-14.1c.2-10.1.6-14.5 1.5-15.3.8-.6 7.3-1.2 15.3-1.3l13.9-.3v-9l-13.9-.3c-8-.1-14.5-.7-15.3-1.3-.9-.8-1.3-5.2-1.5-15.3l-.3-14.1-4-.3c-2.3-.2-4.4 0-4.8.4z',
    ],
    viewBox: '0 0 216 198',
  },
  MARKER: {
    paths: [
      'M1.141 7.906c-.363 1.356-.363 3.01-.363 6.316 0 3.307 0 4.96.363 6.316a10.667 10.667 0 007.543 7.543c.745.2 1.579.29 2.727.33l3.17 3.17a.593.593 0 00.838 0l3.17-3.17c1.148-.04 1.983-.13 2.727-.33a10.667 10.667 0 007.543-7.543c.363-1.356.363-3.01.363-6.316s0-4.96-.363-6.316A10.667 10.667 0 0021.316.363C19.96 0 18.306 0 15 0c-3.307 0-4.96 0-6.316.363A10.667 10.667 0 001.14 7.906z',
    ],
    viewBox: '0 0 30 32',
  },
  MENU: {
    paths: [
      'M.933 0a.933.933 0 100 1.867H18.2A.933.933 0 1018.2 0H.933zm0 6.067a.933.933 0 100 1.866H18.2a.933.933 0 100-1.866H.933zm0 6.066a.933.933 0 000 1.867H18.2a.933.933 0 100-1.867H.933z',
    ],
    viewBox: '0 0 20 14',
  },
  MENU_NEW: {
    paths: [
      'M0.933333 0C0.417877 0 0 0.417851 0 0.933333C0 1.44881 0.417877 1.86667 0.933333 1.86667H18.2C18.7155 1.86667 19.1333 1.44881 19.1333 0.933333C19.1333 0.417851 18.7155 0 18.2 0H0.933333ZM0.933333 6.06667C0.417877 6.06667 0 6.48452 0 7C0 7.51548 0.417877 7.93333 0.933333 7.93333H18.2C18.7155 7.93333 19.1333 7.51548 19.1333 7C19.1333 6.48452 18.7155 6.06667 18.2 6.06667H0.933333ZM0.933333 12.1333C0.417877 12.1333 0 12.5512 0 13.0667C0 13.5821 0.417877 14 0.933333 14H18.2C18.7155 14 19.1333 13.5821 19.1333 13.0667C19.1333 12.5512 18.7155 12.1333 18.2 12.1333H0.933333Z',
    ],
    viewBox: '0 0 19 14',
  },
  CURRENT_LOCATION: {
    paths: [
      'M14 8.91A5.09 5.09 0 008.91 14 5.09 5.09 0 0014 19.09 5.09 5.09 0 0019.09 14 5.09 5.09 0 0014 8.91zm11.378 3.817A11.447 11.447 0 0015.273 2.622V0h-2.546v2.622A11.447 11.447 0 002.622 12.727H0v2.546h2.622a11.447 11.447 0 0010.105 10.105V28h2.546v-2.622a11.447 11.447 0 0010.105-10.105H28v-2.546h-2.622zM14 22.91A8.91 8.91 0 015.09 14 8.91 8.91 0 0114 5.09 8.91 8.91 0 0122.91 14 8.91 8.91 0 0114 22.91z',
    ],
    viewBox: '0 0 28 28',
  },
  LOCATION_MAP_ON: {
    paths: [
      'M6.5 1.083a3.789 3.789 0 00-3.792 3.792c0 2.844 3.792 7.042 3.792 7.042s3.792-4.198 3.792-7.042A3.789 3.789 0 006.5 1.083zm0 5.146a1.355 1.355 0 110-2.709 1.355 1.355 0 010 2.71z',
    ],
    viewBox: '0 0 13 13',
  },
  LOCATION_MAP_OFF: {
    paths: ['M3 7.5h9V9H3V7.5zm0-3h9V6H3V4.5zm0 6h6V12H3v-1.5zm7.5 0V15l3.75-2.25-3.75-2.25z'],
    viewBox: '0 0 18 18',
  },
  CART: {
    paths: [
      'M1.3 2.7C.6 4.2 0 6.9 0 8.7 0 14.2 3 15 22.9 15c16.6 0 17.1.1 18.5 2.2.8 1.3 1.7 4.1 2.1 6.3.3 2.2 1.2 6.5 1.9 9.5.8 3 2.2 9.1 3.1 13.5.9 4.4 2.3 10.3 3.1 13.1.8 2.8 1.4 6.1 1.4 7.4 0 1.3.6 4.6 1.4 7.4.8 2.8 2.2 8.7 3.1 13.1.9 4.4 2.3 10.5 3 13.5 1.1 4.8 2.2 9.5 5 22.3.5 2.4-.1 3.3-3.6 6.5-6.9 6.1-9.7 15.9-6.7 22.9C57 156.8 62 163 63.5 163c.7 0 1.8.7 2.5 1.5.9 1 3.2 1.5 7.6 1.5 3.4 0 6.5.4 6.8.8.3.5-1.7 2.5-4.4 4.5-2.8 2.1-5 4.3-5 5.1 0 .7-.7 1.9-1.5 2.6-2.2 1.8-2.1 13.7.2 17 5.4 8.2 10.2 11 18.8 11 5 0 7-.5 10.1-2.6 5.3-3.5 7.1-6 9-12.1 1.3-4.4 1.3-5.9.3-9.5-1.6-5.4-5.1-10.1-9.5-12.9-1.9-1.1-3.2-2.5-2.8-3 .7-1.2 81.1-1.2 81.8 0 .3.4-1.5 2.3-4 4.1-5 3.5-9.4 11.2-9.4 16.3 0 2.8 2.8 9.8 5.3 12.8.7.9 3 2.8 5.1 4.3 3.2 2.2 5 2.6 10.6 2.6s7.4-.4 10.6-2.6c2.1-1.5 4.4-3.4 5.1-4.3 2.5-3 5.3-10 5.3-12.8 0-5.1-4.4-12.8-9.4-16.3-2.6-1.9-4.3-3.7-3.9-4.1.5-.4 2.8-.9 5.2-1.1 3.4-.2 4.9-.9 6.4-3 1.9-2.5 1.9-3 .7-5.4-.8-1.4-2.5-3-3.8-3.5-1.3-.5-30.4-.9-64.8-.9-70.2 0-68.4.2-68.4-6.6 0-2.5.7-4.2 2.3-5.5 2.1-1.7 5.1-1.8 66.8-1.9h64.5l2.2-3c1.2-1.6 2.2-3.5 2.2-4.2 0-.7.6-3.3 1.4-5.8.8-2.5 2.2-7.2 3.2-10.5.9-3.3 2.3-8.1 3-10.8.8-2.6 2-6.8 2.8-9.5 1.8-6.5 4.3-15.2 6.1-21.7.8-3 2.2-8.1 3.1-11.3.9-3.1 2.3-7.7 3-10.2 2.6-9.3 3.1-10.5 4.2-10.2.8.1 1.2-1.5 1.2-4.8 0-3.2-.4-5-1.2-5-.6 0-2-.8-3.1-1.8-1.8-1.6-7.8-1.7-86.5-2.2l-84.7-.5-1.3-5.5c-.7-3-1.7-7.4-2.2-9.7-.5-2.3-1.8-6-2.7-8.3l-1.8-4h-48L1.3 2.7zm214.3 44c-.4 2.1-1.3 5.8-2.1 8.3-1.7 5.8-4.8 16.5-9 31.5-.9 3.3-2.2 8-2.9 10.5-.7 2.5-2.1 7.2-3 10.5-1 3.3-2.5 8.5-3.5 11.5l-1.8 5.5-56.2.3-56.2.2-1.5-3.7c-.9-2-1.8-5.3-2-7.2-.3-2-1.1-6.1-1.9-9.1-.7-3-2.1-9.1-3-13.5-.9-4.4-2.3-10.5-3-13.5-.7-3-2.1-9.1-3-13.5-.9-4.4-2.3-10.3-3.1-13.1-.8-2.8-1.4-5.8-1.4-6.7 0-1.6 5.9-1.7 77.1-1.7h77.1l-.6 3.7zM93.4 183.6c2.3 3.3 1.7 6.2-1.6 8.7-3.5 2.6-7.3 1.6-9.3-2.3-1.4-2.7-1.4-3.3 0-6 1.3-2.5 2.1-3 5.4-3 3 0 4.2.5 5.5 2.6zm96.1-1c2.6 2.6 1.6 8.5-1.7 10.4-2.5 1.3-3.1 1.3-5.5 0-2.1-1.2-2.9-2.4-3.1-5.2-.4-4.3 1.7-6.8 5.8-6.8 1.6 0 3.6.7 4.5 1.6z',
    ],
    viewBox: '0 0 234 207',
  },
  CART_NEW: {
    paths: [
      'M8.63643 21C9.16361 21 9.59098 20.5736 9.59098 20.0476C9.59098 19.5216 9.16361 19.0952 8.63643 19.0952C8.10925 19.0952 7.68188 19.5216 7.68188 20.0476C7.68188 20.5736 8.10925 21 8.63643 21Z',
      'M19.1364 21C19.6636 21 20.091 20.5736 20.091 20.0476C20.091 19.5216 19.6636 19.0952 19.1364 19.0952C18.6092 19.0952 18.1819 19.5216 18.1819 20.0476C18.1819 20.5736 18.6092 21 19.1364 21Z',
      'M1 1H4.81818L7.37636 13.7524C7.46365 14.1909 7.70273 14.5847 8.05175 14.865C8.40077 15.1454 8.83748 15.2943 9.28545 15.2857H18.5636C19.0116 15.2943 19.4483 15.1454 19.7973 14.865C20.1464 14.5847 20.3854 14.1909 20.4727 13.7524L22 5.7619H5.77273',
    ],
    viewBox: '0 0 23 22',
    fillOpacity: 0.001,
    strokeOpacity: 1.0,
  },
  LOCATION_ACC_MENU_ICON: {
    paths: ['M18.0001 1L8.65015 10.35', 'M18 1L12.05 18L8.65 10.35L1 6.95L18 1Z'],
    viewBox: '0 0 19 19',
    fillOpacity: 0.001,
    strokeOpacity: 0.5,
  },
  LOYALTY_ACC_MENU_ICON: {
    paths: [
      'M20 7.744L12.809 7.12698L10 0.5L7.19102 7.12698L0 7.744L5.45502 12.471L3.82 19.5L10 15.772L16.18 19.5L14.545 12.471L20 7.744ZM10 13.896L6.23698 16.166L7.23298 11.885L3.91 9.00702L8.29 8.63102L10 4.59498L11.71 8.63098L16.09 9.00698L12.768 11.885L13.764 16.166L10 13.896Z',
    ],
    viewBox: '0 0 20 19',
  },
  ORDER_FAV_ACC_MENU_ICON: {
    paths: [
      'M13.05 0.765015C11.52 0.765015 9.99 1.48501 9 2.65502C8.01 1.48501 6.48 0.765015 4.95 0.765015C2.16 0.765015 0 2.92502 0 5.71502C0 9.13502 3.06 11.925 7.74 16.065L9 17.235L10.26 16.065C14.85 11.835 18 9.04502 18 5.71502C18 2.92502 15.84 0.765015 13.05 0.765015ZM9.09 14.805H9L8.91 14.715C4.59 10.845 1.8 8.32502 1.8 5.71502C1.8 3.91502 3.15 2.56502 4.95 2.56502C6.3 2.56502 7.65 3.46502 8.19 4.72502H9.9C10.35 3.46502 11.7 2.56502 13.05 2.56502C14.85 2.56502 16.2 3.91502 16.2 5.71502C16.2 8.32502 13.41 10.845 9.09 14.805Z',
    ],
    viewBox: '0 0 18 16.5',
  },
  ADDRESS_ACC_MENU_ICON: {
    paths: [
      'M8.26364 1.90001C6.54937 1.90001 4.90532 2.58099 3.69315 3.79316C2.48099 5.00533 1.8 6.64938 1.8 8.36364C1.8 10.8655 3.43007 13.3052 5.23518 15.2106C6.11932 16.1438 7.00653 16.9081 7.6737 17.4394C7.89928 17.619 8.09887 17.7714 8.26363 17.8939C8.4284 17.7714 8.62799 17.619 8.85357 17.4394C9.52074 16.9081 10.4079 16.1438 11.2921 15.2106C13.0972 13.3052 14.7273 10.8655 14.7273 8.36364C14.7273 6.64938 14.0463 5.00533 12.8341 3.79316C11.6219 2.58099 9.9779 1.90001 8.26364 1.90001ZM8.26364 19C7.76441 19.7489 7.76418 19.7487 7.76393 19.7485L7.76148 19.7469L7.75588 19.7431L7.73684 19.7303C7.7207 19.7193 7.69778 19.7036 7.66851 19.6833C7.60998 19.6428 7.52603 19.5839 7.42029 19.5075C7.2089 19.3548 6.9099 19.1322 6.55243 18.8475C5.83893 18.2794 4.88522 17.4584 3.92846 16.4485C2.05175 14.4675 0 11.589 0 8.36364C5.96046e-08 6.17199 0.870631 4.0701 2.42036 2.52037C3.9701 0.970637 6.07198 0.100006 8.26364 0.100006C10.4553 0.100006 12.5572 0.970637 14.1069 2.52037C15.6566 4.0701 16.5273 6.17199 16.5273 8.36364C16.5273 11.589 14.4755 14.4675 12.5988 16.4485C11.642 17.4584 10.6883 18.2794 9.97484 18.8475C9.61737 19.1322 9.31837 19.3548 9.10698 19.5075C9.00125 19.5839 8.9173 19.6428 8.85876 19.6833C8.82949 19.7036 8.80657 19.7193 8.79044 19.7303L8.77139 19.7431L8.76579 19.7469L8.76399 19.7481C8.76374 19.7483 8.76287 19.7489 8.26364 19ZM8.26364 19L8.76287 19.7489C8.46056 19.9504 8.06624 19.9501 7.76393 19.7485L8.26364 19Z',
      'M8.26373 6.80909C7.40517 6.80909 6.70918 7.50509 6.70918 8.36364C6.70918 9.22219 7.40517 9.91819 8.26373 9.91819C9.12228 9.91819 9.81827 9.22219 9.81827 8.36364C9.81827 7.50509 9.12228 6.80909 8.26373 6.80909ZM4.90918 8.36364C4.90918 6.51098 6.41106 5.00909 8.26373 5.00909C10.1164 5.00909 11.6183 6.51098 11.6183 8.36364C11.6183 10.2163 10.1164 11.7182 8.26373 11.7182C6.41106 11.7182 4.90918 10.2163 4.90918 8.36364Z',
    ],
    viewBox: '0 0 22 22',
  },
  NOTIFICATION_ACC_MENU_ICON: {
    paths: [
      'M9.0001 18C9.9901 18 10.8001 17.19 10.8001 16.2H7.2001C7.2001 17.19 8.0101 18 9.0001 18ZM14.8501 12.6V7.65C14.8501 4.86 12.9601 2.61 10.3501 1.98V1.35C10.3501 0.63 9.7201 0 9.0001 0C8.2801 0 7.6501 0.63 7.6501 1.35V1.98C5.0401 2.61 3.1501 4.86 3.1501 7.65V12.6L1.3501 14.4V15.3H16.6501V14.4L14.8501 12.6ZM13.0501 13.5H4.9501V7.65C4.9501 5.4 6.7501 3.6 9.0001 3.6C11.2501 3.6 13.0501 5.4 13.0501 7.65V13.5Z',
    ],
    viewBox: '0 0 18 18',
  },
  HELP_ACC_MENU_ICON: {
    paths: [
      'M8.10013 8.10001H9.90015V13.5H8.10013V8.10001Z',
      'M9.00002 0C13.9725 0 18 4.02751 18 8.99998C18 13.9724 13.9725 18 9.00002 18C4.02755 18 0 13.9725 0 8.99998C0 4.02747 4.02751 0 9.00002 0ZM9.00002 16.2C12.969 16.2 16.2 12.969 16.2 8.99998C16.2 5.03098 12.969 1.80001 9.00002 1.80001C5.03102 1.80001 1.80001 5.03098 1.80001 8.99998C1.80001 12.969 5.03102 16.2 9.00002 16.2Z',
      'M8.10013 4.5H9.90015V6.30002H8.10013V4.5Z',
    ],
    viewBox: '0 0 18 18',
  },
  CLOCK_ICON: {
    paths: ['M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z', 'M7 3.39996V6.99996L9.4 8.19996'],
    viewBox: '0 0 14 14',
  },
  CALENDAR_ICON: {
    paths: [
      'M14.25 2.25H13.5V0.749996H12V2.25H6V0.749996H4.5V2.25H3.75C2.9175 2.25 2.2575 2.925 2.2575 3.75L2.25 14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM14.25 14.25H3.75V6H14.25V14.25ZM5.25 7.5H9V11.25H5.25V7.5Z',
    ],
    viewBox: '0 0 16 16',
  },
  SCAN_MY_CODE: {
    paths: [
      'M0 6H6.85714V0H0V6ZM2.28571 2H4.57143V4H2.28571V2ZM9.14286 0V6H16V0H9.14286ZM13.7143 4H11.4286V2H13.7143V4ZM0 14H6.85714V8H0V14ZM2.28571 10H4.57143V12H2.28571V10ZM14.8571 8H16V12H12.5714V11H11.4286V14H9.14286V8H12.5714V9H14.8571V8ZM14.8571 13H16V14H14.8571V13ZM12.5714 13H13.7143V14H12.5714V13Z',
    ],
    viewBox: '0 0 16 14',
  },
  CLOSE_ICON: {
    paths: ['M11 1L1 11', 'M1 1L11 11'],
    viewBox: '0 0 12 12',
  },
  ALERT_ICON: {
    paths: [
      'M14 8C14 4.6875 11.3125 2 8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8Z',
      'M7.8205 5.18907L7.99987 9.00001L8.17894 5.18907C8.18004 5.1647 8.17617 5.14037 8.16755 5.11756C8.15894 5.09474 8.14576 5.07392 8.12882 5.05637C8.11189 5.03882 8.09155 5.02491 8.06906 5.01549C8.04657 5.00606 8.02239 5.00133 7.998 5.00156V5.00156C7.9739 5.00179 7.9501 5.00687 7.92801 5.0165C7.90592 5.02613 7.88599 5.04012 7.86942 5.05762C7.85286 5.07512 7.83999 5.09578 7.83158 5.11836C7.82317 5.14094 7.8194 5.16499 7.8205 5.18907V5.18907Z',
      'M8 11.4972C7.87639 11.4972 7.75555 11.4605 7.65277 11.3919C7.54999 11.3232 7.46988 11.2256 7.42258 11.1114C7.37527 10.9972 7.36289 10.8715 7.38701 10.7503C7.41113 10.629 7.47065 10.5177 7.55806 10.4303C7.64547 10.3428 7.75683 10.2833 7.87807 10.2592C7.99931 10.2351 8.12497 10.2475 8.23918 10.2948C8.35338 10.3421 8.451 10.4222 8.51967 10.525C8.58835 10.6277 8.625 10.7486 8.625 10.8722C8.625 11.038 8.55916 11.1969 8.44194 11.3141C8.32473 11.4313 8.16576 11.4972 8 11.4972Z',
    ],
    viewBox: '0 0 16 16',
  },
  REFRESH_OUTLINE: {
    paths: [
      'M40 18.25C40 18.25 43.045 16.75 32 16.75C28.0444 16.75 24.1776 17.923 20.8886 20.1206C17.5996 22.3182 15.0362 25.4418 13.5224 29.0963C12.0087 32.7509 11.6126 36.7722 12.3843 40.6518C13.156 44.5314 15.0608 48.0951 17.8579 50.8921C20.6549 53.6892 24.2186 55.594 28.0982 56.3657C31.9778 57.1374 35.9992 56.7413 39.6537 55.2276C43.3082 53.7138 46.4318 51.1504 48.6294 47.8614C50.827 44.5724 52 40.7056 52 36.75',
      'M32 7.25L42 17.25L32 27.25',
    ],
    viewBox: '0 0 64 64',
  },
  CHECK_MARK: {
    paths: ['M3 6.5L6.36842 9L11 4'],
    viewBox: '0 0 14 14',
  },
};

export const SvgIcon: React.FC<CreateSvgIcon> = (props: CreateSvgIcon): React.ReactElement => {
  const theme = useTheme<Theme>();
  const { iconConfig, ...restOfProps } = props;
  const width: number = +(restOfProps.viewBox ?? iconConfig.viewBox).split(' ')[2];
  const height: number = +(restOfProps.viewBox ?? iconConfig.viewBox).split(' ')[3];
  // @ts-ignore
  const fill = restOfProps.fill ? theme.colors[restOfProps.fill] : theme.colors.primaryColor;
  // @ts-ignore
  const stroke = restOfProps.stroke ? theme.colors[restOfProps.stroke] : theme.colors.gray;
  return (
    <Svg
      strokeOpacity={restOfProps.strokeOpacity || iconConfig.strokeOpacity || 0}
      strokeWidth={restOfProps.strokeWidth || 1.0}
      width={restOfProps.width || width}
      height={restOfProps.height || height}
      viewBox={restOfProps.viewBox ?? iconConfig.viewBox}
      {...restOfProps}
    >
      {/* <Circle
        cx='17.8451'
        cy='26.2974'
        r='1.75358'
        stroke={props.stroke}
        strokeWidth={props.strokeWidth || 1.0}
        fill={props.fill || theme.webMerchant?.theme.colors.primary}
      /> */}
      <G fill={fill} stroke={stroke} fillOpacity={iconConfig.fillOpacity || props.fillOpacity}>
        {iconConfig.paths.map((path: string) => (
          <Path d={path} key={path} />
        ))}
      </G>
    </Svg>
  );
};

export const createSvgIcon = (config: CreateSvgIcon): React.FC<ConcreteSvgIcon> => {
  const createdIcon = (props: ConcreteSvgIcon) => {
    return <SvgIcon {...config} {...props} />;
  };
  return memo(createdIcon);
};
