import { createRestyleComponent, createRestyleFunction } from '@shopify/restyle';
import { lighten } from 'polished';

import { Box } from '../box';

const withLightenColor = createRestyleFunction({
  property: 'lightenColor',
  styleProperty: 'backgroundColor',
  transform: ({ value, theme }) => {
    return value ? theme.colors[value] ?? lighten(0.33, theme.colors.primaryColor) : lighten(0.33, theme.colors.primaryColor);
  },
});
export const BoxWithLighten = createRestyleComponent([withLightenColor], Box);
