import type { IOrder } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useValidateCartMutation = (
  options?: UseMutationOptions<IOrder, Error, boolean | undefined>,
): UseMutationResult<IOrder, Error, boolean | undefined> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (isDigitalCart?: boolean) => {
    return isDigitalCart
      ? axios.get(`/api/v2/stores/${storeId}/cart-giftcards/validate`)
      : axios.patch(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/validate`, {});
  }, options);
};
