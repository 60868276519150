import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const DeleteIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M3.99999 12C3.99999 12.7333 4.59999 13.3333 5.33333 13.3333H10.6667C11.4 13.3333 12 12.7333 12 12V4H3.99999V12ZM5.33333 5.33333H10.6667V12H5.33333V5.33333ZM10.3333 2L9.66666 1.33333H6.33333L5.66666 2H3.33333V3.33333H12.6667V2H10.3333Z',
    ],
    viewBox: '0 0 16 16',
  },
  ...BaseConfig,
});
