import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ChevronRight = createSvgIcon({
  iconConfig: {
    paths: ['M1 17L8 9L0.999999 1'],
    viewBox: '0 0 18 18',
  },
  ...BaseConfig,
});
