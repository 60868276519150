import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const MenuIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M0.933333 0C0.417877 0 0 0.417851 0 0.933333C0 1.44881 0.417877 1.86667 0.933333 1.86667H18.2C18.7155 1.86667 19.1333 1.44881 19.1333 0.933333C19.1333 0.417851 18.7155 0 18.2 0H0.933333ZM0.933333 6.06667C0.417877 6.06667 0 6.48452 0 7C0 7.51548 0.417877 7.93333 0.933333 7.93333H18.2C18.7155 7.93333 19.1333 7.51548 19.1333 7C19.1333 6.48452 18.7155 6.06667 18.2 6.06667H0.933333ZM0.933333 12.1333C0.417877 12.1333 0 12.5512 0 13.0667C0 13.5821 0.417877 14 0.933333 14H18.2C18.7155 14 19.1333 13.5821 19.1333 13.0667C19.1333 12.5512 18.7155 12.1333 18.2 12.1333H0.933333Z',
    ],
    viewBox: '0 0 20 14',
  },
  ...BaseConfig,
});
