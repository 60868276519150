import type { BoxProps } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { FC } from 'react';
import React from 'react';

import { Spinner } from '../spinner';

type BlockLoaderType = BoxProps<Theme> & {
  stroke?: keyof Theme['colors'];
};
export const BlockLoader: FC<BlockLoaderType> = ({ stroke, ...boxProps }) => {
  return (
    <Box testID="block-loader" alignItems="center" justifyContent="center" backgroundColor="gray200" {...boxProps}>
      <Spinner stroke={stroke ?? 'white'} />
    </Box>
  );
};
