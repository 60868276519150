import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const DashedStarIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M24.5 6H22.5V10H24.5C32.232 10 38.5 16.268 38.5 24C38.5 31.732 32.232 38 24.5 38C16.768 38 10.5 31.732 10.5 24V22H6.5V24C6.5 33.9411 14.5589 42 24.5 42C34.4411 42 42.5 33.9411 42.5 24C42.5 14.0589 34.4411 6 24.5 6Z',
      'M18.0242 11.5841L19.7966 10.6575L17.9434 7.11265L16.171 8.03925C15.4336 8.42477 14.7264 8.85974 14.0539 9.33968C13.2398 9.9207 12.4763 10.5677 11.7712 11.2729C11.0654 11.9789 10.4179 12.7433 9.83648 13.5584C9.48663 14.049 9.1607 14.5579 8.86043 15.0836C8.75252 15.2725 8.64793 15.4635 8.54674 15.6567L7.61854 17.4283L11.1617 19.2846L12.0899 17.5131C12.1685 17.363 12.2498 17.2144 12.3337 17.0676C12.5672 16.6588 12.8207 16.2629 13.0931 15.8811C13.5456 15.2465 14.0499 14.6511 14.5998 14.1012C15.1492 13.5517 15.7438 13.0478 16.3776 12.5956C16.901 12.222 17.4511 11.8837 18.0242 11.5841Z',
      'M29.3943 32L28.4512 26.225L32.5 22.0368L26.9065 21.1697L24.5 16L22.0935 21.1697L16.5 22.0368L20.5488 26.225L19.6057 32L24.5 29.2843L29.3943 32Z',
    ],
    viewBox: '0 0 49 48',
  },
  ...BaseConfig,
});
