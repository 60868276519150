import type { UserLoggedInContextDto } from '@goparrot/webstore-gateway-public-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

interface UseVerificationCodeMutation {
  phoneNumber: string;
  inputCode: string;
}

export const useVerificationCodeMutation = (
  options?: UseMutationOptions<UserLoggedInContextDto, AxiosError, UseVerificationCodeMutation>,
): UseMutationResult<UserLoggedInContextDto, AxiosError, UseVerificationCodeMutation> => {
  const { analytics } = usePlatformStoreState();
  const { merchantId, storeId } = useWebStore();
  return useMutation(async ({ phoneNumber, inputCode }) => {
    analytics.track(AnalyticsActionsEnum.ACCOUNT_SUBMIT_VALIDATION_CODE, {
      phoneNumber: phoneNumber,
      code: inputCode,
    });
    const response: UserLoggedInContextDto = await axios.get(`/api/v2/users-by-phones/${phoneNumber}/codes/${inputCode}`, {
      params: { merchantId, storeId },
    });

    analytics.track(AnalyticsActionsEnum.ACCOUNT_SUCCESS_LOGIN, {
      oldUserId: response.fromUserId,
      newUserId: response.user?.userId,
      phoneNumber: phoneNumber,
      code: inputCode,
    });
    return response;
  }, options);
};

export const useVerificationCodeResend = (options?: UseMutationOptions<void, AxiosError, string>): UseMutationResult<void, AxiosError, string> => {
  const { analytics } = usePlatformStoreState();
  const { merchantId, storeId } = useWebStore();
  return useMutation(async (phoneNumber) => {
    analytics.track(AnalyticsActionsEnum.ACCOUNT_RESEND_VALIDATION_CODE, {
      phoneNumber: phoneNumber,
    });
    await axios.post(`/api/v2/users-by-phones/${phoneNumber}/codes`, undefined, { params: { merchantId, storeId } });
  }, options);
};
