import type { PropsWithChildren } from 'react';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const ReactQueryProvider: React.FC<PropsWithChildren<{ client?: QueryClient }>> = ({ client, children }) => (
  <QueryClientProvider client={client ?? queryClient}>{children}</QueryClientProvider>
);

export * from 'react-query';
