import type { BoxProps } from '@shopify/restyle';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import type { ReactNode } from 'react';
import React, { forwardRef, memo } from 'react';

import { Box } from '../box';

export const ColFull = memo(
  forwardRef(({ children, ...props }: BoxProps<Theme> & { children: ReactNode }, forwardedRef) => {
    const { isDesktop, width } = useWindowDimensions();

    const getWrapperWidth = () => {
      if (isDesktop && width > 1400) {
        return 1400;
      }
      return '100%';
    };
    return (
      <Box ref={forwardedRef} width={getWrapperWidth()} {...props}>
        {children}
      </Box>
    );
  }),
);
