import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const HomeIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M12 2.59L4 10.59V18C4 19.65 5.35 21 7 21H17C18.65 21 20 19.65 20 18V10.59L12 2.59ZM13 19H11V15H13V19ZM18 18C18 18.55 17.55 19 17 19H15V13H9V19H7C6.45 19 6 18.55 6 18V11.41L12 5.41L18 11.41V18Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
