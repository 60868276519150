import { datadogRum } from '@datadog/browser-rum';

/**
 * Wrapper function for initializing DataDog RUM monitoring if enabled for current env
 */
export const datadogRumInitWrapper = () => {
  if (window.DD_RUM_ENABLED) {
    datadogRum.init({
      applicationId: window.DD_RUM_APPLICATION_ID,
      clientToken: window.DD_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      version: window.APPLICATION_VERSION,
      enableExperimentalFeatures: ['feature_flags'],
      sessionReplaySampleRate: 100,
      service: 'webstore-prod-aws',
      defaultPrivacyLevel: 'mask-user-input',
      trackUserInteractions: true,
    });
  }
};
