import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CloseIcon = createSvgIcon({
  iconConfig: {
    paths: ['M11 1L1 11', 'M1 1L11 11'],
    viewBox: '0 0 12 12',
  },
  ...BaseConfig,
});
