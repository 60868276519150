import { useEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const isMobile = windowDimensions.width <= 768;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowDimensions,
    isMobile,
    isTablet: windowDimensions.width >= 769 && windowDimensions.width < 1024,
    isDesktop: windowDimensions.width >= 1024,
    isPortraitMobile: windowDimensions.width > windowDimensions.height && isMobile,
  };
}
