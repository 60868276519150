export class WebstoreStorage {
  private storageId: string = 'LS_';

  async getItem<T>(key: string): Promise<T | null> {
    let data: string | null;

    try {
      data = localStorage.getItem(`${this.storageId}${key}`);
    } catch (e) {
      return null;
    }

    if (data === null) {
      return null;
    }

    return JSON.parse(data);
  }

  async setItem(key: string, data: any): Promise<void> {
    if (typeof data == 'object') {
      data = JSON.stringify(data);
    }

    try {
      localStorage.setItem(`${this.storageId}${key}`, data);
    } catch (e) {
      console.error(e);
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      localStorage.removeItem(`${this.storageId}${key}`);
    } catch (e) {
      console.error(e);
    }
  }
}
