import React, { Children, cloneElement } from 'react';

import { Box } from '../box';

export const getSpacedChildren = (
  children: React.ReactNode,
  space: undefined | any,
  axis: 'X' | 'Y',
  reverse: string,
  divider: JSX.Element | undefined,
): any => {
  let childrenArray = Children.toArray(children);
  childrenArray = reverse === 'reverse' ? [...childrenArray].reverse() : childrenArray;

  const orientation = axis === 'X' ? 'vertical' : 'horizontal';

  // If there's a divider, we wrap it with a Box and apply vertical and horizontal margins else we add a spacer Box with height or width
  if (divider) {
    const spacingProp: Record<string, unknown> = {
      ...(axis === 'X' ? { mx: space } : { my: space }),
    };

    divider = cloneElement(divider, {
      orientation,
      ...spacingProp,
    });

    childrenArray = childrenArray.map((child: any, index: number) => {
      return (
        <React.Fragment key={child.key ?? `spaced-child-${index}`}>
          {child}
          {index < childrenArray.length - 1 && divider}
        </React.Fragment>
      );
    });
  } else {
    const spacingProp: Record<string, unknown> = {
      ...(axis === 'X' ? { width: space } : { height: space }),
    };
    childrenArray = childrenArray.map((child: any, index: number) => {
      return (
        <React.Fragment key={child.key ?? `spaced-child-${index}`}>
          {child}
          {index < childrenArray.length - 1 && <Box {...spacingProp} />}
        </React.Fragment>
      );
    });
  }

  return childrenArray;
};
