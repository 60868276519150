export function getDiffInMinutes(date1: string | Date, date2: string | Date): number {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  const diffMin = (dt1.getTime() - dt2.getTime()) / 1000 / 60;
  return Math.round(diffMin);
}

export function getDiffInSeconds(date1: string | Date, date2: string | Date): number {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  const diffMin = (dt1.getTime() - dt2.getTime()) / 1000;
  return Math.round(diffMin);
}
