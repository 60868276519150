import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const NoteIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M17 4.16992C17.72 4.16992 18.45 4.44992 19 4.99992C20.1 6.09992 20.1 7.89992 19 8.99992L17 10.9999L8 19.9999H4V15.9999L13 6.99992L15 4.99992C15.55 4.44992 16.28 4.16992 17 4.16992ZM17.01 6.16992C16.88 6.16992 16.63 6.19992 16.42 6.40992L15.84 7.00992L17.01 8.17992L17.6 7.58992C17.81 7.36992 17.84 7.12992 17.84 6.99992C17.84 6.86992 17.82 6.61992 17.6 6.40992C17.38 6.19992 17.14 6.16992 17.01 6.16992ZM6 17.9999H7.17L15.58 9.58992L14.41 8.41992L6 16.8299V17.9999ZM12 18H20V20H12V18Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
});
