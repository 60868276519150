import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const GenericItemSecondIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M9 2C8.44772 2 8 2.44772 8 3V9.0726C6.23714 10.1257 5 11.9009 5 14V15H11V21L12 22L13 21V15H19V14C19 11.9009 17.7629 10.1257 16 9.0726V3C16 2.44772 15.5523 2 15 2H9ZM10 10.297V4H14V10.297L14.5658 10.5697C15.7186 11.1255 16.517 12.0067 16.84 13H7.16003C7.48298 12.0067 8.28144 11.1255 9.43424 10.5697L10 10.297Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
