import type { IButtonStylesApp } from '@goparrot/webstore-sdk';
import type { BoxProps, ColorProps, TextProps, VariantProps } from '@shopify/restyle';
import { color, createRestyleComponent, createVariant, useTheme } from '@shopify/restyle';
import { useFocusRing } from '@webstore-monorepo/shared/hooks/use-focus-ring';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Spinner } from '@webstore-monorepo/ui/loading';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import type { PropsWithChildren, RefCallback, RefObject } from 'react';
import React, { Children, cloneElement, forwardRef, isValidElement, memo, useRef } from 'react';
import { Pressable, TouchableOpacity } from 'react-native';

const buttonVariant = createVariant({ themeKey: 'buttonVariants' });
const ButtonContainer = createRestyleComponent<
  VariantProps<Theme, 'buttonVariants'> & ColorProps<Theme> & Pick<TextPropsExtended, 'fontSize'> & React.ComponentProps<typeof Box>,
  Theme
>([buttonVariant, color], Box);

type ColorScheme = 'primary' | 'secondary' | 'tertiary';
type ButtonVariants = keyof Theme['buttonVariants'];
type ButtonSizes = 'custom' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'full-width';

export type ButtonProps = React.ComponentProps<typeof ButtonContainer> & {
  onPress: () => void;
  label?: string;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  colorScheme?: ColorScheme;
  isLoadingText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  leftIcon?: JSX.Element | Array<JSX.Element>;
  rightIcon?: JSX.Element | Array<JSX.Element>;
  buttonStyle?: IButtonStylesApp & BoxProps<Theme>;
  buttonStyleInactive?: IButtonStylesApp & BoxProps<Theme>;
  testID?: string;
  withClickEffect?: boolean;
  //TODO: need fix it
  ref?: RefCallback<any> | RefObject<any>;
};

const getButtonSize = (size: ButtonSizes = 'md'): BoxProps<Theme> => {
  switch (size) {
    case 'custom': {
      return {};
    }
    case 'sm':
      return {
        minWidth: 120,
        minHeight: 30,
        padding: 's',
      };
    case 'md':
      return {
        minWidth: 160,
        minHeight: 44,
        padding: 'm',
      };
    case 'full-width':
      return {
        width: '100%',
        height: 44,
        padding: 'm',
      };
    default:
      return {
        minHeight: 40,
        padding: 'l',
      };
  }
};

const getButtonVariant = (variant: ButtonVariants): { box: BoxProps<Theme>; text?: TextProps<Theme> } => {
  switch (variant) {
    case 'stroked':
      return {
        box: {
          borderWidth: 1,
        },
      };
    case 'link':
      return {
        box: {
          width: 'auto',
          height: 'auto',
          borderWidth: 0,
          minWidth: 'auto',
          minHeight: 'auto',
          paddingLeft: 'none',
          paddingRight: 'none',
          paddingTop: 'none',
          paddingBottom: 'none',
        },
        text: {
          fontWeight: '700',
        },
      };
    default:
      return {
        box: {},
      };
  }
};

const getColorScheme = (
  colorScheme: ColorScheme,
  variant: ButtonVariants,
  isHovered: boolean,
  isInactive?: boolean,
): BoxProps<Theme> & { color: keyof Theme['colors'] } => {
  if (isInactive) {
    return {
      backgroundColor: `${colorScheme}ButtonColorInactive`,
      color: `${colorScheme}ButtonTextColorInactive`,
    };
  }
  switch (variant) {
    case 'contained':
      return {
        backgroundColor: isHovered ? `${colorScheme}ButtonColorHover` : `${colorScheme}ButtonColor`,
        color: `${colorScheme}ButtonTextColor`,
      };
    case 'stroked':
      return {
        borderColor: isHovered ? `${colorScheme}ButtonColorHover` : `${colorScheme}ButtonColor`,
        color: isHovered ? `${colorScheme}ButtonColorHover` : `${colorScheme}ButtonColor`,
        backgroundColor: 'transparent',
      };
    case 'link':
      return {
        backgroundColor: 'transparent',
        borderColor: `transparent`,
        color: `${colorScheme}ButtonColor`,
      };
    default:
      return {
        backgroundColor: isHovered ? `${colorScheme}ButtonColorHover` : `${colorScheme}ButtonColor`,
        color: `${colorScheme}ButtonTextColor`,
      };
  }
};

const PressableWrapper = ({
  children,
  withClickEffect,
  ...props
}: PropsWithChildren<React.ComponentProps<typeof TouchableOpacity> & { withClickEffect: boolean; variant: ButtonVariants; isDisabled?: boolean }>) => {
  return withClickEffect ? (
    <TouchableOpacity {...props} activeOpacity={props?.isDisabled ? 1 : props.variant === 'link' ? 0.5 : 0.7}>
      {children}
    </TouchableOpacity>
  ) : (
    <Pressable {...props}>{children}</Pressable>
  );
};

export const Button = memo(
  forwardRef(
    (
      {
        onPress,
        variant = 'contained',
        borderRadius = 'xs',
        leftIcon,
        rightIcon,
        colorScheme = 'primary',
        backgroundColor,
        color,
        fontSize,
        isDisabled,
        isLoading,
        isLoadingText,
        size = 'md',
        children,
        buttonStyle,
        buttonStyleInactive,
        withClickEffect = false,
        ...rest
      }: ButtonProps,
      forwardRef,
    ) => {
      const ref = useRef();
      const { hoverProps, isHovered } = useHover({}, forwardRef ?? ref);
      const theme = useTheme<Theme>();
      const buttonSize = getButtonSize(size);
      const coloredStyles = getColorScheme(colorScheme, variant, isHovered, isDisabled || isLoading);
      const buttonVariants = getButtonVariant(variant);
      const { isFocusVisible, focusProps } = useFocusRing();

      let startIcon = leftIcon;
      let endIcon = rightIcon;
      if (leftIcon) {
        startIcon = leftIcon;
      }
      if (rightIcon) {
        endIcon = rightIcon;
      }
      if (endIcon && isValidElement(endIcon)) {
        endIcon = Children.map(endIcon, (child: JSX.Element, index: number) => {
          return cloneElement(child, {
            key: `button-end-icon-${index}`,
            // TODO: find a better way to pass color to the icon
            fill: theme.colors[coloredStyles.color],
            ...child.props,
          });
        });
      }
      if (startIcon && isValidElement(startIcon)) {
        startIcon = Children.map(startIcon, (child: JSX.Element, index: number) => {
          return cloneElement(child, {
            key: `button-start-icon-${index}`,
            // TODO: find a better way to pass color to the icon
            fill: theme.colors[coloredStyles.color],
            ...child.props,
          });
        });
      }

      return (
        <PressableWrapper
          withClickEffect={withClickEffect}
          variant={variant}
          testID={rest.testID}
          accessibilityRole={rest.accessibilityRole ?? 'button'}
          accessibilityLabel={rest.accessibilityLabel ?? ''}
          // dislabled status only on isLoading
          // to prevent the button from being pressed, handle logic inside the onPress function
          disabled={isLoading}
          isDisabled={isDisabled}
          onPress={onPress}
          {...focusProps}
          // @ts-ignore
          style={{
            ...(isFocusVisible
              ? {
                  outlineColor: theme.colors.primaryColor,
                  outlineWidth: 2,
                  outlineStyle: 'solid',
                  outlineOffset: 0,
                  borderRadius: theme.borderRadii.xs,
                }
              : {}),
            width: buttonSize.width,
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <ButtonContainer
            ref={forwardRef ?? ref}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            px="m"
            py="s"
            borderRadius="xs"
            {...buttonSize}
            {...(backgroundColor ? { ...coloredStyles, backgroundColor } : coloredStyles)}
            {...hoverProps}
            {...buttonVariants.box}
            {...buttonStyle}
            {...(isDisabled
              ? {
                  backgroundColor: `${colorScheme}ButtonColorInactive`,
                  ...buttonStyleInactive,
                }
              : {})}
          >
            {!isLoading ? (
              <>
                {startIcon}
                {children ? (
                  <Text
                    variant="button"
                    ml={startIcon ? 'xs' : undefined}
                    mr={endIcon ? 'xs' : undefined}
                    {...(color ? { ...coloredStyles, color } : coloredStyles)}
                    {...buttonVariants.text}
                    fontSize={fontSize}
                    {...buttonStyle}
                    {...(isDisabled && {
                      color: `${colorScheme}ButtonTextColorInactive`,
                      ...buttonStyleInactive,
                    })}
                  >
                    {children}
                  </Text>
                ) : null}
                {endIcon}
              </>
            ) : isLoadingText ? (
              isLoadingText
            ) : (
              <Spinner stroke={(color ? color : coloredStyles.color) as unknown as string} />
            )}
          </ButtonContainer>
        </PressableWrapper>
      );
    },
  ),
);
