import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { NotificationBannerEnum } from './notification-banner.enum';

type State = {
  variant: NotificationBannerEnum;
};

type Action = { type: 'update'; payload: Partial<State> };
type Dispatch = (action: Action) => void;

const NotificationBannerStateContext = createContext<State>({ variant: NotificationBannerEnum.info });
const NotificationBannerDispatchContext = createContext<Dispatch>(() => void 0);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

interface Props {
  initial?: State;
  children?: React.ReactNode;
}

export const NotificationBannerProvider: React.FC<Props> = ({ children, initial }) => {
  const [state, dispatch] = useReducer(reducer, initial as State);

  useEffect(() => {
    if (initial) dispatch({ type: 'update', payload: { ...initial } });
  }, [initial]);

  const handleDispatch = (action: Action) => {
    dispatch(action);
  };

  return (
    <NotificationBannerStateContext.Provider value={state}>
      <NotificationBannerDispatchContext.Provider value={handleDispatch}>{children}</NotificationBannerDispatchContext.Provider>
    </NotificationBannerStateContext.Provider>
  );
};

export const useNotificationBannerState = () => {
  const context = useContext(NotificationBannerStateContext);
  if (context === undefined) {
    throw new Error('useNotificationBannerState must be used within a NotificationBannerProvider');
  }
  return context;
};

export const useNotificationBannerDispatch = () => {
  const context = React.useContext(NotificationBannerDispatchContext);
  if (context === undefined) {
    throw new Error('useNotificationBannerDispatch must be used within a NotificationBannerProvider');
  }
  return context;
};
