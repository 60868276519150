import type { IOrder } from '@goparrot/order-sdk';
import { OrderStatusEnum } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export interface MerchantUserOrders {
  rows: IOrder[];
  pagination: {
    limit: number;
    offset: number;
    total: number;
  };
}

interface UserOrdersParams {
  page?: number;
  pageSize?: number;
  onlyInProgress?: boolean;
}
export const useLatestMerchantUserOrders = (
  { page = 0, pageSize = 10, onlyInProgress = false }: UserOrdersParams,
  options?: UseQueryOptions<MerchantUserOrders, AxiosError, MerchantUserOrders, any>,
): UseQueryResult<MerchantUserOrders> => {
  const getOrders = async () => {
    const { DELAYED, DELAYED_PAY, NEW, READY, RECEIVED, REFUNDED, COMPLETE, CANCELED } = OrderStatusEnum;
    const statuses = onlyInProgress ? [DELAYED, DELAYED_PAY, NEW, RECEIVED, READY] : [DELAYED, DELAYED_PAY, NEW, READY, RECEIVED, REFUNDED, COMPLETE, CANCELED];
    return axios.post<MerchantUserOrders>(`/api/v3/orders?offset=${pageSize * page}`, {
      statuses,
      onlyActiveStores: true,
      sort: {
        'dateInfo.fulfilledAt': 'DESC',
      },
    }) as unknown as MerchantUserOrders;
  };

  return useQuery(QueryKeysEnum.getMerchantUserOrders, getOrders, options);
};
