import type { IOrder } from '@goparrot/order-sdk';
import type { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';
import { DateTime } from 'luxon';

export const orderAsapReceiveTimeInfo = (cart: IOrder, store: ReadStoreDto, hideDelayedOrderTime: boolean): string => {
  const isDeliveryRequired = DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo.type);
  const receiveMethod = isDeliveryRequired ? ReceiveMethodEnum.DELIVERY : ReceiveMethodEnum.PICKUP;
  const dateInfo = DateTime.fromISO(`${cart?.dateInfo?.fulfilledAt}`, {
    zone: store.contactInfo.timeZone.id ?? 'America/New_York',
    locale: 'en-US',
  });
  const orderReceiveHour = DelayedOrdersUtils.getFormattedIntervalForSlot(store, receiveMethod, dateInfo.toISO()) ?? '';
  const orderReceiveDateAndMonth = dateInfo.toLocaleString({ day: 'numeric', month: 'long' });
  return `${orderReceiveDateAndMonth} ${!hideDelayedOrderTime ? `at approx. ${orderReceiveHour}` : ''}`;
};
