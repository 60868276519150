import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const SearchIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M6.72724 1C5.5945 1 4.48719 1.3359 3.54536 1.96521C2.60352 2.59453 1.86944 3.489 1.43596 4.53552C1.00248 5.58203 0.889063 6.73359 1.11005 7.84456C1.33104 8.95554 1.8765 9.97603 2.67747 10.777C3.47844 11.578 4.49893 12.1234 5.60991 12.3444C6.72088 12.5654 7.87244 12.452 8.91895 12.0185C9.96547 11.585 10.8599 10.851 11.4893 9.90912C12.1186 8.96728 12.4545 7.85997 12.4545 6.72723C12.4544 5.20831 11.8509 3.75162 10.7769 2.67758C9.70285 1.60353 8.24616 1.0001 6.72724 1V1Z',
      'M11 11.0002L14.9998 15.0001',
    ],
    viewBox: '0 0 16 16',
  },
  ...BaseConfig,
});
