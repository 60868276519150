import type { CreateUserProfileDto } from '@goparrot/customer-sdk';
import type { UserLoggedInContextDto } from '@goparrot/webstore-gateway-public-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useRegisterGuestUserMutation = (
  options?: UseMutationOptions<UserLoggedInContextDto, AxiosError, CreateUserProfileDto>,
): UseMutationResult<UserLoggedInContextDto, AxiosError, CreateUserProfileDto> => {
  const { analytics } = usePlatformStoreState();
  const { storeId } = useWebStore();

  return useMutation(async (data) => {
    analytics.track(AnalyticsActionsEnum.GUEST_ACCOUNT_SUBMIT_DATA, {
      userData: data,
    });
    return axios.post(`/api/v2/store/${storeId}/customers/confirm-guest`, data, { data });
  }, options);
};

export const useUpdateGuestUserMutation = (
  options?: UseMutationOptions<UserLoggedInContextDto, AxiosError, Partial<CreateUserProfileDto>>,
): UseMutationResult<UserLoggedInContextDto, AxiosError, Partial<CreateUserProfileDto>> => {
  const { analytics } = usePlatformStoreState();
  const { storeId } = useWebStore();

  return useMutation(async (data) => {
    analytics.track(AnalyticsActionsEnum.GUEST_ACCOUNT_SUBMIT_DATA, {
      userData: data,
    });
    return axios.patch(`/api/v2/store/${storeId}/customers/confirm-guest`, data, { data });
  }, options);
};
