import type { IOrder, IOrderElement } from '@goparrot/order-sdk';
import { OrderElementTypeEnum } from '@goparrot/order-sdk';

export interface ComboConfig {
  comboGroup: IOrderElement;
  items: IOrderElement[];
}

export type ExtractedComboSet = Record<string, ComboConfig>;

export const useExtractCombos = (order: IOrder) => {
  const combos = order.elements.reduce<ExtractedComboSet>((acc, item) => {
    if ([OrderElementTypeEnum.BUNDLE_ITEM, OrderElementTypeEnum.COMBO].includes(item.type)) {
      acc[item.uniqueName] = {
        comboGroup: item,
        items: [],
      };
    }
    return acc;
  }, {});

  return order.elements.reduce((acc, item) => {
    if (!item.parentUniqueName) return acc;

    acc[item.parentUniqueName].items.push(item);

    return acc;
  }, combos);
};
