import type { IFontStylesApp } from '@goparrot/webstore-sdk';
import { Button } from '@webstore-monorepo/ui/button';
import React from 'react';

import { notificationBlockVariantStyles } from './notification-banner.enum';
import { useNotificationBannerState } from './NotificationBannerProvider';

export interface NotificationBannerButtonProps {
  buttonText: string;
  onButtonClick: () => void;
  style?: IFontStylesApp;
}

export const NotificationBannerButton: React.FC<NotificationBannerButtonProps> = ({ buttonText, onButtonClick, style }) => {
  const { variant } = useNotificationBannerState();
  const color = notificationBlockVariantStyles[variant].buttonTextColor;

  return (
    <Button variant="link" alignItems="flex-start" buttonStyle={{ marginTop: 3 }} color={color} onPress={onButtonClick} {...style}>
      {buttonText}
    </Button>
  );
};
