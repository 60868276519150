import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const DefaultCartImage = createSvgIcon({
  iconConfig: {
    paths: [
      'M7 2H9V6.5C9 6.77614 9.22386 7 9.5 7C9.77614 7 10 6.77614 10 6.5V2H12V6C12 9.17573 11.2035 11.3544 10.3575 12.7645C9.93679 13.4657 9.5097 13.967 9.17586 14.3009C9.11378 14.3629 9.05495 14.4192 9 14.4699V22H7V14.4699C6.94505 14.4192 6.88622 14.3629 6.82414 14.3009C6.4903 13.967 6.06321 13.4657 5.64251 12.7645C4.79646 11.3544 4 9.17573 4 6V2H6V6.5C6 6.77614 6.22386 7 6.5 7C6.77614 7 7 6.77614 7 6.5V2ZM7.35749 11.7355C7.58425 12.1134 7.80799 12.4083 8 12.63C8.19201 12.4083 8.41575 12.1134 8.64251 11.7355C9.02841 11.0923 9.43157 10.1945 9.69725 8.98614C9.63327 8.99506 9.56742 9 9.5 9C9.08351 9 8.72706 8.81156 8.49999 8.66018C8.32482 8.5434 8.15603 8.40108 8 8.24247C7.84398 8.40108 7.6752 8.54339 7.50004 8.66017C7.27298 8.81154 6.91653 9 6.50004 9C6.4326 9 6.36674 8.99506 6.30275 8.98614C6.56842 10.1945 6.97159 11.0923 7.35749 11.7355Z',
      'M19 22V3.00013V2H17.5615C16.1849 2 14.985 2.93689 14.6511 4.27239L13.3045 9.65889C13.0489 10.6812 13.3484 11.7627 14.0936 12.5078L17 15.4142V22H19ZM16.5914 4.75746C16.6527 4.51213 16.8017 4.30714 17 4.17255V12.5858L15.5078 11.0936C15.2594 10.8452 15.1596 10.4847 15.2448 10.144L16.5914 4.75746Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
});
