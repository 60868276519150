import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CalendarIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M14.25 2.25H13.5V0.749996H12V2.25H6V0.749996H4.5V2.25H3.75C2.9175 2.25 2.2575 2.925 2.2575 3.75L2.25 14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM14.25 14.25H3.75V6H14.25V14.25ZM5.25 7.5H9V11.25H5.25V7.5Z',
    ],
    viewBox: '0 0 18 18',
  },
  ...BaseConfig,
});
