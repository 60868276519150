import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const LoyaltyIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M17.6399 20.8699L11.9999 17.7499L6.35993 20.8699L7.43993 14.2699L2.92993 9.6499L9.15993 8.6999L11.9999 2.6499L14.8399 8.6999L21.0699 9.6499L16.5599 14.2699L17.6399 20.8699ZM11.9999 15.4699L15.0099 17.1299L14.4299 13.5999L16.9199 11.0399L13.4799 10.5099L11.9999 7.3499L10.5199 10.5099L7.07993 11.0399L9.56993 13.5999L8.98993 17.1299L11.9999 15.4699Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
