import type { IOrder } from '@goparrot/order-sdk';
import { OrderElementTypeEnum, OrderStoreItemElementSubTypeEnum } from '@goparrot/order-sdk';

export const useNewCartEmpty = () => {
  return function (cart: IOrder, isNewCheckout: boolean): boolean {
    const emptyCart = cart.elements.length === 0;
    const noGiftcards = cart.giftcards?.length === 0;

    if (isNewCheckout) {
      const hasAnyItems = cart.elements.some(
        (item) => item.type === OrderElementTypeEnum.ELEMENT && item.subtype !== OrderStoreItemElementSubTypeEnum.PACKING_INSTRUCTIONS,
      );
      return noGiftcards && !hasAnyItems;
    }

    return emptyCart && noGiftcards;
  };
};
