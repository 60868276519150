import type { HoverProps } from '@react-aria/interactions';
import { useHover as useHoverWeb } from '@react-aria/interactions';
import { useEffect } from 'react';

export const attachEventHandlersOnRef = (props: any, ref: any) => {
  if (ref && ref.current) {
    for (const key in props) {
      if (key.startsWith('on')) {
        ref.current[key.toLowerCase()] = props[key];
      }
    }
  }
};

export const useHover = (props: HoverProps, ref: any) => {
  const params = useHoverWeb(props);
  useEffect(() => {
    attachEventHandlersOnRef(params.hoverProps, ref);
  }, []);

  return params;
};
