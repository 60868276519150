import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

interface RegistrationDto {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  birthday?: string | null;
}

export const useRegistrationMutation = (
  options?: UseMutationOptions<unknown, AxiosError, RegistrationDto>,
): UseMutationResult<unknown, AxiosError, RegistrationDto> => {
  const { analytics } = usePlatformStoreState();
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (data) => {
    analytics.track(AnalyticsActionsEnum.ACCOUNT_SUBMIT_REGISTRATION_DATA, {
      userData: data,
    });
    return axios.post(`/api/v2/users`, data, { params: { merchantId, storeId } });
  }, options);
};
