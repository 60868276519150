import type { IItemsValidationInfo } from '@goparrot/vendor-sdk';

export const formatSynchronizeWithPosError = (error: string, items: IItemsValidationInfo): string => {
  let errorMessage = error || 'We could not submit your order, please try to remove items and pay again';
  if (Object.keys(items || {}).length) {
    const titles = Object.keys(items)
      .map((guid) => items[guid].title)
      .filter(Boolean);
    if (titles.length) {
      errorMessage = `Something is wrong with ${titles.join(', ')}, please try to order without ${titles.length > 1 ? 'them' : 'it'}`;
    }
  }
  return errorMessage;
};
