import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const FavoriteIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M8 2H10V4H8C7.45 4 7 4.45 7 5V19C7 19.55 7.45 20 8 20H16C16.55 20 17 19.55 17 19V10H19V19C19 20.66 17.66 22 16 22H8C6.34 22 5 20.66 5 19V5C5 3.34 6.34 2 8 2Z',
      'M14.0769 2H15.718V4.50684L17.9282 3.23077L18.7853 4.71538L16.5229 6.02161L18.7949 7.33334L17.9377 8.81795L15.718 7.53637V10H14.0769V7.43378L11.8571 8.71538L11 7.23077L13.0943 6.02161L11.0095 4.81795L11.8667 3.33334L14.0769 4.60944V2Z',
      'M15 16H9V18H15V16Z',
      'M15 12H9V14H15V12Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
