import type { IOrder } from '@goparrot/order-sdk';
import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

type Action = { type: 'update'; payload: IOrder | any } | { type: 'initiate'; payload: IOrder | any }; // TODO REMOVE ANY
type Dispatch = (action: Action) => void;

export const CartStateContext = createContext<IOrder>({} as IOrder);
export const CartDispatchContext = createContext<Dispatch>(() => void 0);

const cartReducer = (state: IOrder, action: Action): IOrder => {
  switch (action.type) {
    case 'initiate':
      return { ...action.payload };
    case 'update':
      return { ...state, ...action.payload };

    default: {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

type Props = {
  cart?: IOrder;
  onUpdateCartInUserContext: (cart?: IOrder) => void;
};

export const CartProvider: React.FC<PropsWithChildren<Props>> = ({ cart, onUpdateCartInUserContext, children }) => {
  const [state, dispatch] = useReducer(cartReducer, {} as IOrder);

  const handleDispatch = useCallback((action: Action) => {
    dispatch(action);
  }, []);

  useEffect(() => {
    if (cart) {
      handleDispatch({ type: 'initiate', payload: cart });
      onUpdateCartInUserContext(undefined);
    }
  }, [cart, handleDispatch]);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={handleDispatch}>{state.uuid && children}</CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export const useCartState = () => {
  const context = useContext(CartStateContext);
  if (context === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }
  return context;
};

export const useCartDispatch = () => {
  const context = useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }
  return context;
};
