import DOMPurify from 'dompurify';
import isArray from 'lodash/isArray';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import React from 'react';
import type { ToastOptions } from 'react-hot-toast';
import { toast, Toaster } from 'react-hot-toast';
import styled from 'styled-components';

import type { SSRContext } from '../../interfaces/AppState';

const WrapperStyled = styled('span')`
  display: flex;
  align-items: center;
  background: inherit;
  color: #fff;
  line-height: 1.3;
  will-change: transform;
  pointer-events: auto;
  border-radius: 8px;
  max-width: 350px;
  margin: -4px -10px;
  padding: 8px 10px;
`;

const IconWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
`;

const ContentStyled = styled('div')`
  display: flex;
  justify-content: center;
  margin: 4px 10px;
  color: inherit;
  flex: 1 1 auto;
  & span {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ErrorIcon = styled('div')`
  width: 20px;
  opacity: 0;
  height: 20px;
  border-radius: 10px;
  background: white;
  position: relative;
  transform: rotate(45deg);
  animation: go2264125279 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: 100ms;
  &:before,
  &:after {
    content: '';
    animation: go3020080000 0.15s ease-out forwards;
    animation-delay: 150ms;
    position: absolute;
    border-radius: 3px;
    opacity: 0;
    background: #df403f;
    bottom: 9px;
    left: 4px;
    height: 2px;
    width: 12px;
  }
  &:before {
    animation: go463499852 0.15s ease-out forwards;
    animation-delay: 180ms;
    transform: rotate(90deg);
  }
`;

const SuccessIcon = styled('div')`
  width: 20px;
  opacity: 0;
  height: 20px;
  border-radius: 10px;
  background: #61d345;
  position: relative;
  transform: rotate(45deg);
  animation: go1310225428 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: 100ms;
  &:before {
    animation: go463499852 0.15s ease-out forwards;
    animation-delay: 180ms;
    transform: rotate(90deg);
  }
  &:after {
    content: '';
    box-sizing: border-box;
    animation: go651618207 0.2s ease-out forwards;
    opacity: 0;
    animation-delay: 200ms;
    position: absolute;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: #fff;
    bottom: 6px;
    left: 6px;
    height: 10px;
    width: 6px;
  }
`;

const NotificationContext = React.createContext<any>(null);

export interface NotificationObj {
  success: (message: string, options?: ToastOptions) => void;
  error: (message: string, options?: ToastOptions) => void;
}
interface Props {
  appContext?: SSRContext;
}

export const NotificationProvider: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  const handleClick = () => toast.dismiss();

  const setNotificationParams = () => {
    setHeaderHeight(document.getElementById('main-header')?.clientHeight ?? 0);
  };

  const handleSuccess = React.useCallback((data: any, options?: ToastOptions) => {
    setNotificationParams();
    toast(
      <WrapperStyled onClick={handleClick}>
        <IconWrapper>
          <SuccessIcon />
        </IconWrapper>
        <ContentStyled>{isArray(data) ? <span>{data}</span> : <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />}</ContentStyled>
      </WrapperStyled>,
      {
        ...options,
        duration: 3000,
        style: {
          background: '#363636',
          color: '#fff',
          padding: 0,
        },
      },
    );
  }, []);

  const handleError = React.useCallback((data: any, options?: ToastOptions) => {
    setNotificationParams();
    toast(
      <WrapperStyled onClick={handleClick}>
        <IconWrapper>
          <ErrorIcon />
        </IconWrapper>
        <ContentStyled>
          {!data.message && isArray(data) ? (
            <span>{data}</span>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.message ? data.message : data) }} />
          )}
        </ContentStyled>
      </WrapperStyled>,
      {
        ...options,
        duration: 3000,
        style: {
          background: '#df403f',
          color: '#fff',
          padding: 0,
        },
      },
    );
  }, []);

  return (
    <NotificationContext.Provider value={{ success: handleSuccess, error: handleError }}>
      {children}
      <Toaster
        position="top-right"
        containerStyle={{
          zIndex: 10000,
          top: headerHeight,
        }}
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
        }}
      />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationContext');
  }
  return context;
};
