import { ExclamationCircle } from '@webstore-monorepo/shared/icons';
import { ExclamationTriangle } from '@webstore-monorepo/shared/icons';
import { InfoIcon } from '@webstore-monorepo/shared/icons';
import type { ConcreteSvgIcon } from '@webstore-monorepo/shared/interfaces';
import { Box } from '@webstore-monorepo/ui/box';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import React from 'react';

import { NotificationBannerEnum, notificationBlockVariantStyles } from './notification-banner.enum';
import { useNotificationBannerState } from './NotificationBannerProvider';

export interface NotificationBannerIconProps {
  iconUrl?: string;
}

export const NotificationBannerIcon: React.FC<NotificationBannerIconProps & ConcreteSvgIcon> = ({ iconUrl, ...rest }) => {
  const { variant } = useNotificationBannerState();
  const fill = notificationBlockVariantStyles[variant].fill;

  if (iconUrl)
    return (
      <Box marginRight={3}>
        <LazyImage width={24} src={iconUrl} />
      </Box>
    );

  if (variant === NotificationBannerEnum.error) {
    return (
      <Box marginRight={3}>
        <ExclamationCircle stroke="transparent" fill={fill} {...rest} />
      </Box>
    );
  }
  if (variant === NotificationBannerEnum.warning) {
    return (
      <Box marginRight={3}>
        <ExclamationTriangle stroke="transparent" fill={fill} {...rest} />
      </Box>
    );
  }

  return (
    <Box marginRight={3}>
      <InfoIcon fill={fill} {...rest} />
    </Box>
  );
};
