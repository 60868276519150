import type { ReadTemporaryUserDto, ReadUserDto } from '@goparrot/customer-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useEditUserMutation = (
  userId: string,
  options?: UseMutationOptions<unknown, AxiosError, Partial<ReadUserDto | ReadTemporaryUserDto>>,
): UseMutationResult<unknown, AxiosError, Partial<ReadUserDto | ReadTemporaryUserDto>> => {
  const { analytics } = usePlatformStoreState();
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (data) => {
    analytics.track(AnalyticsActionsEnum.ACCOUNT_SUBMIT_EDIT_DATA, {
      userData: data,
    });
    return axios.patch(`/api/v2/users/${userId}`, data, { params: { merchantId, storeId } });
  }, options);
};
