export enum AnalyticsActionsEnum {
  ACCOUNT_SUBMIT_PHONE = 'account_submit_phone',
  ACCOUNT_SUBMIT_REGISTRATION_DATA = 'account_submit_registration_data',
  GUEST_ACCOUNT_SUBMIT_DATA = 'guest_submit_data',
  GUEST_ACCOUNT_UPDATE_DATA = 'guest_update_data',
  ACCOUNT_SUBMIT_EDIT_DATA = 'account_submit_edit_data',
  ACCOUNT_SUBMIT_EDIT_DATA_RESULT = 'account_submit_edit_data_result',
  ACCOUNT_DELETE_RESULT = 'account_delete_result',
  ACCOUNT_DELETE_CONFIRM = 'account_delete_confirm',
  ACCOUNT_SUBMIT_REGISTRATION_DATA_RESULT = 'account_submit_registration_data_result',
  ACCOUNT_RESEND_VALIDATION_CODE = 'account_resend_validation_code',
  ACCOUNT_RESEND_MERGE_USERS_CONFIRMATION_CODE = 'account_resend_merge_users_confirmation_code',
  ACCOUNT_SUBMIT_VALIDATION_CODE = 'account_submit_validation_code',
  ACCOUNT_SUBMIT_VALIDATION_CODE_INVALID = 'account_submit_validation_code_invalid',
  ACCOUNT_SUBMIT_MERGE_USERS_CONFIRMATION_CODE = 'account_submit_merge_users_confirmation_code',
  ACCOUNT_SUBMIT_MERGE_USERS_CONFIRMATION_CODE_INVALID = 'account_submit_merge_users_confirmation_code_invalid',
  ACCOUNT_SKIP_IMPORT_USERS = 'account_skip_import_user',
  ACCOUNT_IMPORTED_USERS = 'account_imported_users',
  ACCOUNT_IMPORTED_USERS_ERROR = 'account_imported_users_error',
  ACCOUNT_SUCCESS_LOGIN = 'account_success_login',
  ORDER_TO_TABLE_SET_ACTIONS = 'order_to_table_set_actions',
  profile_opened = 'profile_opened',
  account_my_loyalty_click = 'account_my_loyalty_click',
  cart_opened = 'cart_opened',
  account_login_click = 'account_login_click',
  select_dining_option = 'select_dining_option',
  select_delayed_info = 'select_delayed_info',
  modal_back_button_click = 'modal_back_button_click',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  order_to_table_set_actions = 'order_to_table_set_actions',
  order_setup_page_set_actions = 'order_setup_page_set_actions',
  dining_options_page_set_actions = 'dining_options_page_set_actions',
  delivery_address_error = 'delivery_address_error',
}
