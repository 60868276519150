import React, { memo } from 'react';

import type { ImageProps } from './interface';
import { imagePipe } from './utils';

export const LazyImage: React.FC<ImageProps> = memo(
  ({ alt, src, quality, srcSet, dataSrc, dataSrcSet, width, height, loading = 'eager', title, accessibilityLabel, ...props }) => {
    return (
      <img
        alt={accessibilityLabel || alt}
        role="presentation"
        title={title}
        src={imagePipe(src, width, quality, '2', true)}
        srcSet={srcSet}
        data-src={dataSrc}
        data-srcset={`${imagePipe(src, width, quality, '1')} 1x,
      ${imagePipe(src, width, quality, '1.5')} 1.5x,
      ${imagePipe(src, width, quality, '2')} 2x,
      ${imagePipe(src, width, quality, '3')} 3x
      ${dataSrcSet ? dataSrcSet : ''}`}
        className="lazyload"
        onDragStart={(e) => e.preventDefault()}
        loading={loading}
        style={{
          width: src ? width : '',
          height: src ? height : '',
          ...props,
        }}
      />
    );
  },
);
