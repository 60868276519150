import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const PenIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M12.8812 0.0234375C12.2981 0.0234375 11.7512 0.3129 11.2964 0.769421V0.767125L1.79188 10.2514L0.522218 14.0744C0.392354 14.4654 0.764633 14.8369 1.15537 14.7062L4.96157 13.4328L5.05762 13.3364L14.4661 3.95075C14.9209 3.49423 15.207 2.94533 15.207 2.36009C15.207 1.77484 14.9209 1.22594 14.4661 0.769421C14.0112 0.312894 13.4643 0.0234375 12.8812 0.0234375ZM10.5348 3.18411L12.0488 4.70362L4.32809 12.4068L2.05945 13.1665L2.81642 10.8872L10.5348 3.18411Z',
    ],
    viewBox: '0 0 16 16',
  },
  ...BaseConfig,
});
