import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CheckIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M21.5 40C21.5 29.2317 30.2317 20.5 41 20.5C51.7683 20.5 60.5 29.2317 60.5 40C60.5 50.7683 51.7683 59.5 41 59.5C30.2317 59.5 21.5 50.7683 21.5 40ZM25.8333 40C25.8333 48.3633 32.6367 55.1667 41 55.1667C49.3633 55.1667 56.1667 48.3633 56.1667 40C56.1667 31.6367 49.3633 24.8333 41 24.8333C32.6367 24.8333 25.8333 31.6367 25.8333 40ZM33.8718 37.4001L38.8334 42.3618L48.1718 33.0668L51.2268 36.1218L40.3934 46.9551C39.9601 47.3668 39.4184 47.5834 38.8551 47.5834C38.2918 47.5834 37.7501 47.3884 37.3168 46.9551L30.8168 40.4551L33.8718 37.4001Z',
    ],
    viewBox: '0 0 80 80',
  },
  ...BaseConfig,
  width: 80,
  height: 80,
});
