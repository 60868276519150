import { SocialMediaTypeEnum } from '@goparrot/webstore-sdk';
import type { ConcreteSvgIcon } from '@webstore-monorepo/shared/interfaces';
import type { FC } from 'react';
import React from 'react';

import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

const FacebookIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M13.9674 23.7372H17.2038V15.7362H19.363L19.6488 12.9792H17.2038L17.2073 11.5987C17.2073 10.8802 17.2743 10.4942 18.2841 10.4942H19.6337V7.7372H17.474C14.8803 7.7372 13.9674 9.0747 13.9674 11.3242V12.9797H12.3506V15.7372H13.9674V23.7372Z',
    ],
    viewBox: '0 0 32 32',
  },
  ...BaseConfig,
});

const InstagramIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M12.7094 8.14035C11.572 8.14035 10.4812 8.59217 9.67697 9.39642C8.87272 10.2007 8.4209 11.2915 8.4209 12.4288V19.2904C8.4209 20.4278 8.87272 21.5186 9.67697 22.3229C10.4812 23.1271 11.572 23.5789 12.7094 23.5789H19.571C20.7084 23.5789 21.7992 23.1271 22.6034 22.3229C23.4077 21.5186 23.8595 20.4278 23.8595 19.2904V12.4288C23.8595 11.2915 23.4077 10.2007 22.6034 9.39642C21.7992 8.59217 20.7084 8.14035 19.571 8.14035H12.7094ZM21.2864 9.85575C21.7581 9.85575 22.1441 10.2417 22.1441 10.7134C22.1441 11.1852 21.7581 11.5711 21.2864 11.5711C20.8147 11.5711 20.4287 11.1852 20.4287 10.7134C20.4287 10.2417 20.8147 9.85575 21.2864 9.85575ZM16.1402 11.5711C17.2776 11.5711 18.3684 12.023 19.1726 12.8272C19.9769 13.6315 20.4287 14.7223 20.4287 15.8596C20.4287 16.997 19.9769 18.0878 19.1726 18.8921C18.3684 19.6963 17.2776 20.1481 16.1402 20.1481C15.0028 20.1481 13.912 19.6963 13.1078 18.8921C12.3035 18.0878 11.8517 16.997 11.8517 15.8596C11.8517 14.7223 12.3035 13.6315 13.1078 12.8272C13.912 12.023 15.0028 11.5711 16.1402 11.5711ZM16.1402 13.2865C15.8022 13.2867 15.4675 13.3534 15.1553 13.4828C14.843 13.6123 14.5593 13.802 14.3204 14.0411C14.0815 14.2801 13.8919 14.564 13.7627 14.8763C13.6335 15.1886 13.567 15.5233 13.5671 15.8614C13.5672 16.1994 13.6339 16.5341 13.7634 16.8463C13.8928 17.1586 14.0825 17.4422 14.3216 17.6812C14.5607 17.9201 14.8445 18.1096 15.1568 18.2389C15.4692 18.3681 15.8039 18.4346 16.1419 18.4345C16.8246 18.4342 17.4792 18.1628 17.9617 17.68C18.4443 17.1971 18.7152 16.5423 18.715 15.8596C18.7148 15.177 18.4434 14.5224 17.9605 14.0398C17.4776 13.5573 16.8229 13.2863 16.1402 13.2865Z',
    ],
    viewBox: '0 0 32 32',
  },
  ...BaseConfig,
});

const TwitterIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M24.4207 10.8591C23.7872 11.1312 23.1171 11.312 22.4312 11.396C23.1541 10.9737 23.6945 10.3051 23.9501 9.51673C23.2735 9.91184 22.5332 10.1901 21.7612 10.3395C21.2878 9.84152 20.6732 9.49527 19.9972 9.34579C19.3212 9.19631 18.6152 9.25051 17.971 9.50135C17.3267 9.75219 16.774 10.1881 16.3847 10.7523C15.9954 11.3165 15.7875 11.983 15.7881 12.6651C15.7855 12.9254 15.8123 13.1852 15.8682 13.4396C14.4946 13.3733 13.1506 13.0223 11.9242 12.4096C10.6978 11.7969 9.61661 10.9362 8.75121 9.88393C8.3069 10.6317 8.16922 11.5183 8.36625 12.3626C8.56329 13.207 9.08019 13.9455 9.81145 14.4274C9.26513 14.4129 8.73036 14.2692 8.25221 14.0087V14.0457C8.25311 14.8307 8.52868 15.5915 9.03253 16.1999C9.53638 16.8083 10.2378 17.2273 11.0186 17.3862C10.7232 17.4629 10.4188 17.5005 10.1133 17.4982C9.89392 17.502 9.6748 17.4825 9.45969 17.4401C9.68284 18.1148 10.1129 18.7047 10.6907 19.1288C11.2685 19.5529 11.9656 19.7902 12.6861 19.8081C11.4639 20.7491 9.95661 21.2596 8.40509 21.2581C8.12886 21.2599 7.85281 21.2442 7.57861 21.2113C9.15742 22.2129 10.9972 22.7428 12.8754 22.7368C19.229 22.7368 22.7028 17.5551 22.7028 13.0635C22.7028 12.9131 22.6975 12.7682 22.6901 12.624C23.3712 12.1442 23.9576 11.5461 24.4207 10.8591Z',
    ],
    viewBox: '0 0 32 32',
  },
  ...BaseConfig,
});

const YoutubeIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M25.0162 12.2422C25.0162 10.7517 23.8143 9.54386 22.3314 9.54386H9.73603C8.25312 9.54386 7.05127 10.7517 7.05127 12.2422V19.4771C7.05127 20.9674 8.25312 22.1754 9.73603 22.1754H22.3314C23.8143 22.1754 25.0162 20.9674 25.0162 19.4771V12.2422ZM14.2372 18.9123V12.1163L19.3676 15.5142L14.2372 18.9123Z',
    ],
    viewBox: '0 0 32 32',
  },
  ...BaseConfig,
});

type Props = {
  socialType: SocialMediaTypeEnum;
  width?: number;
  height?: number;
} & ConcreteSvgIcon;
export const SocialMediaIcon: FC<Props> = ({ width = 32, height = 32, socialType, ...rest }) => {
  const icons = {
    [SocialMediaTypeEnum.FACEBOOK]: <FacebookIcon width={width} height={height} {...rest} />,
    [SocialMediaTypeEnum.INSTAGRAM]: <InstagramIcon width={width} height={height} {...rest} />,
    [SocialMediaTypeEnum.TWITTER]: <TwitterIcon width={width} height={height} {...rest} />,
    [SocialMediaTypeEnum.YOUTUBE]: <YoutubeIcon width={width} height={height} {...rest} />,
  };
  return icons[socialType] ?? null;
};
