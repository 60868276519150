import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CreditCardIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M19 5H5C3.34 5 2 6.34 2 8V9V11V16C2 17.66 3.34 19 5 19H19C20.66 19 22 17.66 22 16V11V9V8C22 6.34 20.66 5 19 5ZM20 16C20 16.55 19.55 17 19 17H5C4.45 17 4 16.55 4 16V11H20V16ZM4 9V8C4 7.45 4.45 7 5 7H19C19.55 7 20 7.45 20 8V9H4Z',
    ],
    viewBox: '0 0 24 24',
    fillOpacity: 0.9,
  },
  ...BaseConfig,
});
