export const imagePipe = (src?: string, size: number | string | 'auto' = 'auto', quality = 'eco', dpr?: string, blur = false): string => {
  if (!src) return '';
  if (src.includes('via.placeholder.com')) {
    return src;
  }

  if (!src.includes('cloudinary')) {
    console.warn(`"${src}" should come from cloudinary`);

    return src;
  }

  let criteria;
  if (blur) {
    criteria = `upload/w_${size},e_blur:1000,q_1,f_auto,${dpr ? `dpr_${dpr}` : ''}/`;
  } else {
    criteria = `upload/w_${size},q_auto:${quality},f_auto,e_sharpen:120,${dpr ? `dpr_${dpr}` : ''}/`;
  }

  return src ? src.replace('http://', 'https://').replace('upload/', criteria) : src;
};
