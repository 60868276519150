/**
 * Converts hex color to rgba
 * @param color
 * @param opacity
 */
export const hexToRgb = (color: string, opacity?: number): string | null => {
  const getRgbStr = (values: number[]): string => {
    const joined = values.join(', ');
    return values.length === 4 ? `rgba(${joined})` : `rgb(${joined})`;
  };
  if (!color.includes('#') && color.includes('rgb')) {
    const result = color.split(', ').map((str) => Number(str.replace(/[^\d.]/g, '')));
    return opacity || opacity === 0 ? getRgbStr([...result.splice(0, 3), opacity]) : result[3] ? getRgbStr(result) : getRgbStr([...result.splice(0, 3)]);
  }
  if (color.length === 4) {
    color = color
      .replace('#', '')
      .trim()
      .split('')
      .map((item) => item + item)
      .join('');
    color = `#${color}`;
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

  return result
    ? opacity || opacity === 0
      ? getRgbStr([...result.splice(1, 3).map((item) => parseInt(item, 16)), opacity])
      : getRgbStr([...result.splice(1, 3).map((item) => parseInt(item, 16))])
    : null;
};
