import { StoreItemPromotionRelationType } from '@goparrot/common';
import type { IOrder, IOrderElement } from '@goparrot/order-sdk';
import { getItemsQuantity, OrderElementTypeEnum } from '@goparrot/order-sdk';
import {
  INestedStoreItemGroup,
  IStoreItem,
  IStoreItemBundle,
  IStoreItemCombo,
  IStoreItemWithOptions,
  StoreItemTypeEnum,
  TimedMenuWebstoreItemType,
  WebstoreItemType,
} from '@goparrot/storeitems-sdk';
import type { ReadTopItemDto } from '@goparrot/top-item-client-sdk';
import type { MenuCategories } from '@webstore-monorepo/shared/interfaces';
import cloneDeep from 'lodash/cloneDeep';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import toArray from 'lodash/toArray';

export const isItemDiscounted = (item: IOrderElement | IStoreItemWithOptions): boolean => {
  return (
    // @ts-ignore
    OrderElementTypeEnum.ELEMENT === item.type && [StoreItemPromotionRelationType.DISCOUNTED, StoreItemPromotionRelationType.ADDED].includes(item.tag?.type)
  );
};

export const addSoldOutWatermark = (imageUrl: string, waterMark: string): string => {
  const splitted = imageUrl.split('/');
  const injectIndex = splitted.indexOf('upload') + 1;
  if (injectIndex > 0) {
    splitted.splice(injectIndex, 0, waterMark);
    return splitted.join('/');
  }

  return imageUrl;
};

export const markSoldOutItemImage = (item: any, soldOutWatermark?: string) => {
  if (!soldOutWatermark || item.isAvailable || item.availabilitySchedule) return item;
  const itemImageProp = ['image_url_square', 'image_url', 'hover_image_url'];
  const clonedItem = cloneDeep(item);

  itemImageProp.forEach((prop) => {
    if (clonedItem[prop]) {
      clonedItem[prop] = addSoldOutWatermark(item[prop], soldOutWatermark);
    }
  });
  return clonedItem;
};

export const getDiscountedElementsQuantityByItem = (item: IOrderElement, cart: IOrder): number =>
  cart.elements.reduce((acc, el) => {
    if (el.uniqueNameOriginal === item.uniqueNameOriginal && isItemDiscounted(el)) {
      if (isItemDiscounted(el)) {
        return acc + el.quantity;
      }
    }
    return acc;
  }, 0);

export const getBaseItemAllowedQuantity = (extremum: 'min' | 'max') => {
  return (cart: IOrder, selectedItem: IOrderElement) => {
    const configMinQuantity = Math.max(selectedItem.metadata?.minQuantity || 0, 1);
    const configMaxQuantity = selectedItem.metadata?.maxQuantity ?? 99;
    const configExtremum = extremum === 'min' ? configMinQuantity : configMaxQuantity;
    const discountedElementsQuantity = getDiscountedElementsQuantityByItem(selectedItem, cart);
    const allowedQuantity = configExtremum - discountedElementsQuantity;

    return Math.max(allowedQuantity ?? 0, 1);
  };
};

export function getAllowedQuantity(extremum: 'min' | 'max', cart: IOrder, selectedItem: IOrderElement, editable?: boolean): number {
  const configExtremum = extremum === 'min' ? selectedItem?.metadata?.minQuantity ?? 1 : selectedItem?.metadata?.maxQuantity ?? 99;
  const itemCartQuantity = getItemsQuantity(cart)[selectedItem?.uniqueNameOriginal || selectedItem?.uniqueName]?.quantity ?? 0;
  const selectedItemQuantity = selectedItem?.quantity ?? 0;
  const allowedQuantity = !editable ? configExtremum - (itemCartQuantity - selectedItemQuantity) : configExtremum;

  return extremum === 'min' ? Math.max(itemCartQuantity >= configExtremum ? allowedQuantity : configExtremum, 1) : allowedQuantity;
}

export const sortStoreItemsByShowOrder = (arr?: IStoreItem[], sortProperties?: INestedStoreItemGroup['properties']) => {
  const getShowOrder = (item?: IStoreItem | INestedStoreItemGroup) => sortProperties?.find((propery) => propery.uid === item?.uniqueName)?.showOrder;
  return orderBy<IStoreItem | INestedStoreItemGroup>(arr, getShowOrder);
};

export const filterItemsByCategoryAndSort = (
  storeItems: WebstoreItemType[] | TimedMenuWebstoreItemType[],
  categories: any[],
  soldOutWatermark?: string,
): MenuCategories[] => {
  const categoriesMap = keyBy(categories, 'uniqueName');
  for (const category of categories) {
    delete category.items;
  }

  for (const item of storeItems) {
    if (item.metadata.categories && item.metadata.categories.length) {
      for (const categoryUniqueId of item.metadata.categories) {
        if (categoriesMap[categoryUniqueId]) {
          let updatedItem;

          categoriesMap[categoryUniqueId].items = categoriesMap[categoryUniqueId].items || [];

          const markSoldOutItemImageToItemGroup = (items: any[]): any[] =>
            items.map((item) => {
              if (item.items?.length) {
                return {
                  ...item,
                  items: markSoldOutItemImageToItemGroup(item.items),
                };
              } else {
                return markSoldOutItemImage(item, soldOutWatermark);
              }
            });

          if (item.type === 'storeItemGroup') {
            updatedItem = { ...item, items: markSoldOutItemImageToItemGroup(item.items) };
          } else {
            updatedItem = markSoldOutItemImage(item, soldOutWatermark);
          }

          categoriesMap[categoryUniqueId].items.push(updatedItem);
        }
      }
    } else {
      const categoryMapIndex = StoreItemTypeEnum.COMBO === item.type || !item.metadata.category ? 0 : item.metadata.category;
      // TODO: check if we could remove storeItem.metadata.category support here
      if (categoriesMap[categoryMapIndex]) {
        categoriesMap[categoryMapIndex].items = categoriesMap[categoryMapIndex].items || [];

        const updatedItem = markSoldOutItemImage(item, soldOutWatermark);

        categoriesMap[categoryMapIndex].items.push(updatedItem);
      }
    }
  }

  let newCategories = toArray(categoriesMap);

  // Sort categories by show_order if it is;
  if (!isUndefined(newCategories) && newCategories.length) {
    newCategories = sortBy(newCategories, (el: any) => Number.parseInt(el.metadata.show_order, 10));
    for (const category of newCategories) {
      category.items = sortBy(category.items, (el: any) => (el.metadata && el.metadata.show_order ? Number.parseInt(el.metadata.show_order, 10) : 0));
    }
  }

  for (const category of newCategories) {
    if (!category.items || !category.items.length) {
      newCategories = newCategories.filter((el: any) => el.uniqueName !== category.uniqueName);
    }
  }

  return newCategories;
};

export const filterTopSellingItems = (
  topSellingItems: ReadTopItemDto[],
  categories?: MenuCategories[],
): (IStoreItem | IStoreItemBundle | INestedStoreItemGroup | IStoreItemWithOptions | IStoreItemCombo)[] => {
  const categoryItems = categories?.flatMap((category: MenuCategories) => category.items) ?? [];

  const isInTopSellingItem = (storeItem: IStoreItem | IStoreItemWithOptions | INestedStoreItemGroup) =>
    topSellingItems.some((topSellingItem) => storeItem.isAvailable && storeItem.uniqueName === topSellingItem.uniqueNameOriginal);

  const storeItemsTopSelling = categoryItems.filter((storeItem) => storeItem.type === StoreItemTypeEnum.STORE_ITEM && isInTopSellingItem(storeItem));

  const storeItemsTopSellingFromStoreItemGroup: (IStoreItem | IStoreItemBundle | INestedStoreItemGroup | IStoreItemCombo)[] = categoryItems
    .filter((storeItem) => storeItem.type === StoreItemTypeEnum.STORE_ITEM_GROUP)
    .flatMap((itemGroup) => ('items' in itemGroup ? itemGroup.items : []))
    .filter((storeItem) => storeItem.type === StoreItemTypeEnum.STORE_ITEM && isInTopSellingItem(storeItem));

  const fullTopSellingItems = [...storeItemsTopSellingFromStoreItemGroup, ...storeItemsTopSelling];
  const topSellingItemsLimited = 8;
  return fullTopSellingItems.slice(0, topSellingItemsLimited);
};
