export const WATERMARK = {
  DEFAULT_WATERMARK: 'default_watermark.png',
  PARAMS: {
    width: 'w_',
    height: 'h_',
    x: 'x_',
    y: 'y_',
    gravity: 'g_',
    color: 'co_rgb:',
    flag: 'fl_',
    rotate: 'a_', // angle
    colorize: 'e_colorize:',
  },
  DEFAULT_TEXT_PARAMS: {
    font: 'Arial',
    size: 16,
  },
  DEFAULT_PARAMS: {
    width: '1.0',
    flag: 'relative',
  },
  TEXT_PARAMS: ['font', 'size', 'weight'],
};
