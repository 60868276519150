import type { ReadWebStoreDto } from '@goparrot/webstore-sdk';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const WebStoreStateContext = createContext<ReadWebStoreDto>({} as ReadWebStoreDto);

interface Props {
  initial: ReadWebStoreDto;
}

export const WebStoreProvider: React.FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const [state, setState] = useState<ReadWebStoreDto>(initial);

  useEffect(() => {
    if (initial) {
      setState({ ...initial });
    }
  }, [initial]);

  return <WebStoreStateContext.Provider value={state}>{state && children}</WebStoreStateContext.Provider>;
};

export const useWebStore = () => {
  const context = useContext(WebStoreStateContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a WebStoreProvider');
  }
  return context;
};
