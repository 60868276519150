import type { IReadMerchantDto } from '@goparrot/store-v2-sdk';
import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useContext, useMemo } from 'react';

export const MerchantStateContext = createContext<IReadMerchantDto>({} as IReadMerchantDto);

interface Props {
  initial: IReadMerchantDto;
}

export const MerchantProvider: FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const state = useMemo(
    () => ({
      ...initial,
    }),
    [],
  );

  return <MerchantStateContext.Provider value={state}>{children}</MerchantStateContext.Provider>;
};

export const useMerchantState = () => {
  const context = useContext(MerchantStateContext);
  if (context === undefined) {
    throw new Error('useMerchantState must be used within a MerchantProvider');
  }
  return context;
};
