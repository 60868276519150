export const checkIfItemInParentView = (el: HTMLElement | null, parent: HTMLElement | null) => {
  if (el && parent) {
    const { left, right } = el.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    return left <= parentRect.left ? parentRect.left - left <= 0 : right - parentRect.right <= 0;
  }
  return false;
};

export const checkIfItemsInView = (elements: (HTMLElement | null)[], parent: HTMLElement | null) => {
  return elements.filter((item) => checkIfItemInParentView(item, parent));
};
