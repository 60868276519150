import { WATERMARK } from './constants';

const getWaterMarkProps = (params: Record<string, any>): string => {
  const { position, dimension, text = {}, imageUrl } = params;
  const { colorize = '100', color } = text;
  const needToapplyColorStyles = !imageUrl;

  const config: Record<string, string> = { ...WATERMARK.DEFAULT_PARAMS, ...dimension, ...position, ...(needToapplyColorStyles ? { color, colorize } : {}) };
  const configToString = Object.keys(config)
    .reduce((result: string[], key: string) => {
      // @ts-ignore
      result.push(`${WATERMARK.PARAMS[key]}${config[key]}`);

      return result;
    }, [])
    .join(',');

  return configToString ? `${configToString},` : '';
};

/**
 *
 * @param {Object} params - text watermark params: title, font, size, weight
 * @returns {string}
 */
const getTextWaterMark = (params: Record<string, any>): string => {
  const paramsWithDefaults = { ...WATERMARK.DEFAULT_TEXT_PARAMS, ...params };
  const stringWithStyles: string = WATERMARK.TEXT_PARAMS.reduce((result: any, prop: any) => {
    // @ts-ignore
    if (paramsWithDefaults[prop]) {
      // @ts-ignore
      result.push(paramsWithDefaults[prop]);
    }

    return result;
  }, []).join('_');

  // @ts-ignore
  return `l_text:${stringWithStyles}:${encodeURI(paramsWithDefaults.title)}`;
};

/**
 * If watermark url defined, will convert it to base64, otherwise take default watermark
 * Default watermark should exist on root level at cloudinary
 *
 * @param {Object} params - watermark config
 * @returns {string}
 */
const getImageWaterMark = (params: { imageUrl?: string } = {}) => {
  const { imageUrl } = params;
  const watermarkBase64 = imageUrl ? `fetch:${window.btoa(imageUrl)}` : '';
  return `l_${watermarkBase64 || WATERMARK.DEFAULT_WATERMARK}`;
};

export const getWaterMark = (waterMarkConfig?: Record<string, any>): string => {
  let waterMarkProps = '';

  if (waterMarkConfig) {
    waterMarkProps = getWaterMarkProps(waterMarkConfig);
    if (waterMarkConfig.imageUrl) {
      return `${waterMarkProps}${getImageWaterMark(waterMarkConfig)}`;
    }

    if (waterMarkConfig.text && waterMarkConfig.text.title) {
      return `${waterMarkProps}${getTextWaterMark(waterMarkConfig.text)}`;
    }
  }
  return waterMarkProps;
};
