import type { AuthResponseDto } from '@goparrot/webstore-gateway-public-sdk';
import { LocalStorage } from '@webstore-monorepo/shared/utils/local-storage';

import type { UserSessionsByMerchant } from './type';

export class UserAuthStorageService {
  private key: string = 'sessions';
  private merchantId = '';
  private localStorage = new LocalStorage();

  constructor(merchantId: string) {
    this.merchantId = merchantId;
  }

  getAccessToken(): string | null | undefined {
    return this.get()?.accessToken;
  }

  isAuthenticated(): boolean | null | undefined {
    return this.get()?.isAuthenticated;
  }

  has(): boolean {
    const sessions: UserSessionsByMerchant = this.getAll();

    return this.merchantId in sessions;
  }

  get(): AuthResponseDto | null {
    const sessions: UserSessionsByMerchant = this.getAll();

    return this.merchantId in sessions ? sessions[this.merchantId] : null;
  }

  set(data: AuthResponseDto): void {
    const sessions: UserSessionsByMerchant = this.getAll();
    sessions[this.merchantId] = data;
    this.localStorage.set(this.key, sessions);
  }

  remove(): void {
    const sessions: UserSessionsByMerchant = this.getAll();
    if (this.merchantId in sessions) {
      delete sessions[this.merchantId];
      this.localStorage.set(this.key, sessions);
    }
  }

  private getAll(): UserSessionsByMerchant {
    return (this.localStorage.get<UserSessionsByMerchant>(this.key) as UserSessionsByMerchant) || {};
  }
}
