import { useCallback } from 'react';

export const useLocalStorage = () => {
  const storageId: string = 'LS_';

  //esbuild-loader is failing because <T> is being considered as JSX
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  const get = useCallback(<T extends unknown>(key: string): T | null => {
    let data: string | null;

    try {
      data = localStorage.getItem(`${storageId}${key}`);
    } catch (e) {
      return null;
    }

    if (data === null) {
      return null;
    }

    return JSON.parse(data) as T;
  }, []);

  const has = useCallback((key: string): boolean => {
    return get(key) !== null;
  }, []);

  const set = useCallback((key: string, data: any): void => {
    if (typeof data == 'object') {
      data = JSON.stringify(data);
    }

    try {
      localStorage.setItem(`${storageId}${key}`, data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const remove = useCallback((key: string): void => {
    try {
      localStorage.removeItem(`${storageId}${key}`);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return { set, has, get, remove };
};
