import type { DiningOptionEnum } from '@goparrot/common';
import type { ISlotDetails } from '@goparrot/order-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { MAX_ALLOCATION_PER_SLOT } from '@webstore-monorepo/shared/utils/dining-options';
import { getOrderAheadAvailable } from '@webstore-monorepo/shared/utils/store';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

const getSlots = async (storeId: string, date: string, diningOptionType?: DiningOptionEnum): Promise<ISlotDetails[]> => {
  return axios.get(`/api/v2/stores/${storeId}/throttling-slots/${date.split('T')[0]}`, {
    params: diningOptionType ? { diningOptionType } : undefined,
  });
};

export const useGetRelevantDaySlots = (
  date: string,
  selectedDiningOption?: DiningOptionEnum,
  options?: UseQueryOptions<ISlotDetails[], AxiosError, ISlotDetails[], (QueryKeysEnum | string)[]>,
) => {
  const { storeId } = useWebStore();

  return useQuery([QueryKeysEnum.getSlots, date], () => getSlots(storeId, date, selectedDiningOption), options);
};

export const useGetFirstSlotForTheDay = () => {
  const cart = useCartState();
  const store = useStoreState();
  const isDeliveryRequired = DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo.type);
  const receiveMethod = isDeliveryRequired ? ReceiveMethodEnum.DELIVERY : ReceiveMethodEnum.PICKUP;
  const availableDays = DelayedOrdersUtils.getAllowedDays(store, receiveMethod);
  const isOrderAheadAvailable = getOrderAheadAvailable(store, cart.diningOptionInfo.type);

  const getFirstSlot = async (): Promise<ISlotDetails> => {
    const slots: ISlotDetails[] = await getSlots(store.storeId, availableDays.days[0]?.date, cart.diningOptionInfo.type);
    const firstSlot = slots.find((slot) => slot?.allocation < MAX_ALLOCATION_PER_SLOT);
    return firstSlot || slots[0];
  };

  return useQuery(QueryKeysEnum.getFirstSlot, getFirstSlot, { enabled: isOrderAheadAvailable });
};
