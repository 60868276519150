import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ChevronUp = createSvgIcon({
  iconConfig: {
    paths: [
      'M6.29297 0.292893C6.68349 -0.0976318 7.31666 -0.0976319 7.70718 0.292892L13.7072 6.29289L12.293 7.70711L7.00008 2.41421L1.70718 7.70711L0.29297 6.29289L6.29297 0.292893Z',
    ],
    viewBox: '0 0 14 8',
  },
  ...BaseConfig,
  fill: 'gray900',
  stroke: 'transparent',
});
