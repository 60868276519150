export const text = {
  textVariants: {
    base: {
      fontFamily: 'Montserrat',
      color: 'danger',
    },
    button: {
      color: 'black',
      fontSize: 14,
    },
  },
};
