import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ArrowLeft = createSvgIcon({
  iconConfig: {
    paths: ['M10.9077 20.6004L1.30772 11.0004M1.30772 11.0004L10.9077 1.40039M1.30772 11.0004H21.9077'],
    viewBox: '0 0 23 22',
  },
  ...BaseConfig,
});
