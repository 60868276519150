import { DiningOptionEnum, ReceiveMethodEnum } from '@goparrot/common';

export const diningOptionRelevantReceiveMethods: Record<DiningOptionEnum, ReceiveMethodEnum> = {
  [DiningOptionEnum.TAKE_OUT]: ReceiveMethodEnum.PICKUP,
  [DiningOptionEnum.CURBSIDE]: ReceiveMethodEnum.PICKUP,
  [DiningOptionEnum.DELIVERY]: ReceiveMethodEnum.DELIVERY,
  [DiningOptionEnum.DINE_IN]: ReceiveMethodEnum.PICKUP,
  [DiningOptionEnum.TO_COUNTER]: ReceiveMethodEnum.DELIVERY,
  [DiningOptionEnum.MULTI_LOCATION]: ReceiveMethodEnum.PICKUP,
};

export const diningOptionsTestIds: Record<DiningOptionEnum, string> = {
  [DiningOptionEnum.TAKE_OUT]: 'diningOptionTakeOut',
  [DiningOptionEnum.CURBSIDE]: 'diningOptionCurbside',
  [DiningOptionEnum.DELIVERY]: 'diningOptionDelivery',
  [DiningOptionEnum.DINE_IN]: 'diningOptionTakeOutDineIn',
  [DiningOptionEnum.TO_COUNTER]: 'diningOptionToCounter',
  [DiningOptionEnum.MULTI_LOCATION]: 'diningOptionMultiLocation',
};
