import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const ClockIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M7.9987 14.6666C11.6806 14.6666 14.6654 11.6818 14.6654 7.99992C14.6654 4.31802 11.6806 1.33325 7.9987 1.33325C4.3168 1.33325 1.33203 4.31802 1.33203 7.99992C1.33203 11.6818 4.3168 14.6666 7.9987 14.6666Z',
      'M8 4V8L10.6667 9.33333',
    ],
    viewBox: '0 0 16 16',
  },
  ...BaseConfig,
  width: 16,
  height: 16,
});
