import type { ISynchronizeTotalResponse } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { formatSynchronizeWithPosError } from '@webstore-monorepo/shared/utils/pos';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useSynchronizeWithWithPOSMutation = (
  options?: UseMutationOptions<ISynchronizeTotalResponse, Error, string>,
): UseMutationResult<ISynchronizeTotalResponse, Error, string> => {
  const { merchantId, storeId } = useWebStore();
  const { notification, analytics } = usePlatformStoreState();

  return useMutation(
    async (cartId) => {
      return axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/${cartId}/synchronize-with-pos`);
    },
    {
      ...options,
      onSuccess: (data) => {
        const {
          validationResult: { isError, message, items, errorCode },
        } = data;

        if (isError) {
          const errorMessage = formatSynchronizeWithPosError(message ?? '', items);
          notification.error(errorMessage);
          analytics.track('pay_button_clicked_cart_synchronize_with_pos_error', { error: errorMessage, items, message });
          throw new Error('sync-with-pos-error. Error code: ' + errorCode);
        }
      },
      onError: (error) => {
        if (!error.message.includes('sync-with-pos-error')) {
          notification.error(error?.message ?? 'Something went wrong');
          analytics.track('pay_button_clicked_cart_synchronize_with_pos_error', { error });
        }
      },
    },
  );
};
