import React from 'react';

import { BaseConfig } from '../interfaces/icon-config';
import type { IconProps } from '../interfaces/icons.interface';
import { createSvgIcon } from './SVGIcon';

export const SidebarCategoriesMenuIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M15 17H9V15H15V17Z',
      'M9 13H15V11H9V13Z',
      'M16.9999 4.6293C16.9999 2.67792 14.9464 1.40875 13.201 2.28143L5.55273 6.10557L5.5535 6.10881C5.22506 6.27551 5 6.61647 5 7.01V19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19V9C19 7.69376 18.1652 6.58251 16.9999 6.17069V4.6293ZM14.0954 4.07029C14.511 3.8625 14.9999 4.16469 14.9999 4.6293V6H10.236L14.0954 4.07029ZM7 19V8H16C16.5523 8 17 8.44772 17 9V19C17 19.5523 16.5523 20 16 20H8C7.44772 20 7 19.5523 7 19Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});

export const SidebarCategoriesMenuIcon2: React.FC<IconProps> = ({ fill = '#000000' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 17H9V15H15V17Z" fill={fill} fillOpacity="0.9" />
    <path d="M9 13H15V11H9V13Z" fill={fill} fillOpacity="0.9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9999 4.6293C16.9999 2.67792 14.9464 1.40875 13.201 2.28143L5.55273 6.10557L5.5535 6.10881C5.22506 6.27551 5 6.61647 5 7.01V19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19V9C19 7.69376 18.1652 6.58251 16.9999 6.17069V4.6293ZM14.0954 4.07029C14.511 3.8625 14.9999 4.16469 14.9999 4.6293V6H10.236L14.0954 4.07029ZM7 19V8H16C16.5523 8 17 8.44772 17 9V19C17 19.5523 16.5523 20 16 20H8C7.44772 20 7 19.5523 7 19Z"
      fill={fill}
      fillOpacity="0.9"
    />
  </svg>
);
