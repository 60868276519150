import type { Theme } from '@webstore-monorepo/shared/theme';

export const enum NotificationBannerEnum {
  warning = 'warning',
  info = 'info',
  error = 'error',
}
type Colors = {
  borderColor: keyof Theme['colors'];
  backgroundColor: keyof Theme['colors'];
  fill: keyof Theme['colors'];
  buttonTextColor: keyof Theme['colors'];
};
export const notificationBlockVariantStyles: Record<NotificationBannerEnum, Colors> = {
  [NotificationBannerEnum.warning]: {
    borderColor: 'warning30',
    backgroundColor: 'warning40',
    fill: 'warningFill',
    buttonTextColor: 'warningText',
  },
  [NotificationBannerEnum.error]: {
    borderColor: 'critical30',
    backgroundColor: 'critical40',
    fill: 'criticalFill',
    buttonTextColor: 'criticalText',
  },
  [NotificationBannerEnum.info]: {
    borderColor: 'emphasis30',
    backgroundColor: 'emphasis40',
    fill: 'emphasisFill',
    buttonTextColor: 'emphasisText',
  },
};
