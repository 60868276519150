import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const Bell = createSvgIcon({
  iconConfig: {
    paths: [
      'M18 17V11C18 8.39 16.33 6.18 14 5.35V5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5V5.35C7.67 6.18 6 8.39 6 11V17H4V19H10C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19H20V17H18ZM8 17V11C8 8.79 9.79 7 12 7C14.21 7 16 8.79 16 11V17H8Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
