import type { OrderGratuityTypeEnum } from '@goparrot/common';
import type { ICartAction, IOrder, IOrderElement, IUpdateItemBundleAction, IUpdateItemComboAction } from '@goparrot/order-sdk';
import { CartActionTypeEnum, OrderElementTypeEnum } from '@goparrot/order-sdk';
import type { IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useClearCartMutation = (options?: UseMutationOptions<IOrder, AxiosError>): UseMutationResult<IOrder, AxiosError, void> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async () => {
    return axios.delete(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts`);
  }, options);
};

export const useGetFullCartMutation = (options?: UseMutationOptions<IOrder, AxiosError>): UseMutationResult<IOrder, AxiosError, void> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async () => {
    return axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts`);
  }, options);
};

export const useRemoveRewardMutation = (options?: UseMutationOptions<IOrder, AxiosError, string>): UseMutationResult<IOrder, AxiosError, string> => {
  const { storeId } = useWebStore();

  return useMutation(async (ruleUuid: string) => {
    return axios.delete(`/api/v2/stores/${storeId}/loyalty-rewards/${ruleUuid}`);
  }, options);
};

export const useAddGratuity = (
  options?: UseMutationOptions<IOrder, AxiosError, { gratuityType: OrderGratuityTypeEnum; gratuityAmount: number }>,
): UseMutationResult<IOrder, AxiosError, { gratuityType: OrderGratuityTypeEnum; gratuityAmount: number }> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ gratuityType, gratuityAmount }) => {
    return axios.patch(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/gratuities/${gratuityType}/amounts/${gratuityAmount}`, undefined);
  }, options);
};

interface AddItemToCartDto {
  item: IStoreItemWithOptions;
  quantity: number;
  menuConceptUuid?: string;
  index: number;
}
export const useAddItemToCartMutations = (
  options?: UseMutationOptions<IOrder, AxiosError, AddItemToCartDto>,
): UseMutationResult<IOrder, AxiosError, AddItemToCartDto> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ item, quantity, menuConceptUuid }) => {
    return axios.post(`/api/v2/merchants/${merchantId}/stores/${storeId}/cart-items`, { item, quantity, menuConceptUuid });
  }, options);
};

interface UpdateQuantityProps {
  uniqueName: string;
  quantity: number;
  item: IOrderElement;
}
export const useUpdateQuantity = (
  options?: UseMutationOptions<IOrder, AxiosError, UpdateQuantityProps>,
): UseMutationResult<IOrder, AxiosError, UpdateQuantityProps> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ uniqueName, quantity }: UpdateQuantityProps) => {
    return axios.patch(`/api/v2/merchants/${merchantId}/stores/${storeId}/cart-items/${uniqueName}/quantities/${quantity}`);
  }, options);
};

export const useRemoveItemFromCart = (
  options?: UseMutationOptions<IOrder, AxiosError, IOrderElement | IStoreItemWithOptions>,
): UseMutationResult<IOrder, AxiosError, IOrderElement | IStoreItemWithOptions> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (item) => {
    return axios.delete(`/api/v2/merchants/${merchantId}/stores/${storeId}/cart-items/${item.uniqueName}`);
  }, options);
};

export type RemoveComboParams = {
  uuid: string;
  type: Extract<OrderElementTypeEnum, OrderElementTypeEnum.COMBO | OrderElementTypeEnum.BUNDLE_ITEM>;
};

export const useRemoveComboMutation = (options?: UseMutationOptions<IOrder, Error, RemoveComboParams>): UseMutationResult<IOrder, Error, RemoveComboParams> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ uuid, type }) => {
    const action: ICartAction = {
      type: OrderElementTypeEnum.BUNDLE_ITEM === type ? CartActionTypeEnum.REMOVE_ITEM_BUNDLE : CartActionTypeEnum.REMOVE_ITEM_COMBO,
      payload: {
        uuid,
      },
    };

    return axios.patch(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/actions`, [action]);
  }, options);
};

export interface ComboUpdateMutationParams {
  action: IUpdateItemBundleAction | IUpdateItemComboAction;
  comboTitle: string;
}

export const useUpdateComboMutation = (
  options?: UseMutationOptions<IOrder, Error, ComboUpdateMutationParams>,
): UseMutationResult<IOrder, Error, ComboUpdateMutationParams> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ action }) => {
    return axios.patch(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/actions`, [action]);
  }, options);
};

export const useRemoveGiftCardFromCart = (options?: UseMutationOptions<unknown, AxiosError, string>): UseMutationResult<unknown, AxiosError, string> => {
  const { storeId } = useWebStore();

  return useMutation(async (giftCardId) => {
    return axios.delete(`/api/v2/stores/${storeId}/cart-giftcards/${giftCardId}`);
  }, options);
};

interface UpdateGiftCardQuantityProps {
  giftCardId: string;
  quantity: number;
}
export const useUpdateGiftCardQuantity = (
  options?: UseMutationOptions<void, AxiosError, UpdateGiftCardQuantityProps>,
): UseMutationResult<void, AxiosError, UpdateGiftCardQuantityProps> => {
  const { storeId } = useWebStore();

  return useMutation(async ({ giftCardId, quantity }) => {
    return axios.patch(`/api/v2/stores/${storeId}/cart-giftcards/${giftCardId}`, { quantity });
  }, options);
};
