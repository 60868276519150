import type { IOrder } from '@goparrot/order-sdk';
import type { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { DiningOptionsUtil } from '@goparrot/store-v2-sdk';

export function getShowTip(cart: IOrder, store: ReadStoreDto): boolean {
  return cart.diningOptionInfo?.type
    ? DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo.type)
      ? store.delivery?.isTipRequired !== false
      : store.pickup?.isTipRequired !== false
    : false;
}
