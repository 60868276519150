import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CartIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M13.5452 14.5455C13.5452 14.9471 13.6876 15.2898 13.9715 15.5737C14.2553 15.8578 14.5983 16 15.0001 16C15.4014 16 15.7443 15.8578 16.0282 15.5737C16.3123 15.2898 16.4545 14.9471 16.4545 14.5455C16.4545 14.1438 16.3125 13.8011 16.0282 13.517C15.7443 13.2331 15.4014 13.0909 15.0001 13.0909C14.5983 13.0909 14.2553 13.2331 13.9715 13.517C13.6876 13.8011 13.5452 14.1438 13.5452 14.5455Z',
      'M18.6929 1.67033C18.5489 1.52637 18.3785 1.45437 18.1815 1.45437H4.53405C4.52637 1.40139 4.50933 1.30488 4.48283 1.16474C4.45632 1.02452 4.43554 0.912836 4.42038 0.829413C4.40513 0.746348 4.37688 0.645891 4.33512 0.528478C4.29345 0.410985 4.24422 0.31809 4.18742 0.25007C4.13071 0.181692 4.05497 0.123144 3.96024 0.0737513C3.86559 0.0246766 3.75757 0 3.63642 0H0.727403C0.530308 0 0.359881 0.0721194 0.21596 0.215881C0.072 0.359801 0 0.530348 0 0.727284C0 0.924219 0.072 1.09469 0.21596 1.23861C0.36 1.38257 0.530468 1.45449 0.727403 1.45449H3.04529L5.05652 10.8068C5.04147 10.837 4.96382 10.9808 4.8236 11.2385C4.68346 11.4962 4.57174 11.7215 4.48848 11.9147C4.40517 12.1081 4.3635 12.2576 4.3635 12.3636C4.3635 12.5605 4.4355 12.731 4.57938 12.8752C4.7235 13.0187 4.89389 13.0909 5.09087 13.0909H5.81811H15.9998H16.7266C16.9235 13.0909 17.0941 13.0187 17.2379 12.8752C17.382 12.7311 17.4539 12.5606 17.4539 12.3636C17.4539 12.1666 17.382 11.9961 17.2379 11.8523C17.0941 11.7084 16.9236 11.6362 16.7266 11.6362H6.27248C6.45437 11.2725 6.54527 11.0304 6.54527 10.9091C6.54527 10.8332 6.5358 10.7498 6.51682 10.6591C6.49783 10.5684 6.47514 10.4678 6.44864 10.3578C6.42209 10.2483 6.40513 10.1668 6.39757 10.1137L18.2613 8.7274C18.4505 8.70452 18.6059 8.62336 18.7271 8.48302C18.8484 8.34301 18.9088 8.18205 18.9088 8.00012V2.18189C18.9087 1.98496 18.8369 1.81449 18.6929 1.67033Z',
      'M3.36353 14.5455C3.36353 14.9471 3.5055 15.2898 3.78963 15.5737C4.07385 15.8578 4.4165 16 4.81813 16C5.21953 16 5.56237 15.8578 5.84639 15.5737C6.13053 15.2898 6.27266 14.9471 6.27266 14.5455C6.27266 14.1438 6.13065 13.8011 5.84639 13.517C5.56233 13.2331 5.21953 13.0909 4.81813 13.0909C4.41654 13.0909 4.07369 13.2331 3.78963 13.517C3.50565 13.8011 3.36353 14.1438 3.36353 14.5455Z',
    ],
    viewBox: '0 0 19 16',
  },
  ...BaseConfig,
  width: 24,
  height: 23,
});
