import type { IOrder } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useGetFullCart = (options?: UseQueryOptions<IOrder, AxiosError, IOrder, any>): UseQueryResult<IOrder> => {
  const { merchantId, storeId } = useWebStore();

  const handleGet = async (): Promise<IOrder> => {
    return axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts`);
  };
  return useQuery(QueryKeysEnum.getFullCart, handleGet, { staleTime: 1000, enabled: false, ...options });
};
