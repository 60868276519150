import type { BoxProps } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import type { FC, PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';

import { Box } from '../box';

export const InlineBox: FC<PropsWithChildren<BoxProps<Theme>> & { testID?: string }> = forwardRef(({ children, ...rest }, forwardedRef) => (
  <Box flexDirection="row" ref={forwardedRef}>
    <Box flexDirection="row" flexBasis="auto" {...rest}>
      {children}
    </Box>
  </Box>
));
