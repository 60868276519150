import type { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { deepObjectSanitizeHtml } from '@webstore-monorepo/shared/utils/url';
import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

type Action = { type: 'update'; payload: Partial<ReadStoreDto> } | { type: 'refetch' };
type Dispatch = (action: Action) => void;

export const StoreStateContext = createContext<ReadStoreDto>({} as ReadStoreDto);
export const StoreStateDispatch = createContext<Dispatch>(() => void 0);

interface Props {
  initial: ReadStoreDto;
}

const reducer = (state: ReadStoreDto, action: Action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };

    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
const getStoreV2 = async (storeId: string): Promise<ReadStoreDto> => {
  return axios.get(`/api/v3/stores/${storeId}`).then((res) => deepObjectSanitizeHtml<ReadStoreDto>(res as unknown as ReadStoreDto));
};

export const StoreProvider: React.FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const [state, dispatch] = useReducer(reducer, initial);
  const handleDispatch = useCallback((action: Action) => {
    if (action.type === 'refetch') {
      getStoreV2(state.storeId).then((res) => dispatch({ type: 'update', payload: res }));
      return;
    }
    dispatch(action);
  }, []);

  useEffect(() => {
    if (initial) handleDispatch({ type: 'update', payload: { ...initial } });
  }, [handleDispatch, initial]);

  return (
    <StoreStateContext.Provider value={state}>
      <StoreStateDispatch.Provider value={handleDispatch}>{state && children}</StoreStateDispatch.Provider>
    </StoreStateContext.Provider>
  );
};

export const useStoreState = (): ReadStoreDto => {
  const context = useContext(StoreStateContext);
  if (context === undefined) {
    throw new Error('useStoreState must be used within a StoreProvider');
  }
  return context;
};

export const useStoreDispatch = () => {
  const context = useContext(StoreStateDispatch);
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider');
  }
  return context;
};
