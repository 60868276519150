export enum QueryKeysEnum {
  getFullCart = 'getFullCart',
  getMenuConcepts = 'getMenuConcepts',
  storeItems = 'storeItems',
  packingInstructions = 'packingInstructions',
  topSellingItems = 'topSellingItems',
  getSlots = 'getSlots',
  getFirstSlot = 'getFirstSlot',
  getPreviousOrder = 'getPreviousOrder',
  getPreviousOrders = 'getPreviousOrders',
  getMerchantUserOrders = 'getMerchantUserOrders',
}
