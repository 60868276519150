import type { TextProps } from '@shopify/restyle';
import { createRestyleComponent, createRestyleFunction, createText } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React, { forwardRef, memo } from 'react';
import type { AccessibilityProps } from 'react-native';
import { Platform } from 'react-native';

const BaseText = createText<Theme>();
type TextPropsExtended = Omit<TextProps<Theme>, 'fontSize'> &
  AccessibilityProps & {
    fontSize?: keyof Theme['fontSizes'];
    children?: React.ReactNode;
    testID?: string;
  };
const withFontSizesThemeKey = createRestyleFunction({
  property: 'fontSize',
  styleProperty: 'fontSize',
  themeKey: 'fontSizes',
});
const withColorThemeKey = createRestyleFunction({
  property: 'color',
  styleProperty: 'color',
  themeKey: 'colors',
});

const withFontFamily = createRestyleFunction({
  property: 'fontFamily',
  styleProperty: 'fontFamily',
  themeKey: 'fontFamily',
});

export const TextBase = createRestyleComponent<TextPropsExtended, Theme>([withFontSizesThemeKey, withColorThemeKey, withFontFamily], BaseText);

const textTransformVariant = ['none', 'capitalize', 'uppercase', 'lowercase'];

const transformFont = (fontWeight?: any, fontStyle?: any) => {
  if (Platform.OS === 'android') {
    return { fontWeight: 400, fontStyle: 'normal' };
  }

  return { fontWeight, fontStyle };
};

export const Text = memo(
  forwardRef((props: TextPropsExtended, forwardedRef) => {
    const { fontSize, color, fontFamily, children, ...rest } = props;
    // @ts-ignore Guard against unwanted and unsupported properties
    delete rest.borderWidth;
    // @ts-ignore Guard against unwanted and unsupported properties
    delete rest.width;
    // @ts-ignore Guard against unwanted and unsupported properties
    delete rest.height;
    // @ts-ignore Guard against unwanted and unsupported properties
    delete rest.backgroundColor;
    // @ts-ignore Guard against unwanted and unsupported properties
    delete rest.background;
    // @ts-ignore Guard against unwanted and unsupported properties
    delete rest.borderRadius;
    if (rest.textTransform && !textTransformVariant.includes(String(rest.textTransform))) {
      // @ts-ignore Guard against unwanted and unsupported properties
      delete rest.textTransform;
    }
    const transformedFontFamily = !props?.variant ? fontFamily ?? 'primary' : fontFamily;
    const transformedFont = transformFont(props.fontWeight, props.fontStyle);

    return (
      <TextBase ref={forwardedRef} fontSize={fontSize} color={color} {...rest} fontFamily={transformedFontFamily} {...transformedFont}>
        {children}
      </TextBase>
    );
  }),
);
