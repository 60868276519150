import { useTheme } from '@shopify/restyle';
import type { ConcreteSvgIcon } from '@webstore-monorepo/shared/interfaces';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

export const CutleryIcon: React.FC<ConcreteSvgIcon> = ({ stroke }) => {
  const theme = useTheme<Theme>();
  // @ts-ignore
  const themeStroke = stroke ? theme.colors[stroke] : '#292929';
  return (
    <Svg viewBox="0 0 32 19" width={32} height={20}>
      <Path fill="transparent" d="M1 1.78571V7.44285L2.96429 8.85713L4.92857 7.44285V1.78571" stroke={themeStroke} strokeWidth="1.3" strokeLinecap="round" />
      <Path d="M2.96436 8.85718V18.2857" stroke={themeStroke} strokeWidth="1.3" strokeLinecap="round" />
      <Path d="M27.3 1L27.3 18.2857" stroke={themeStroke} strokeWidth="1.3" strokeLinecap="round" />
      <Path
        d="M27.4429 1C28.6429 1.88656 30.4429 4.65708 30.4429 6.31938C30.4429 8.97908 30.4429 12.2108 30.4429 11.9892H27.7429"
        stroke={themeStroke}
        fill="transparent"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <Path
        fill={themeStroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16.7C20.2526 16.7 23.7 13.2526 23.7 9C23.7 4.74741 20.2526 1.3 16 1.3C11.7474 1.3 8.3 4.74741 8.3 9C8.3 13.2526 11.7474 16.7 16 16.7ZM16 18C20.9706 18 25 13.9706 25 9C25 4.02944 20.9706 0 16 0C11.0294 0 7 4.02944 7 9C7 13.9706 11.0294 18 16 18Z"
      />

      <Circle fill="transparent" cx="16" cy="9" r="4.35" stroke={themeStroke} strokeWidth="1.3" />
    </Svg>
  );
};
