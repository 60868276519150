import { useTheme } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

export const MinusCircled: React.FC<{ stroke?: string }> = (props) => {
  const theme = useTheme<Theme>();
  // @ts-ignore
  const stroke = props.stroke ? theme.colors[props.stroke] : theme.colors.gray;

  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <Circle cx="8" cy="8" r="7.5" stroke={stroke} />
      <G fill="none" stroke={stroke}>
        <Path d="M13 8L3 8" key="M13 8L3 8" />
      </G>
    </Svg>
  );
};
