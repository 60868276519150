import { DiningOptionEnum, StoreItemPromotionRelationType, StoreItemTypeEnum } from '@goparrot/common';
import type { IAddItemBundleProperty, IOrder, IOrderElement, IOrderPromotionElement } from '@goparrot/order-sdk';
import { getItemsQuantity, OrderDelayedInfoUtil, OrderElementTypeEnum } from '@goparrot/order-sdk';
import type { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil, HumanReadableUtils, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';
import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import groupBy from 'lodash/groupBy';

import { getDiffInMinutes } from './diff-minutes';

export const isMinimumOrderValue = (isDeliveryRequired: boolean, isMinMaxRelativeToGrossTotal: boolean, cart: IOrder, store: ReadStoreDto) => {
  if (isDeliveryRequired) {
    return isMinMaxRelativeToGrossTotal
      ? store.delivery?.minOrderTotal && store.delivery.minOrderTotal > cart.grossTotal
      : store.delivery?.minOrderTotal && store.delivery.minOrderTotal > cart.subtotal;
  }
  return isMinMaxRelativeToGrossTotal
    ? store.pickup?.minOrderTotal && store.pickup.minOrderTotal > cart.grossTotal
    : store.pickup?.minOrderTotal && store.pickup.minOrderTotal > cart.subtotal;
};

export const isMaximumOrderValue = (isDeliveryRequired: boolean, isMinMaxRelativeToGrossTotal: boolean, cart: IOrder, store: ReadStoreDto) => {
  if (isDeliveryRequired) {
    if (!store.delivery?.maxOrderTotal) return false;
    return isMinMaxRelativeToGrossTotal ? store.delivery.maxOrderTotal < cart.grossTotal : store.delivery.maxOrderTotal < cart.subtotal;
  }
  if (!store.pickup?.maxOrderTotal) return false;
  return isMinMaxRelativeToGrossTotal ? store.pickup.maxOrderTotal < cart.grossTotal : store.pickup.maxOrderTotal < cart.subtotal;
};

export const getRelatedPromotion = (
  cartItem: IOrderElement | IStoreItemWithOptions,
  promotions: IOrderPromotionElement[],
): undefined | IOrderPromotionElement => {
  const { promotionUuid, type } = cartItem.tag ?? {};

  if (!promotionUuid || StoreItemPromotionRelationType.DISCOUNT_PRODUCER === type) return;

  return promotions.find((promo: IOrderPromotionElement) => promo.uuid === promotionUuid);
};

const getItemDiscount = (itemId: string, promotion: IOrderPromotionElement): number => {
  return promotion.elements?.find((promotionElement) => promotionElement.uniqueName === itemId)?.amount ?? 0;
};

export const calcItemTotalPrice = (item: IOrderElement | IStoreItemWithOptions, promo?: IOrderPromotionElement): { discounted: number; origin: number } => {
  const origin = item.quantity * item.price;
  const discounted = promo === undefined ? origin : origin - getItemDiscount(item.uniqueName, promo);

  return {
    discounted,
    origin,
  };
};

export const isItemAddedWithDiscount = (item: IOrderElement | IStoreItemWithOptions) => {
  return OrderElementTypeEnum.ELEMENT === item.type && StoreItemPromotionRelationType.ADDED === item.tag?.type;
};

export const hideASAP = (cart: IOrder, store?: ReadStoreDto): boolean => {
  if (store) {
    const { isDisabledASAP: isDisabledASAPPickup } = store?.pickup?.delayedOrders ?? {};
    const { isDisabledASAP: isDisabledASAPDelivery } = store?.delivery?.delayedOrders ?? {};
    const { diningOptionInfo } = cart;
    const { type: diningOptionFromCart } = diningOptionInfo || {};
    const typeBaseAsap =
      !!diningOptionFromCart && DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo?.type) ? isDisabledASAPDelivery : isDisabledASAPPickup;
    const isDelivery = DiningOptionsUtil.getEnabledDiningOptions(store).includes(DiningOptionEnum.DELIVERY);
    const isPickUp = DiningOptionsUtil.getEnabledDiningOptions(store).some((option: DiningOptionEnum) =>
      [DiningOptionEnum.CURBSIDE, DiningOptionEnum.TAKE_OUT].includes(option),
    );
    const orderAheadAvailabilityForMethods = DelayedOrdersUtils.isOrderAheadAvailable(store);
    const { isOpen } = HumanReadableUtils.getHumanReadableDataFromStore(store);
    const isOrderAheadAvailable =
      (isPickUp && orderAheadAvailabilityForMethods[ReceiveMethodEnum.PICKUP]) || (isDelivery && orderAheadAvailabilityForMethods[ReceiveMethodEnum.DELIVERY]);
    // check if store is open and has order ahead available
    if (!isOpen) {
      return isOrderAheadAvailable;
    }
    return !OrderDelayedInfoUtil.isASAP(cart) || !!typeBaseAsap;
  }
  return false;
};

// this logic should be moved to backend
// item.tag.canQuantityBeModified should be used as single condition of disabling quantity input for discounted items
export const isQuantityDisabledForDiscountedItem = (item: IOrderElement) => {
  // @ts-ignore canQuantityBeModified is not on the type
  return OrderElementTypeEnum.ELEMENT === item.type && StoreItemPromotionRelationType.DISCOUNTED === item.tag?.type && !item.tag.canQuantityBeModified;
};

export function isValidDelayedInfo(cart: IOrder, store?: ReadStoreDto) {
  const { isDisabledASAP: isDisabledASAPPickup } = store?.pickup?.delayedOrders ?? {};
  const { isDisabledASAP: isDisabledASAPDelivery } = store?.delivery?.delayedOrders ?? {};
  const { delayedInfo, dateInfo, diningOptionInfo } = cart;
  const { type: diningOptionFromCart } = diningOptionInfo || {};

  const isDisabledASAP =
    !!diningOptionFromCart && DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo?.type) ? isDisabledASAPDelivery : isDisabledASAPPickup;

  if (!delayedInfo) {
    return false;
  }

  if (OrderDelayedInfoUtil.isASAP(cart) && isDisabledASAP) {
    return false;
  }

  if (delayedInfo && !OrderDelayedInfoUtil.isASAP(cart) && delayedInfo?.date && getDiffInMinutes(delayedInfo?.date, dateInfo?.startPrepAt) < 0) {
    return false;
  }

  return true;
}

export const hideItemsWithMaxQuantityExceeded = (recommendedItems: IStoreItemWithOptions[], cart: IOrder) => {
  const cartItemsQuantity = getItemsQuantity(cart);

  return recommendedItems.map((recommendedItem) => {
    const { uniqueName, metadata } = recommendedItem;

    if (uniqueName in cartItemsQuantity) {
      const configMaxQuantity = metadata?.maxQuantity ?? 99;
      const cartItemQuantity = cartItemsQuantity[uniqueName].quantity;
      const isMaxQuantityAchieved = cartItemQuantity >= configMaxQuantity;

      return {
        ...recommendedItem,
        isVisible: !isMaxQuantityAchieved,
      };
    }
    return { ...recommendedItem };
  });
};

export const recommendedItemExistsInCart = (recommendedItem: IStoreItemWithOptions, cart: IOrder) => {
  const cartItemsQuantity = getItemsQuantity(cart);

  return cartItemsQuantity[recommendedItem.uniqueName]?.quantity >= 1;
};

export const getSelectionsWithPrice = (item: IOrderElement) =>
  item.properties
    ?.map(
      ({ isHidden, selected, selections }) =>
        !isHidden &&
        selected &&
        selections.map((selection) => {
          if (OrderElementTypeEnum.SELECTION === selection.type) {
            const { title, price, selected } = selection;
            return selected && { title, price: price * item.quantity };
          }

          return selection.properties.map(({ selected, title, price }) => selected && { title, price: price * item.quantity });
        }),
    )
    .flat(2)
    .filter(Boolean);

export const groupItemsByPropertyUid = (items: IOrderElement[]): IAddItemBundleProperty[] => {
  const groups = groupBy(items, (el) => el.parentPropertyUid);
  return Object.keys(groups).map((key) => ({
    propertyUid: key,
    storeItems: groups[key],
  }));
};

export const resetItemsQuantity = (selectedItems: IOrderElement[], comboQty: number = 1) =>
  selectedItems.map((i) => ({ ...i, quantity: i.quantity / comboQty }));

export function findInvalidPropertyIndex(properties: IStoreItemOptionWithSelections[] = []): number {
  return properties.findIndex((property) => {
    const selectedItemsCount =
      property.selections &&
      property.selections
        .reduce<IStoreItemOptionSelection[]>((acc, prop) => {
          if (prop.type === StoreItemTypeEnum.ITEM_BASE_GROUP) {
            acc.push(...prop.properties);
          } else {
            acc.push(prop);
          }
          return acc;
        }, [])
        .filter(({ selected }) => selected).length;

    return selectedItemsCount > property.selections_max || (!!property.selections_min && selectedItemsCount < property.selections_min);
  });
}

export const getComboTotalPrice = (combo: IOrderElement, promo?: IOrderPromotionElement) => {
  const totalPrice = calcItemTotalPrice(combo, promo);

  return {
    ...totalPrice,
    discounted: OrderElementTypeEnum.BUNDLE_ITEM === combo.type || totalPrice.discounted === totalPrice.origin ? 0 : totalPrice.discounted,
  };
};
