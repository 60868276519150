import { checkIfItemInParentView, checkIfItemsInView } from '@webstore-monorepo/shared/utils/view';
import type { RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useDraggableScroll = (
  ref: RefObject<HTMLElement>,
  options: {
    direction?: 'vertical' | 'horizontal' | 'both';
  } = { direction: 'both' },
) => {
  if (process.env.NODE_ENV === 'development') {
    if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
      console.error('`useDraggableScroll` expects a single ref argument.');
    }
  }
  const itemsRef = useRef<HTMLElement[]>([]);
  const lastVisibleItem = useRef<HTMLElement | null>();
  const [isLeftEdge, setLeftEdge] = useState(true);
  const [isRightEdge, setRightEdge] = useState(false);
  const { direction } = options;

  let initialPosition = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };

  if (ref.current) {
    itemsRef.current = Array.from(ref.current.children) as HTMLElement[];
  }

  useEffect(() => {
    if (ref.current) {
      const leftEdgeObserver = new IntersectionObserver(
        (changes: IntersectionObserverEntry[]) => {
          if (!changes || !changes.length) return;
          const { intersectionRatio, isIntersecting } = changes[0];
          setLeftEdge(intersectionRatio !== 0 && isIntersecting);
        },
        { threshold: 0.8 },
      );
      const rightEdgeObserver = new IntersectionObserver(
        (changes: IntersectionObserverEntry[]) => {
          if (!changes || !changes.length) return;
          const { intersectionRatio, isIntersecting } = changes[0];
          setRightEdge(intersectionRatio !== 0 && isIntersecting);
        },
        { threshold: 0.8 },
      );
      leftEdgeObserver.observe(ref.current.children[0]);
      rightEdgeObserver.observe(ref.current.children[ref.current.children.length - 1]);
    }
  }, [ref.current]);

  const handleMouseMove = useCallback(
    (event: { clientX: number; clientY: number }) => {
      if (ref.current) {
        const dx = event.clientX - initialPosition.mouseX;
        const dy = event.clientY - initialPosition.mouseY;

        if (direction !== 'horizontal') ref.current.scrollTop = initialPosition.scrollTop - dy;
        if (direction !== 'vertical') ref.current.scrollLeft = initialPosition.scrollLeft - dx;
      }
    },
    [ref.current],
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      const touch = event.touches[0] || event.changedTouches[0];
      const { pageX, pageY } = touch;
      if (ref.current) {
        const dx = pageX - initialPosition.mouseX;
        const dy = pageY - initialPosition.mouseY;

        if (direction !== 'horizontal') ref.current.scrollTop = initialPosition.scrollTop - dy;
        if (direction !== 'vertical') ref.current.scrollLeft = initialPosition.scrollLeft - dx;
      }
    },
    [ref.current],
  );

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleTouchEnd = () => {
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  };

  const handleMouseDown = useCallback(
    (event: { clientX: number; clientY: number }) => {
      if (ref.current) {
        initialPosition = {
          scrollLeft: ref.current.scrollLeft,
          scrollTop: ref.current.scrollTop,
          mouseX: event.clientX,
          mouseY: event.clientY,
        };

        ref.current.style.userSelect = 'none';

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      }
    },
    [ref.current],
  );

  const handleTouchStart = useCallback(
    (event: any) => {
      const touch = event.touches[0] || event.changedTouches[0];
      const { pageX, pageY } = touch;
      if (ref.current) {
        initialPosition = {
          scrollLeft: ref.current.scrollLeft,
          scrollTop: ref.current.scrollTop,
          mouseX: pageX,
          mouseY: pageY,
        };

        ref.current.style.userSelect = 'none';
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
      }
    },
    [ref.current],
  );

  const handleScrollToEnd = useCallback(
    (direction: 'left' | 'right') => {
      if (ref.current) {
        if (direction === 'left') {
          ref.current.scrollLeft = 0;
        }
        if (direction === 'right') {
          ref.current.scrollLeft = ref.current.scrollWidth;
        }
      }
    },
    [ref.current],
  );

  const handleScrollTo = useCallback(
    (el: HTMLElement | null) => {
      if (ref.current && el) {
        const isInView = checkIfItemInParentView(el, ref.current);
        if (!isInView) {
          const scrollRect = ref.current.getBoundingClientRect();
          const activeRect = el.getBoundingClientRect();

          ref.current.scrollLeft += activeRect.left - scrollRect.left - scrollRect.width / 2 + activeRect.width / 2;
        }
      }
    },
    [ref],
  );

  const handleScrollToPrevGroup = () => {
    if (ref.current && itemsRef?.current) {
      const visibleItems = checkIfItemsInView(itemsRef.current, ref.current);
      const firstVisibleIndex = itemsRef.current.findIndex((item) => item?.innerHTML === visibleItems[0]?.innerHTML);
      if (firstVisibleIndex === -1) {
        const lastVisibleIndex = itemsRef?.current?.findIndex((item) => item?.innerHTML === lastVisibleItem.current?.innerHTML) || 0;
        handleScrollTo(itemsRef.current[lastVisibleIndex - 1]);
        return;
      }
      const _nextGroupFirstItemIndex = firstVisibleIndex - visibleItems.length;
      const isEnd = _nextGroupFirstItemIndex < 0;
      if (isEnd) {
        handleScrollToEnd('left');
        return;
      }
      const prev = itemsRef.current.slice(_nextGroupFirstItemIndex, firstVisibleIndex);
      if (prev.length && prev[0]) {
        lastVisibleItem.current = prev[0];
        handleScrollTo(prev[0]);
      }
    }
  };

  const handleScrollToNextGroup = () => {
    if (ref.current && itemsRef?.current) {
      const visibleItems = checkIfItemsInView(itemsRef.current, ref.current);
      const lastVisibleIndex = itemsRef.current.findIndex((item) => item?.innerHTML === visibleItems[visibleItems.length - 1]?.innerHTML);
      if (lastVisibleIndex === -1) {
        const lastVisibleIndex = itemsRef?.current?.findIndex((item) => item?.innerHTML === lastVisibleItem.current?.innerHTML) || 0;
        handleScrollTo(itemsRef.current[lastVisibleIndex + 1]);
      }
      const _nextGroupLastItemIndex = lastVisibleIndex + visibleItems.length + 1;
      const isEnd = _nextGroupLastItemIndex > itemsRef.current.length - 1;
      if (isEnd) {
        handleScrollToEnd('right');
        return;
      }
      const next = itemsRef.current.slice(lastVisibleIndex + 1, _nextGroupLastItemIndex);

      if (next.length && next[next.length - 1]) {
        lastVisibleItem.current = next[next.length - 1];
        handleScrollTo(next[next.length - 1]);
      }
    }
  };

  return {
    isLeftEdge,
    isRightEdge,
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseMove: handleMouseMove,
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onScrollToEnd: handleScrollToEnd,
    onScrollTo: handleScrollTo,
    onScrollToPrevGroup: handleScrollToPrevGroup,
    onScrollToNextGroup: handleScrollToNextGroup,
  };
};
