export const buttons = {
  buttonVariants: {
    contained: {
      color: 'primaryButtonTextColor',
      backgroundColor: 'primaryButtonColor',
    },
    stroked: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: 'primaryButtonColor',
      color: 'primaryButtonColor',
    },
    link: {
      width: 'auto',
      height: 'auto',
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: 'primaryButtonColor',
    },
  },
};
