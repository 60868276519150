import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const CashIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M20 9V7H18C18 8.1 18.9 9 20 9ZM8 7H16C16 9.21 17.79 11 20 11V13C17.79 13 16 14.79 16 17H8C8 14.79 6.21 13 4 13V11C6.21 11 8 9.21 8 7ZM6 7H4V9C5.1 9 6 8.1 6 7ZM4 17V15C5.1 15 6 15.9 6 17H4ZM20 15C18.9 15 18 15.9 18 17H20V15ZM2 5H22V19H2V5ZM13 12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12C11 12.55 11.45 13 12 13C12.55 13 13 12.55 13 12ZM9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12Z',
    ],
    viewBox: '0 0 24 24',
    fillOpacity: 0.9,
  },
  ...BaseConfig,
});
