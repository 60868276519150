import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { FormatNumberOptions } from 'react-intl';
import type { CountryCode } from 'react-native-country-picker-modal';

export const PlatformStoreContext = createContext<PlatformStoreProviderState>({
  notification: {
    success: () => {},
    error: () => {},
  },
  analytics: {
    track: () => {},
  },
  intl: {
    formatNumber: () => '',
    getIntlString: () => '',
    formatMessage: () => '',
  },
  defaultCountryCode: 'US',
  defaultErrorMessage: '',
} as PlatformStoreProviderState);

export type PlatformStoreProviderState = {
  defaultCountryCode: CountryCode;
  defaultErrorMessage: string;
  // pass your notification provider here
  notification: {
    success: (message: string, options?: any) => void;
    error: (message: string, options?: any) => void;
  };
  analytics: { track: (tag: string, additionalData?: Record<any, any>) => void };
  intl: {
    formatNumber: (value: number, options?: FormatNumberOptions) => React.ReactNode | string;
    getIntlString: (path: string, options?: any) => string;
    formatMessage: (path: string, options?: unknown) => React.ReactNode | string;
  };
};

export const PlatformStoreProvider: React.FC<PropsWithChildren<PlatformStoreProviderState>> = (props) => {
  const state = useMemo(
    () => ({
      ...props,
    }),
    [],
  );

  return <PlatformStoreContext.Provider value={state}>{props.children}</PlatformStoreContext.Provider>;
};

export const usePlatformStoreState = () => {
  const context = useContext(PlatformStoreContext);
  if (context === undefined) {
    throw new Error('usePlatformStoreState must be used within a PlatformStore');
  }
  return context;
};
