import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const usePhoneNumberMutation = (options?: UseMutationOptions<void, AxiosError, string>): UseMutationResult<void, AxiosError, string> => {
  const { analytics } = usePlatformStoreState();
  const { storeId } = useWebStore();
  return useMutation(async (phoneNumber: string): Promise<void> => {
    analytics.track(AnalyticsActionsEnum.ACCOUNT_SUBMIT_PHONE, {
      phoneNumber: phoneNumber,
    });
    return axios.post(`/api/v2/users-by-phones/${phoneNumber}/codes`, undefined, { params: { storeId } });
  }, options);
};
