import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const PlusIcon = createSvgIcon({
  iconConfig: {
    paths: ['M5 0V10', 'M10 5L0 5'],
    viewBox: '0 0 10 10',
  },
  ...BaseConfig,
  width: 10,
  height: 10,
  // stroke: '#005AD9',
});
