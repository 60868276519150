import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import type { AxiosError } from 'axios';
import type { UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

export const useDeleteUserMutation = (userId: string, options?: UseMutationOptions<unknown, AxiosError>) => {
  const { analytics } = usePlatformStoreState();
  // TODO: remove merchantId and storeId params. Looks like gateway service ignores them
  const { merchantId, storeId } = useWebStore();

  return useMutation(async () => {
    analytics.track(AnalyticsActionsEnum.ACCOUNT_DELETE_CONFIRM);
    return axios.delete(`/api/v2/users/${userId}`, { params: { merchantId, storeId } });
  }, options);
};
