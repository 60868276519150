import { BaseConfig } from '../interfaces/icon-config';
import { createSvgIcon } from './SVGIcon';

export const AddGiftCardIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M19 5V2H21V5H24V7H21V10H19V7H16V5H19Z',
      'M5 7C4.44772 7 4 7.44772 4 8V9H17V11H4V16C4 16.5523 4.44772 17 5 17H18C18.5523 17 19 16.5523 19 16V12H21V16C21 17.6569 19.6569 19 18 19H5C3.34315 19 2 17.6569 2 16V8C2 6.34315 3.34315 5 5 5H14V7H5Z',
    ],
    viewBox: '0 0 24 24',
  },
  ...BaseConfig,
  strokeOpacity: 0,
});
