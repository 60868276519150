import type { IFacadeInterceptors } from '@goparrot/common';
import { RPT_HEADER } from '@goparrot/webstore-gateway-public-sdk';
import { parseURLSearchParams } from '@webstore-monorepo/shared/utils/url';
import { UserAuthStorageService, WebstoreUserAuthStorage } from '@webstore-monorepo/shared/utils/user-auth-storage';
import type { AxiosError, AxiosRequestConfig } from 'axios';

export const responseInterceptor = (res: Record<string, any>) => {
  if (res.data) {
    return res.data;
  }
  return res;
};
export async function requestInterceptor(config: AxiosRequestConfig) {
  const { merchantId } = window;
  const webstoreUserAuthStorage = new WebstoreUserAuthStorage(merchantId);
  const accessToken = await webstoreUserAuthStorage.getAccessToken();

  if (!config.headers) {
    config.headers = {};
  }

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const urlParams = parseURLSearchParams();
  if (urlParams?.rpt) {
    config.headers[RPT_HEADER] = urlParams.rpt;
  }

  if (typeof window !== 'undefined' && !config.url?.includes('/api/v2/analytics') && window.document && window.document.body) {
    const version = document.body?.getAttribute('version');
    if (version) {
      config.headers['X-Client-Version'] = version;
    }

    const hostname = document.body?.getAttribute('hostname');
    if (hostname) {
      config.headers['X-Client-Hostname'] = hostname;
    }
  }
  return config;
}

export async function handleResponseFail(error: AxiosError) {
  const response = error?.response;
  const { merchantId } = window;
  const userAuth = new UserAuthStorageService(merchantId);

  if (response?.status === 401 && window.location.pathname.includes('/order-complete')) {
    // navigate to menu page if user is not authenticated
    const paths = window.location.pathname.split('/');
    window.location.replace(window.location.origin + paths.slice(0, paths.indexOf('menu') + 1).join('/'));
    return;
  }

  if (
    response?.status === 401 ||
    (response?.status === 403 && response?.config?.method?.toUpperCase() !== 'POST' && !response?.config?.url?.includes('/codes?')) ||
    (response?.status === 404 && response?.config?.url?.includes('/user-contexts'))
  ) {
    if (merchantId && userAuth.has()) {
      userAuth.remove();
    }
    window.location.reload();
  }

  if (response?.status === 418) {
    window.location.reload();
  }

  return Promise.reject(error);
}

export const getInterceptors = (): IFacadeInterceptors => ({
  request: [
    {
      onFulfilled: requestInterceptor,
    },
  ],
});
